import React from 'react';

// assets:
import Back from '../../assets/back.svg';

const Backbutton = ({ normal, target, onClick }) => {
  return (
    <div className="backbutton" onClick={onClick}>
      <img src={Back} alt="" className="doinvert" />
      <div className="textwrapper">
        <p className="tw1">
          <strong>{normal || 'Tillbaka'}</strong>
        </p>
        <p className="tw2">
          <strong>{target}</strong>
        </p>
      </div>
    </div>
  );
};

export default Backbutton;
