import { doc, getDoc, getFirestore } from 'firebase/firestore';
import idx from 'idx';
import React, { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { toast } from 'react-toastify';
import {
  removeFromURL,
  setStateSafely,
  updateOrganization,
} from '../../../functions';

// components:
import Backbutton from '../../global/Backbutton';
import CheckboxGroup from '../../global/CheckboxGroup';
import MultiSelectInput from '../../global/MultiSelectInput';

// assets:
import CheckMark from '../../../assets/checkmark.svg';
import Image from '../../../assets/image.svg';
import AddLogoPopup from './AddLogoPopup';

const EditOrganization = ({ organizationID }) => {
  const [organization, setOrganization] = useState(null);

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [orgNr, setOrgNr] = useState('');

  useEffect(() => {
    if (organizationID == null || organizationID === '') return;

    const db = getFirestore();

    getDoc(doc(db, 'organizations', organizationID)).then((doc) => {
      let data = doc.data();
      if (data) {
        setOrganization(data);
      }
    });
  }, [organizationID]);

  useEffect(() => {
    if (organization == null) return;

    setStateSafely(
      setName,
      idx(organization, (_) => _.name)
    );
    setStateSafely(
      setAddress,
      idx(organization, (_) => _.address)
    );
    setStateSafely(
      setPostalCode,
      idx(organization, (_) => _.postalcode)
    );
    setStateSafely(
      setCity,
      idx(organization, (_) => _.city)
    );
    setStateSafely(
      setOrgNr,
      idx(organization, (_) => _.orgnr)
    );
    setStateSafely(
      setFormOptions,
      idx(organization, (_) => _.formoptions)
    );
    setStateSafely(
      setNewBookingNotifs,
      idx(organization, (_) => _.notificationoptions.newbooking)
    );
    setStateSafely(
      setNewDiscussionNotifs,
      idx(organization, (_) => _.notificationoptions.newDiscussion)
    );
    setStateSafely(
      setStatusChangeNotifs,
      idx(organization, (_) => _.notificationoptions.statuschanged)
    );
    setStateSafely(
      setCompletedNotifs,
      idx(organization, (_) => _.notificationoptions.completedNotifs)
    );
  }, [organization]);

  const submit = (e) => {
    e.preventDefault();

    const db = getFirestore();
    if (db == null) {
      toast.error(
        'Någonting gick fel. Vänligen ladda om sidan och försök igen.'
      );
      return;
    }
    if (organizationID == null || organizationID == '') {
      toast.error(
        'Någonting gick fel. Vänligen ladda om sidan och försök igen.'
      );
      return;
    }
    if (name === '') {
      toast.error('Ange organisationens namn');
      return;
    }
    if (address === '') {
      toast.error('Ange organisationens address');
      return;
    }
    if (postalCode === '') {
      toast.error('Ange postnummer');
      return;
    }
    if (city === '') {
      toast.error('Ange ort');
      return;
    }
    if (orgNr === '') {
      toast.error('Ange organisationens organisationsnummer');
      return;
    }

    var notificationoptions = {
      newbooking: newBookingNotifs,
      statuschanged: statusChangeNotifs,
      completedNotifs: completedNotifs,
      newDiscussion: newDiscussionNotifs,
    };

    updateOrganization(
      db,
      name,
      address,
      postalCode,
      city,
      orgNr,
      organizationID,
      formOptions,
      notificationoptions,
      (callback) => {
        if (callback) {
          removeFromURL('editorganization');
        }
      }
    );
  };

  const [formOptions, setFormOptions] = useState([]);

  const [newBookingNotifs, setNewBookingNotifs] = useState(
    newBookingRows.filter((e) => e.default === true).map((a) => a.value)
  );
  const [newDiscussionNotifs, setNewDiscussionNotifs] = useState(
    newDiscussionRows.filter((e) => e.default === true).map((a) => a.value)
  );
  const [statusChangeNotifs, setStatusChangeNotifs] = useState(
    statusChangeRows.filter((e) => e.default === true).map((a) => a.value)
  );
  const [completedNotifs, setCompletedNotifs] = useState(
    completedRows.filter((e) => e.default === true).map((a) => a.value)
  );

  const [showingLogoPopup, setShowingLogoPopup] = useState(false);

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: '100%', maxHeight: '100%' },
    from: { opacity: 0, x: 16, maxWidth: '100%', maxHeight: '100%' },
    config: { friction: 20, tension: 400 },
  });

  return (
    <animated.div style={springprops}>
      <div className="createbooking board">
        <Backbutton
          target={'Hantera organisation'}
          onClick={() => removeFromURL('editorganization')}
        />
        <h1 className="title">Redigera organisation</h1>
        <p className="subtitle">Ändra organisationsuppgifter i portalen</p>
        <form onSubmit={submit}>
          <label htmlFor="name">Namn</label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="name"
            placeholder="Namn"
          />
          <label htmlFor="address">Organisationens adress</label>
          <input
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            id="address"
            placeholder="Adress"
          />
          <label htmlFor="postalcode">Postnummer</label>
          <input
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            id="postalcode"
            placeholder="Postnummer"
          />
          <label htmlFor="city">Stad</label>
          <input
            value={city}
            onChange={(e) => setCity(e.target.value)}
            id="city"
            placeholder="Ort"
          />
          <label htmlFor="orgnr">Organisationsnummer (XXYYZZ-XXYY)</label>
          <input
            value={orgNr}
            onChange={(e) => setOrgNr(e.target.value)}
            id="orgnr"
            placeholder="Organisationsnummer"
          />
          <label>Alternativ för bokningsformulär</label>
          <CheckboxGroup
            rows={formOptionRows}
            state={formOptions}
            setState={setFormOptions}
          />
          <label>
            <strong>Vem får notifikation vid:</strong>
          </label>
          <label>Ny bokning</label>
          <MultiSelectInput
            rows={newBookingRows}
            state={newBookingNotifs}
            setState={setNewBookingNotifs}
          />
          <label>Ny diskussion</label>
          <MultiSelectInput
            rows={newDiscussionRows}
            state={newDiscussionNotifs}
            setState={setNewDiscussionNotifs}
          />
          <label>Statusändring</label>
          <MultiSelectInput
            rows={statusChangeRows}
            state={statusChangeNotifs}
            setState={setStatusChangeNotifs}
          />
          <label>Färdigställd</label>
          <MultiSelectInput
            rows={completedRows}
            state={completedNotifs}
            setState={setCompletedNotifs}
          />
          <div></div>
          <button type="button" onClick={submit}>
            <img src={CheckMark} className="doinvert" alt="" />
            Spara ändringar
          </button>
          <button type="button" onClick={() => setShowingLogoPopup(true)}>
            <img src={Image} className="doinvert" alt="" />
            Lägg till logotyp
          </button>
        </form>
      </div>
      {showingLogoPopup ? (
        <AddLogoPopup
          setShowingSelf={setShowingLogoPopup}
          organization={organization}
        />
      ) : null}
    </animated.div>
  );
};

export default React.memo(EditOrganization);

const formOptionRows = [
  {
    label: 'Visa kalender',
    value: 'SHOW_CALENDAR',
  },
  {
    label: 'Visa redigeringstyp',
    value: 'SHOW_EDITINGSTYLE',
  },
  {
    label: 'Möjlighet att själv boka tillfälle',
    value: 'SHOW_BOOKINGTIME',
  },
];

const newBookingRows = [
  {
    label: 'Bokare',
    value: 'BOOKER',
    default: true,
  },
  {
    label: 'Administratörer',
    value: 'ADMINS',
    default: false,
  },
  {
    label: 'Assistenter',
    value: 'ASSISTANTS',
    default: false,
  },
];

const newDiscussionRows = [
  {
    label: 'Bokare',
    value: 'BOOKER',
    default: true,
  },
  {
    label: 'Administratörer',
    value: 'ADMINS',
    default: false,
  },
  {
    label: 'Assistenter',
    value: 'ASSISTANTS',
    default: false,
  },
];

const statusChangeRows = [
  {
    label: 'Bokare',
    value: 'BOOKER',
    default: true,
  },
  {
    label: 'Administratörer',
    value: 'ADMINS',
    default: false,
  },
  {
    label: 'Assistenter',
    value: 'ASSISTANTS',
    default: false,
  },
];

const completedRows = [
  {
    label: 'Bokare',
    value: 'BOOKER',
    default: true,
  },
  {
    label: 'Administratörer',
    value: 'ADMINS',
    default: false,
  },
  {
    label: 'Assistenter',
    value: 'ASSISTANTS',
    default: true,
  },
];
