import {
  collectionGroup,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import {
  containsURL,
  getUserPermissions,
  removeFromURL,
} from "../../../functions";

// components:
import Backbutton from "../../global/Backbutton";
import BookingDetails from "./HandleBooking";
import SortingOptionsAdmin from "./SortingOptionsAdmin";
import idx from "idx";
import BookingWrapper from "./wrappers/BookingWrapper";
import SortingOptionsClient from "./SortingOptionsClient";
import EmptyScreen from "../../global/EmptyScreen";

// assets:
import Filter from "../../../assets/filter.svg";

const HandleBookingsBoard = ({ user }) => {
  const [bookings, setBookings] = useState(null);
  const [filteredBookings, setFilteredBookings] = useState(null);
  const [booking, setBooking] = useState(null);
  const [q, setQ] = useState(null);
  const [qKey, setQKey] = useState(uuidv4());
  const [userPermissions, setUserPermissions] = useState(null);
  const [qConfig, setQConfig] = useState(null);
  const [qConfigDescriptors, setQConfigDescriptors] = useState({});
  const [shouldShowFilteringPopup, setShouldShowFilteringPopup] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getUserPermissions((callback) => {
      setUserPermissions(callback);
    });
  }, []);

  useEffect(() => {
    if (q == null) {
      const db = getFirestore();

      if (idx(user, (_) => _.organization) === "INTERNAL") {
        setQ(
          query(
            collectionGroup(db, "bookings"),
            where("status", "in", ["BOOKED", "NOT_BOOKED", "DELIVERED"]),
            orderBy("timecreated", "desc")
          )
        );
      } else {
        console.log(userPermissions);
        if (userPermissions !== "client") {
          setQ(
            query(
              collectionGroup(db, "bookings"),
              where("status", "in", ["BOOKED", "NOT_BOOKED", "DELIVERED"]),
              where(
                "organizationID",
                "==",
                idx(user, (_) => _.organization) || null
              ),
              orderBy("timecreated", "desc")
            )
          );
        } else {
          setQ(
            query(
              collectionGroup(db, "bookings"),
              where("status", "in", ["BOOKED", "NOT_BOOKED", "DELIVERED"]),
              where("uid", "==", idx(user, (_) => _.uid) || null),
              orderBy("timecreated", "desc")
            )
          );
        }
      }
    }
  }, [qKey, userPermissions, q]);

  useEffect(() => {
    setQ(null);
  }, [userPermissions]);

  useEffect(() => {
    if (q == null) return;

    var listener = onSnapshot(
      q,
      (snapshot) => {
        var tempBookings = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          var queryString = "";
          queryString += idx(data, (_) => _.sellername) || "";
          queryString += idx(data, (_) => _.address.address) || "";
          queryString += idx(data, (_) => _.address.city) || "";
          queryString += idx(data, (_) => _.address.postalcode) || "";
          data.queryString = queryString;
          tempBookings.push(data);
          if (booking) {
            if (booking.id === data.id) {
              setBooking(data);
            }
          }
        });
        setBookings(tempBookings);
      },
      (err) => {
        console.error(err);
      }
    );

    return () => {
      listener();
    };
  }, [q, userPermissions]);

  useEffect(() => {
    if (qConfig == null) {
      setQ(null);
      setQKey(uuidv4());
      return;
    }
    setQ(constructQuery(qConfig.wheres, qConfig.order));
    setQKey(uuidv4());
  }, [qConfig, userPermissions]);

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredBookings(createIntuitiveList(bookings));
      return;
    }
    const throttle = setTimeout(() => {
      setQConfig(null);
      setQConfigDescriptors({});
      const result = bookings.filter((e) =>
        e.queryString.toUpperCase().includes(searchQuery.toUpperCase())
      );
      console.log({ result });
      setFilteredBookings(result);
    }, 250);

    return () => {
      clearTimeout(throttle);
    };
  }, [searchQuery, bookings]);

  useEffect(() => {
    if (!bookings) return;
    bookings.forEach((b) => {
      if (b.id === idx(booking, (_) => _.id)) {
        setBooking(b);
      }
    });
  }, [bookings]);

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <>
      <animated.div style={shouldShowFilteringPopup ? null : springprops}>
        <div className="createbooking board">
          <Backbutton
            target={"Dashboard"}
            onClick={() => removeFromURL("bookings")}
          />
          <h1 className="title">Bokningar</h1>
          <p className="subtitle">
            Se nya bokningar, status, och fullfölj bokningar
          </p>
          <div className="buttonrow">
            <button onClick={() => setShouldShowFilteringPopup(true)}>
              <img src={Filter} className="doinvert" alt="" />
              Ändra filtrering
            </button>
          </div>
          <div className="filteringdescription">
            {idx(qConfigDescriptors, (_) => _.wheres) ? (
              <small>
                Filtrerar efter{" "}
                {idx(qConfigDescriptors, (_) => _.wheres.prefix)}
                <strong>{idx(qConfigDescriptors, (_) => _.wheres.name)}</strong>
              </small>
            ) : null}
            {idx(
              qConfigDescriptors,
              (_) => _.wheres.showStatusesLabels.length
            ) > 0 ? (
              <small>
                Med status{" "}
                <strong>
                  {idx(qConfigDescriptors, (_) =>
                    _.wheres.showStatusesLabels.join(", ").toLowerCase()
                  )}
                </strong>
              </small>
            ) : null}
            {idx(qConfigDescriptors, (_) => _.order) ? (
              <small>
                Sorterar efter{" "}
                <strong>
                  {idx(qConfigDescriptors, (_) => _.order.name.toLowerCase())}
                </strong>
              </small>
            ) : null}
          </div>
          <form>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Sök..."
            />
          </form>
          {filteredBookings ? (
            filteredBookings.length > 0 ? (
              <div className="bookingsList" key={filteredBookings.length}>
                {filteredBookings.map((booking) => {
                  return (
                    <BookingWrapper
                      key={idx(booking, (_) => _.id) || uuidv4()}
                      booking={booking}
                      setBooking={setBooking}
                    />
                  );
                })}
              </div>
            ) : (
              <EmptyScreen
                title={"Inget här!"}
                message={"Testa att ändra dina filtreringsinställningar"}
              />
            )
          ) : null}
        </div>
      </animated.div>
      {shouldShowFilteringPopup ? (
        idx(user, (_) => _.organization) === "INTERNAL" ? (
          <SortingOptionsAdmin
            config={qConfig}
            setConfig={setQConfig}
            configDesc={qConfigDescriptors}
            setConfigDesc={setQConfigDescriptors}
            setShowingSelf={setShouldShowFilteringPopup}
          />
        ) : (
          <SortingOptionsClient
            config={qConfig}
            setConfig={setQConfig}
            configDesc={qConfigDescriptors}
            setConfigDesc={setQConfigDescriptors}
            setShowingSelf={setShouldShowFilteringPopup}
            user={user}
          />
        )
      ) : null}
      {containsURL("bookingdetails") || booking ? (
        <BookingDetails
          providedBooking={booking}
          setProvidedBooking={setBooking}
        />
      ) : null}
    </>
  );
};

export default React.memo(HandleBookingsBoard);

const constructQuery = (wheres, order) => {
  const db = getFirestore();

  var q = query(collectionGroup(db, "bookings"));

  wheres.forEach((it) => {
    q = query(q, where(it[0], it[1], it[2]));
  });

  order.forEach((it) => {
    q = query(q, orderBy(it[0], it[1]));
  });

  return q;
};

const createIntuitiveList = (bookings) => {
  if (!bookings) return bookings;
  var tempBookings = [];
  const within24Hours = bookings.filter((e) => {
    try {
      const bookedDate = e.bookedtime.toDate();
      const H24_IN_MS = 1000 * 60 * 60 * 24;
      const diff = bookedDate.getTime() - new Date().getTime();
      return diff > 0 && diff < H24_IN_MS;
    } catch {}
    return false;
  });
  if (within24Hours.length > 0) {
    tempBookings.push({
      type: "HEADING",
      title: "Inbokat inom 24 timmar",
      id: "NEXT24",
    });
    within24Hours.forEach((e) => tempBookings.push(e));
  }
  const past48Hours = bookings.filter((e) => {
    try {
      const bookedDate = e.bookedtime.toDate();
      const H48_IN_MS = 1000 * 60 * 60 * 48;
      const diff = new Date().getTime() - bookedDate.getTime();
      return diff > 0 && diff < H48_IN_MS;
    } catch {}
    return false;
  });
  if (past48Hours.length > 0) {
    tempBookings.push({
      type: "HEADING",
      title: "Fotades de senaste 48 timmarna",
      id: "PAST48",
    });
    past48Hours.forEach((e) => tempBookings.push(e));
  }
  if (within24Hours.length > 0 || past48Hours.length > 0) {
    tempBookings.push({
      type: "HEADING",
      title: "Övriga",
      id: "OTHERS",
    });
  }
  bookings.forEach((booking) => {
    if (!tempBookings.some((e) => e.id === booking.id)) {
      tempBookings.push(booking);
    }
  });
  return tempBookings;
};
