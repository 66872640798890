import { React, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import {
  getFullname,
  getUserPermissions,
  removeFromURL,
} from "../../../functions";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "@firebase/firestore";
import { v4 as uuidv4 } from "uuid/dist";
import idx from "idx";

// components:
import Backbutton from "../../global/Backbutton";
import MultiSelectInput from "../../global/MultiSelectInput";

// assets:
import CheckMark from "../../../assets/checkmark.svg";
import EditServicesWrapper from "./wrappers/EditServicesWrapper";
import { toast } from "react-toastify";

const EditMeta = () => {
  useEffect(() => {
    getUserPermissions((callback) => {
      if (callback !== "superadmin") {
        removeFromURL("editmeta");
      }
    });
  }, []);

  const [userRows, setUserRows] = useState([]);

  useEffect(() => {
    const db = getFirestore();
    getDocs(
      query(
        collection(db, "users"),
        where("organization", "==", "INTERNAL"),
        orderBy("name.first")
      )
    )
      .then((snapshot) => {
        const tempNewBookingRows = [];
        snapshot.forEach((e) => {
          const user = e.data();
          tempNewBookingRows.push({
            label: getFullname(user),
            value: idx(user, (_) => _.uid),
          });
        });
        setUserRows(tempNewBookingRows);
      })
      .catch((err) => {
        console.error({ err });
      });
  }, []);

  const [newBookingNotifs, setNewBookingNotifs] = useState([]);
  const [newBookingKey, setNewBookingKey] = useState(uuidv4());

  useEffect(() => {
    const db = getFirestore();
    const listener = onSnapshot(
      doc(db, "meta", "newBookingNotifications"),
      (snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.data();
          const uids = data.uids;
          setNewBookingNotifs(uids || []);
        }
      },
      (err) => {
        console.error({ err });
      }
    );

    return () => {
      listener();
    };
  }, []);
  useEffect(() => {
    setNewBookingKey(uuidv4());
  }, [userRows, newBookingNotifs]);

  const [discussionNotifs, setDiscussionNotifs] = useState([]);
  const [discussionKey, setDiscussionKey] = useState(uuidv4());
  useEffect(() => {
    const db = getFirestore();
    const listener = onSnapshot(
      doc(db, "meta", "discussionNotifications"),
      (snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.data();
          const uids = data.uids;
          setDiscussionNotifs(uids || []);
        }
      },
      (err) => {
        console.error({ err });
      }
    );

    return () => {
      listener();
    };
  }, []);
  useEffect(() => {
    setDiscussionKey(uuidv4());
  }, [userRows, discussionNotifs]);

  const [calendarLink, setCalendarLink] = useState("");
  useEffect(() => {
    if (!calendarLink.includes("iframe")) return;
    const components = calendarLink.split('"');
    components.forEach((e) => {
      if (e.includes("calendar.google.com")) {
        setCalendarLink(e);
      }
    });
  }, [calendarLink]);

  useEffect(() => {
    const db = getFirestore();
    const listener = onSnapshot(
      doc(db, "meta", "calendars"),
      (snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.data();
          const sharedCalendar = data.sharedCalendar;
          setCalendarLink(sharedCalendar || "");
        }
      },
      (err) => {
        console.error({ err });
      }
    );

    return () => {
      listener();
    };
  }, []);

  const [isSaving, setIsSaving] = useState(false);
  const submit = () => {
    if (isSaving) return;
    setIsSaving(true);
    const db = getFirestore();
    const promises = [
      updateDoc(doc(db, "meta", "newBookingNotifications"), {
        uids: newBookingNotifs,
        lastUpdated: serverTimestamp(),
      }),
      updateDoc(doc(db, "meta", "discussionNotifications"), {
        uids: discussionNotifs,
        lastUpdated: serverTimestamp(),
      }),
      updateDoc(doc(db, "meta", "calendars"), {
        sharedCalendar: calendarLink,
        lastUpdated: serverTimestamp(),
      }),
    ];
    const promise = Promise.all(promises)
      .then(() => {
        setIsSaving(false);
      })
      .catch((err) => {
        console.error(err);
        setIsSaving(false);
      });
    toast.promise(promise, {
      pending: "Uppdaterar meta... Vänligen lämna inte sidan.",
      error: "Någonting gick fel. Vänligen försök igen senare.",
      success: "Allt gick bra! Uppdateringen är slutförd.",
    });
  };

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <animated.div style={springprops}>
      <div className="createbooking board">
        <Backbutton
          target={"Användare"}
          onClick={() => removeFromURL("editmeta")}
        />
        <h1 className="title">Redigera meta</h1>
        <p className="subtitle">Ändra metadata i portalen</p>
        <form onSubmit={submit}>
          <label>
            <strong>Notifikationsinställningar</strong>
          </label>
          <label>Vid ny bokning</label>
          <MultiSelectInput
            rows={userRows}
            state={newBookingNotifs}
            setState={setNewBookingNotifs}
            key={newBookingKey}
          />
          <label>Vid ny diskussion</label>
          <MultiSelectInput
            rows={userRows}
            state={discussionNotifs}
            setState={setDiscussionNotifs}
            key={discussionKey}
          />
          <label>
            <strong>Tjänster</strong>
          </label>
          <EditServicesWrapper />
          <label>
            <strong>Övrig metadata</strong>
          </label>
          <label>Länk till delad kalender</label>
          <input
            type="text"
            value={calendarLink}
            onChange={(e) => setCalendarLink(e.target.value)}
            placeholder="Klistra in länk"
          />
          <div></div>
          <button type="button" onClick={submit}>
            <img src={CheckMark} className="doinvert" alt="" />
            Spara ändringar
          </button>
        </form>
      </div>
    </animated.div>
  );
};

export default EditMeta;
