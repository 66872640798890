import { React, useEffect, useState } from "react";
import {
  arrayRemove,
  arrayUnion,
  doc,
  getFirestore,
  onSnapshot,
  updateDoc,
} from "@firebase/firestore";
import { toast } from "react-toastify";

// components:
import Modal from "../../../global/Modal";

// assets:
import Trash from "../../../../assets/trash.svg";
import Plus from "../../../../assets/plus.svg";
import Edit from "../../../../assets/edit.svg";

const EditServicesWrapper = () => {
  const [services, setServices] = useState([]);
  const [isAddServiceOpen, setIsAddServiceOpen] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [serviceID, setServiceID] = useState("");
  const [servicePrice, setServicePrice] = useState(0);
  const [serviceMinutes, setServiceMinutes] = useState(0);
  const [isAddingService, setIsAddingService] = useState(false);
  useEffect(() => {
    if (serviceToEdit) return;
    setServiceID(serviceName.toUpperCase());
  }, [serviceName]);
  useEffect(() => {
    setServiceName("");
    setServiceID("");
  }, [isAddServiceOpen]);
  const addService = () => {
    if (isAddingService) return;
    var valid = true;
    if (serviceName === "") {
      toast.error("Ange tjänstens namn");
      valid = false;
    }
    if (serviceID === "") {
      toast.error("Ange tjänstens id");
      valid = false;
    }

    if (!valid) return;
    setIsAddingService(true);
    const db = getFirestore();

    const promise = updateDoc(doc(db, "meta", "services"), {
      services: arrayUnion({
        label: serviceName,
        id: serviceID,
        price: parseInt(servicePrice),
      }),
    })
      .then(() => {
        setIsAddingService(false);
        setIsAddServiceOpen(false);
      })
      .catch((err) => {
        console.error({ err });
        setIsAddingService(false);
      });

    toast.promise(promise, {
      pending: "Uppdaterar tjänster... Vänligen lämna inte sidan.",
      error: "Någonting gick fel. Vänligen försök igen senare.",
      success: "Tjänster uppdaterade!",
    });
  };

  const [isRemoveServiceOpen, setIsRemoveServiceOpen] = useState(false);
  const [serviceToRemove, setServiceToRemove] = useState(null);
  const [removeServiceInput, setRemoveServiceInput] = useState("");
  const removeService = () => {
    if (!serviceToRemove.id) return;
    if (serviceToRemove.id.toLowerCase() !== removeServiceInput.toLowerCase()) {
      toast.error("Fraserna stämde inte överens");
    }
    const db = getFirestore();

    const promise = updateDoc(doc(db, "meta", "services"), {
      services: arrayRemove(serviceToRemove),
    })
      .then(() => {
        setIsRemoveServiceOpen(false);
        setServiceToRemove(null);
      })
      .catch((err) => {
        console.error({ err });
      });

    toast.promise(promise, {
      pending: "Uppdaterar tjänster... Vänligen lämna inte sidan.",
      error: "Någonting gick fel. Vänligen försök igen senare.",
      success: "Tjänster uppdaterade!",
    });
  };

  const [isEditServiceOpen, setIsEditServiceOpen] = useState(false);
  const [serviceToEdit, setServiceToEdit] = useState(null);
  useEffect(() => {
    if (!serviceToEdit) return;
    setServiceName(serviceToEdit.label);
    setServiceID(serviceToEdit.id);
    setServicePrice(serviceToEdit.price || 0);
    setServiceMinutes(serviceToEdit.time || 0);
  }, [serviceToEdit]);
  useEffect(() => {
    if (isEditServiceOpen) return;
    setServiceToEdit(null);
    setServiceName("");
    setServiceID("");
    setServicePrice(0);
    setServiceMinutes(0);
  }, [isEditServiceOpen]);

  const updateService = () => {
    if (!serviceToEdit) return;
    const db = getFirestore();
    const promise = updateDoc(doc(db, "meta", "services"), {
      services: arrayRemove(serviceToEdit),
    })
      .then(() => {
        updateDoc(doc(db, "meta", "services"), {
          services: arrayUnion({
            label: serviceName,
            id: serviceID,
            price: parseInt(servicePrice) || null,
            time: parseInt(serviceMinutes) || null,
          }),
        });
      })
      .then(() => {
        setIsEditServiceOpen(false);
      })
      .catch((err) => {
        console.error({ err });
      });

    toast.promise(promise, {
      pending: "Uppdaterar tjänster... Vänligen lämna inte sidan.",
      error: "Någonting gick fel. Vänligen försök igen senare.",
      success: "Tjänster uppdaterade!",
    });
  };

  useEffect(() => {
    const db = getFirestore();
    const listener = onSnapshot(
      doc(db, "meta", "services"),
      (snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.data();
          setServices(data.services);
        }
      },
      (err) => {
        console.error({ err });
      }
    );
    return () => {
      listener();
    };
  }, []);
  return (
    <div className="serviceList">
      {services.map((service) => {
        return (
          <div className="serviceWrapper">
            <div className="text">
              <p>{service.label}</p>
              <small className="faded mono">
                {service.id} · {service.price || 0}kr · {service.minutes || 0}min
              </small>
            </div>
            <img
              src={Edit}
              alt=""
              className="doinvert"
              tooltip="Redigera"
              onClick={() => {
                setServiceToEdit(service);
                setIsEditServiceOpen(true);
              }}
            />
            <img
              src={Trash}
              alt=""
              tooltip="Ta bort"
              onClick={() => {
                setServiceToRemove(service);
                setIsRemoveServiceOpen(true);
              }}
            />
          </div>
        );
      })}
      {isEditServiceOpen ? (
        <Modal
          isOpen={isEditServiceOpen}
          setIsOpen={setIsEditServiceOpen}
          backNormal={"Avbryt"}
          backTarget={"Redigera meta"}
        >
          <h2
            style={{
              marginBottom: "1.25rem",
            }}
          >
            Redigera tjänst
          </h2>
          <form className="fullwidth">
            <label>Tjänstens namn</label>
            <input
              type="text"
              placeholder="Namn"
              value={serviceName}
              onChange={(e) => setServiceName(e.target.value)}
            />
            <label>Tjänstens ID</label>
            <input
              type="text"
              placeholder="ID"
              value={serviceID}
              onChange={(e) => setServiceID(e.target.value)}
              disabled
            />
            <label>Tjänstens pris</label>
            <input
              type="number"
              placeholder="Pris"
              value={servicePrice}
              onChange={(e) => setServicePrice(e.target.value)}
            />
            <label>Uppskattad tidsåtgång (minuter)</label>
            <input
              type="number"
              placeholder="Minuter"
              value={serviceMinutes}
              onChange={(e) => setServiceMinutes(e.target.value)}
            />
            <div></div>
            <button type="button" onClick={updateService}>
              Spara ändringar
            </button>
          </form>
        </Modal>
      ) : null}
      {isRemoveServiceOpen ? (
        <Modal
          isOpen={isRemoveServiceOpen}
          setIsOpen={setIsRemoveServiceOpen}
          backNormal={"Avbryt"}
          backTarget={"Redigera meta"}
        >
          <h2
            style={{
              marginBottom: "1.25rem",
            }}
          >
            Ta bort tjänst
          </h2>
          <p>
            Vänligen bekräfta genom att skriva {serviceToRemove.id} i fältet
            nedan
          </p>
          <form action={removeService}>
            <label>Bekräfta</label>
            <input
              type="text"
              placeholder={serviceToRemove.id}
              value={removeServiceInput}
              onChange={(e) => setRemoveServiceInput(e.target.value)}
            />
            <button type="button" onClick={removeService}>
              Ta bort
            </button>
          </form>
        </Modal>
      ) : null}
      <button type="button" onClick={() => setIsAddServiceOpen(true)}>
        <img src={Plus} className="doinvert" alt="" />
        Lägg till
      </button>
      {isAddServiceOpen ? (
        <Modal
          isOpen={isAddServiceOpen}
          setIsOpen={setIsAddServiceOpen}
          backNormal={"Avbryt"}
          backTarget={"Redigera meta"}
        >
          <h2
            style={{
              marginBottom: "1.25rem",
            }}
          >
            Lägg till tjänst
          </h2>
          <form className="fullwidth">
            <label>Tjänstens namn</label>
            <input
              type="text"
              placeholder="Namn"
              value={serviceName}
              onChange={(e) => setServiceName(e.target.value)}
            />
            <label>Tjänstens ID</label>
            <input
              type="text"
              placeholder="ID"
              value={serviceID}
              onChange={(e) => setServiceID(e.target.value)}
            />
            <label>Tjänstens pris</label>
            <input
              type="number"
              placeholder="Pris"
              value={servicePrice}
              onChange={(e) => setServicePrice(e.target.value)}
            />
            <label>Uppskattad tidsåtgång (minuter)</label>
            <input
              type="number"
              placeholder="Minuter"
              value={serviceMinutes}
              onChange={(e) => setServiceMinutes(e.target.value)}
            />
            <div></div>
            <button type="button" onClick={addService}>
              Lägg till
            </button>
          </form>
        </Modal>
      ) : null}
    </div>
  );
};

export default EditServicesWrapper;
