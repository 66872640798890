import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { getFullname } from "../../../functions";
import UserWrapper from "./wrappers/UserWrapper";

import EmptyScreen from "../../global/EmptyScreen";
import idx from "idx";

const MemberList = ({ members }) => {
  const [filteredMembers, setFilteredMembers] = useState(null);
  const [memberFilter, setMemberFilter] = useState("");

  useEffect(() => {
    if (memberFilter === "") {
      setFilteredMembers(members);
      return;
    }
    const result = members.filter((e) =>
      getFullname(e).toUpperCase().includes(memberFilter.toUpperCase())
    );
    setFilteredMembers(result);
  }, [members, memberFilter]);

  return (
    <div className="memberlistwrapper">
      <form>
        <input
          type="text"
          value={memberFilter}
          onChange={(e) => setMemberFilter(e.target.value)}
          placeholder="Sök..."
        />
      </form>
      {filteredMembers ? (
        filteredMembers.length > 0 ? (
          <div className="memberlist">
            {filteredMembers.map((member) => {
              return (
                <UserWrapper
                  key={idx(member, (_) => _.uid) || uuidv4()}
                  member={member}
                />
              );
            })}
          </div>
        ) : (
          <EmptyScreen
            title={"Inget här!"}
            message={"Testa att ändra dina filtreringsinställningar"}
          />
        )
      ) : null}
    </div>
  );
};

export default React.memo(MemberList);
