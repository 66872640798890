import {
  addDoc,
  collection,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  doc,
  Timestamp,
} from 'firebase/firestore';
import idx from 'idx';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  getHumanDateTime,
  getStatus,
  statusOptions,
} from '../../../../functions';
import DatePicker from 'react-datepicker';

import Plus from '../../../../assets/plus.svg';

const StatusTimeline = ({ booking, userPermissions }) => {
  const [statuses, setStatuses] = useState([]);
  useEffect(() => {
    if (!booking) return;
    const db = getFirestore();
    const q = query(
      collection(db, 'users', booking.uid, 'bookings', booking.id, 'status'),
      orderBy('timeCreated', 'desc')
    );
    const listener = onSnapshot(
      q,
      (snapshot) => {
        setStatuses((_) => {
          return snapshot.docs.map((e) => {
            return e.data();
          });
        });
      },
      (err) => {
        console.error(err);
      }
    );

    return () => {
      listener();
    };
  }, [booking]);

  const [updateStatus, setUpdateStatus] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(
    idx(booking, (_) => _.status) || statusOptions[0]
  );
  const [bookedTime, setBookedTime] = useState(
    idx(booking, (_) => _.bookedtime.toDate()) || null
  );
  const [comment, setComment] = useState('');

  const [isSubmittingUpdate, setIsSubmittingUpdate] = useState(false);
  const submitUpdate = async () => {
    if (isSubmittingUpdate) return;
    if (!booking) return;
    var valid = true;
    if (selectedStatus === 'BOOKED' && !bookedTime) {
      toast.error('Du måste ange bokad tid för denna status');
      valid = false;
    }
    if (!valid) {
      return;
    }
    setIsSubmittingUpdate(true);

    const db = getFirestore();

    if (selectedStatus === 'BOOKED' && bookedTime) {
      await updateDoc(doc(db, 'users', booking.uid, 'bookings', booking.id), {
        bookedtime: Timestamp.fromDate(bookedTime),
      });
    }

    const promise = addDoc(
      collection(db, 'users', booking.uid, 'bookings', booking.id, 'status'),
      {
        status: selectedStatus,
        comment: comment,
        timeCreated: serverTimestamp(),
      }
    ).then(() => {
      setComment('');
      setSelectedStatus(idx(booking, (_) => _.status) || statusOptions[0]);
      setUpdateStatus(false);
    });

    toast.promise(promise, {
      pending: 'Uppdaterar status... Vänligen lämna inte sidan.',
      error: 'Någonting gick fel! Vänligen försök igen senare.',
      success: 'Allt gick bra! Bokningens status är uppdaterad.',
    });

    await promise;
    setIsSubmittingUpdate(false);
  };

  return (
    <>
      <div className="statusTimeline">
        {statuses.map((status) => {
          return (
            <div className="statusTimelineRow">
              {status.timeCreated ? (
                <small className="faded mono">
                  {getHumanDateTime(status.timeCreated)}
                </small>
              ) : null}
              <p>{getStatus(status)}</p>
              {status.comment ? <small>{status.comment}</small> : null}
            </div>
          );
        })}
      </div>
      {(userPermissions === 'superadmin' || userPermissions === "admin") && (
        <>
          <div
            className="updateStatusButton"
            onClick={() => setUpdateStatus((p) => !p)}
          >
            <img
              src={Plus}
              style={{
                transform: updateStatus ? 'rotate(45deg)' : 'none',
              }}
              className="doinvert"
              alt=""
            />
            {updateStatus ? 'Avbryt' : 'Uppdatera status'}
          </div>
          {updateStatus ? (
            <div>
              <form>
                <label>Status</label>
                <select
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  {statusOptions.map((e) => {
                    return (
                      <option value={e.value} key={e.value}>
                        {e.label}
                      </option>
                    );
                  })}
                </select>
                {selectedStatus === 'BOOKED' && (
                  <>
                    <label>Bokad tid</label>
                    <DatePicker
                      selected={bookedTime}
                      onChange={(date) => setBookedTime(date)}
                      id="bookedTime"
                      showTimeSelect
                      timeIntervals={10}
                    />
                  </>
                )}
                <label>Kommentar</label>
                <textarea
                  value={comment}
                  placeholder="Kommentar (valfritt)"
                  onChange={(e) => setComment(e.target.value)}
                />
                <button type="button" onClick={submitUpdate}>
                  Spara
                </button>
              </form>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default StatusTimeline;
