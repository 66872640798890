import React, { useEffect, useRef, useState } from 'react';
import idx from 'idx';
import 'react-datepicker/dist/react-datepicker.css';
import { v4 as uuidv4 } from 'uuid';
import {
  createBooking,
  getFullname,
  getUserPermissions,
  removeFromURL,
  validateFile,
} from '../../../functions';
import { toast } from 'react-toastify';
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  query,
  where,
} from '@firebase/firestore';

// components:
import DatePicker from 'react-datepicker';
import CheckboxGroup from '../../global/CheckboxGroup';
import EditingStyleSelector from './EditingStyleSelector';
import RadioGroup from '../../global/RadioGroup';

// assets:
import CheckMark from '../../../assets/checkmark.svg';
import GoogleCalendar from '../../../assets/googlecalendar.svg';
import parsePhoneNumberFromString, {
  isValidPhoneNumber,
} from 'libphonenumber-js';
import AutocompleteInput from '../../global/AutocompleteInput';

const BookingForm = ({ user, organization }) => {
  const [userPermissions, setUserPermissions] = useState(null);
  useEffect(() => {
    getUserPermissions((res) => {
      setUserPermissions(res);
    });
  }, []);
  const [name, setName] = useState('');

  const [autocompleteKey, setAutocompleteKey] = useState(uuidv4());

  const [client, setClient] = useState(idx(user, (_) => _.uid));
  const [clients, setClients] = useState([]);

  const [organizations, setOrganizations] = useState([]);
  const [org, setOrg] = useState(idx(user, (_) => _.organization));

  useEffect(() => {
    setAutocompleteKey(uuidv4());
  }, [organizations, clients]);

  useEffect(() => {
    if (client) {
      const db = getFirestore();
      getDoc(doc(db, 'users', client)).then((snapshot) => {
        if (snapshot.exists) {
          setName(getFullname(snapshot.data()));
        }
      });
    }
  }, [org, client]);

  useEffect(() => {
    const db = getFirestore();
    const listener = onSnapshot(collection(db, 'organizations'), (snapshot) => {
      var tempOrganizations = [];
      snapshot.forEach((org) => {
        tempOrganizations.push(org.data());
      });
      setOrganizations(tempOrganizations);
    });

    return () => {
      listener();
    };
  }, []);

  const [googleCalendarLink, setGoogleCalendarLink] = useState(null);

  useEffect(() => {
    if (
      !idx(organization, (_) => _.formoptions.includes('SHOW_CALENDAR')) ||
      googleCalendarLink
    ) {
      return;
    }

    const db = getFirestore();
    getDoc(doc(db, 'meta', 'calendars'))
      .then((res) => {
        if (res.exists) {
          setGoogleCalendarLink(idx(res, (_) => _.data().sharedCalendar));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [organization]);

  useEffect(() => {
    if (!org) return;
    const db = getFirestore();
    const q = query(collection(db, 'users'), where('organization', '==', org));
    const listener = onSnapshot(q, (snapshot) => {
      var tempClients = [];
      snapshot.forEach((cli) => {
        let data = cli.data();
        data.fullName = getFullname(data);
        tempClients.push(data);
      });
      setClients(tempClients);
    });

    return () => {
      listener();
    };
  }, [org]);

  const [deadline, setDeadline] = useState(new Date());
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [instructions, setInstructions] = useState('');
  const [estateType, setEstateType] = useState('');
  const [propertySize, setPropertySize] = useState(0);

  const [services, setServices] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);

  useEffect(() => {
    const db = getFirestore();
    getDoc(doc(db, 'meta', 'services'))
      .then((snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.data();
          const sOpt = data.services.map((it) => {
            return {
              label: it.label,
              value: it.id,
            };
          });
          setServiceOptions(sOpt);
        }
      })
      .catch((err) => {
        console.error({ err });
      });
  }, []);

  const [editingStyle, setEditingStyle] = useState('');

  const [sellerName, setSellerName] = useState('');
  const [sellerPhone, setSellerPhone] = useState('+46');
  const [phoneDisplay, setPhoneDisplay] = useState('');
  useEffect(() => {
    var filtered = '';
    for (var i = 0; i < sellerPhone.length; i++) {
      const char = sellerPhone[i];
      if (
        char === '0' ||
        char === '1' ||
        char === '2' ||
        char === '3' ||
        char === '4' ||
        char === '5' ||
        char === '6' ||
        char === '7' ||
        char === '8' ||
        char === '9' ||
        char === '+'
      ) {
        filtered += char;
      }
    }
    setSellerPhone(filtered);
    var formatted = parsePhoneNumberFromString(filtered);
    try {
      formatted = formatted.formatInternational();
      setPhoneDisplay(formatted);
    } catch {
      setPhoneDisplay(filtered);
    }
  }, [sellerPhone]);

  useEffect(() => {
    console.log(sellerPhone);
  }, [sellerPhone]);

  const [sellerEmail, setSellerEmail] = useState('');

  const [other, setOther] = useState('');

  const bookingOptions = [
    {
      label: 'Jag (mäklaren) har redan bokat fotograferingstillfälle',
      value: 'REALTOR',
    },
    {
      label: 'Fotografen ska själv boka fotograferingstillfälle med säljaren',
      value: 'PHOTOGRAPHER',
    },
  ];
  const [whoIsBooking, setWhoIsBooking] = useState(bookingOptions[1].value);

  const [bookedTime, setBookedTime] = useState(null);

  useEffect(() => {
    if (whoIsBooking !== 'REALTOR') {
      setBookedTime(null);
    } else {
      setBookedTime(new Date());
    }
  }, [whoIsBooking]);

  const [files, setFiles] = useState([]);
  const fileRef = useRef(null);
  const updateFiles = () => {
    const registry =
      /(\.png|\.jpg|\.jpeg|\.pdf|\.gif|\.heic|\.zip|\.rar|\.txt|\.docx|\.pages|\.rtf|\.eps|\.svg)$/i;
    var tempFiles = [];
    Object.keys(fileRef.current.files).forEach((key) => {
      let file = fileRef.current.files[key];
      if (validateFile(file.name, registry)) {
        if (file.size < 1000 * 1000 * 10) {
          tempFiles.push(file);
        } else {
          toast.error(
            `${file.name} kommer inte laddas upp p.g.a: Filstorleken överskrider maxgränsen om 10mb.`
          );
        }
      } else {
        toast.error(
          `${file.name} kommer inte laddas upp p.g.a: Otillåtet filformat.`
        );
      }
    });
    setFiles(tempFiles);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = () => {
    if (isSubmitting) return;
    // verify fields:
    var valid = true;
    if (name == null || name === '') {
      toast.error('Ange namn');
      valid = false;
    }
    if (deadline == null || deadline === '') {
      toast.error('Ange när bilderna behöver vara klara');
      valid = false;
    }
    if (address == null || address === '') {
      toast.error('Ange fastighetens adress');
      valid = false;
    }
    if (estateType == null || estateType === '') {
      toast.error('Ange typ av fastighet');
      valid = false;
    }
    if (propertySize == null || propertySize === '') {
      toast.error('Ange fastighetens storlek');
      valid = false;
    }
    if (services == null || services === '' || services.length < 1) {
      toast.error('Ange minst en tjänst');
      valid = false;
    }
    if (idx(organization, (_) => _.formoptions.includes('SHOW_EDITINGSTYLE'))) {
      if (editingStyle == null || editingStyle === '') {
        toast.error('Ange redigeringstyp');
        valid = false;
      }
    }
    if (sellerName == null || sellerName === '') {
      toast.error('Ange namn på säljaren');
      valid = false;
    }
    if (!isValidPhoneNumber(sellerPhone, 'SE')) {
      toast.error('Ange ett giltigt telefonnummer till säljaren');
      valid = false;
    }
    if (idx(organization, (_) => _.formoptions.includes('SHOW_BOOKINGTIME'))) {
      if (whoIsBooking == null || whoIsBooking === '') {
        toast.error('Ange vem som ska boka fotograferingstillfället');
        valid = false;
      }
      if (whoIsBooking === 'REALTOR') {
        if (bookedTime == null) {
          toast.error('Ange när fotograferingstillfället är inbokat');
          valid = false;
        }
      } else {
        setBookedTime(null);
      }
    } else {
      setBookedTime(null);
    }

    if (!valid) return;

    setIsSubmitting(true);

    createBooking(
      name,
      client,
      org,
      deadline,
      address,
      postalCode,
      city,
      instructions,
      estateType,
      propertySize,
      services,
      editingStyle,
      sellerName,
      sellerPhone,
      sellerEmail,
      other,
      whoIsBooking,
      bookedTime,
      files,
      (callback) => {
        setIsSubmitting(false);
        if (callback) {
          removeFromURL('newbooking');
        }
      }
    );
  };

  return (
    <form onSubmit={submit} autoComplete="nope">
      {idx(organization, (_) => _.formoptions.includes('SHOW_CALENDAR')) ? (
        <a
          href={googleCalendarLink}
          target="_blank"
          rel="noreferrer"
          className={`googlecalendarbutton${
            !googleCalendarLink ? ' disabled' : ''
          }`}
        >
          <button type="button">
            <img src={GoogleCalendar} alt="" rel="noreferrer" />
            Visa kalender
          </button>
        </a>
      ) : null}
      {userPermissions === 'superadmin' ? (
        <>
          <label htmlFor="name" className="required">
            Organisation
          </label>
          <AutocompleteInput
            id={'organization'}
            placeholder={'Välj organisation'}
            options={organizations}
            displaydict={'name'}
            resdict={'id'}
            state={org}
            setState={setOrg}
            key={autocompleteKey}
          />
        </>
      ) : null}
      {userPermissions === 'assistant' ||
      userPermissions === 'admin' ||
      userPermissions === 'superadmin' ? (
        <>
          <label htmlFor="client" className="required">
            Bokare
          </label>
          <AutocompleteInput
            id={'client'}
            placeholder={'Välj kund'}
            options={clients}
            displaydict={'fullName'}
            resdict={'uid'}
            state={client}
            setState={setClient}
            key={autocompleteKey}
          />
        </>
      ) : null}
      <label htmlFor="deadline" className="required">
        När behöver bilderna vara klara?
      </label>
      <DatePicker
        selected={deadline}
        onChange={(date) => setDeadline(date)}
        id="deadline"
      />
      <label htmlFor="address" className="required">
        Adress
      </label>
      <input
        type="text"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        id="address"
        placeholder="Adress"
        autoComplete="off"
      />
      <label className="required">Postnummer</label>
      <input
        type="text"
        value={postalCode}
        onChange={(e) => setPostalCode(e.target.value)}
        id="postalcode"
        placeholder="Postnummer"
        autoComplete="off"
      />
      <label className="required">Ort</label>
      <input
        type="text"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        id="city"
        placeholder="Ort"
        autoComplete="off"
      />
      <label htmlFor="instructions">
        Övrig information gällande fastigheten
      </label>
      <textarea
        value={instructions}
        onChange={(e) => setInstructions(e.target.value)}
        id="instructions"
        placeholder="T.ex portkod, kodlås, gömd nyckel"
        rows="6"
      />
      <label className="required" htmlFor="estatetype">
        Typ av fastighet
      </label>
      <select id="estatetype" onChange={(e) => setEstateType(e.target.value)}>
        <option value="DISALLOWED" selected disabled>
          Välj
        </option>
        <option value="LÄGENHET">Lägenhet</option>
        <option value="VILLA">Villa</option>
        <option value="RADHUS">Radhus</option>
        <option value="FRITIDSHUS">Fritidshus</option>
      </select>
      <label className="required" htmlFor="propertysize">
        Fastighetens storlek (i m²)
      </label>
      <input
        type="number"
        value={propertySize}
        onChange={(e) => setPropertySize(e.target.value)}
        id="propertysize"
      />
      <label className="required" htmlFor="requiredservices">
        Vilka typer av fototjänster behövs?
      </label>
      <CheckboxGroup
        rows={serviceOptions}
        state={services}
        setState={setServices}
        id="requiredservices"
      />
      {idx(organization, (_) => _.formoptions.includes('SHOW_EDITINGSTYLE')) ? (
        <>
          <label className="required" htmlFor="editingstyle">
            Vilken typ av redigering efterfrågas?
          </label>
          <EditingStyleSelector
            state={editingStyle}
            setState={setEditingStyle}
            id={'editingstyle'}
          />
        </>
      ) : null}
      <label htmlFor="sellername" className="required">
        Namn på säljaren
      </label>
      <input
        type="text"
        value={sellerName}
        onChange={(e) => setSellerName(e.target.value)}
        id="sellername"
        placeholder="Säljarens namn"
        autoComplete="off"
      />
      <label htmlFor="sellerphone" className="required">
        Telefonnummer till säljaren
      </label>
      <input
        type="text"
        value={phoneDisplay}
        onChange={(e) => setSellerPhone(e.target.value)}
        id="sellerphone"
        placeholder="Säljarens telefonnummer"
        autoComplete="off"
      />
      <label htmlFor="selleremail">Mejladress till säljaren</label>
      <input
        type="email"
        value={sellerEmail}
        onChange={(e) => setSellerEmail(e.target.value)}
        id="selleremail"
        placeholder="Säljarens e-postadress"
        autoComplete="off"
      />
      <label htmlFor="other">Ytterligare information och önskemål här:</label>
      <textarea
        value={other}
        onChange={(e) => setOther(e.target.value)}
        id="other"
        rows="6"
        placeholder="Övrigt"
      />
      {idx(organization, (_) => _.formoptions.includes('SHOW_BOOKINGTIME')) ? (
        <>
          <label htmlFor="booker" className="required">
            Vem ska boka fotograferingstillfälle med säljaren?
          </label>
          <RadioGroup
            rows={bookingOptions}
            id={'booker'}
            state={whoIsBooking}
            setState={setWhoIsBooking}
          />
          {whoIsBooking === 'REALTOR' ? (
            <>
              <label htmlFor="deadline" className="required">
                När är det inbokat?
              </label>
              <DatePicker
                selected={bookedTime}
                onChange={(date) => setBookedTime(date)}
                id="bookedTime"
                showTimeSelect
                timeIntervals={10}
              />
            </>
          ) : null}
        </>
      ) : null}
      <label htmlFor="fileinput">
        Om du har några filer, t.ex. planritning, kan du bifoga dem här.
      </label>
      <div className="fileinput">
        <input
          type="file"
          multiple
          ref={fileRef}
          onChange={updateFiles}
          id="fileinput"
          accept=".png, .jpg, .jpeg, .pdf, .gif, .heic, .zip, .rar, .txt, .docx, .pages, .rtf, .eps, .svg"
        />
        <div className={`filebox ${files.length < 1 ? 'empty' : ''}`}>
          {files.length > 0 ? (
            files.map((file) => {
              return (
                <p>
                  <small>
                    {file.name}
                    <span className="mono faded">
                      {' '}
                      - {Math.round((file.size / 1000) * 100) / 100}kb
                    </span>
                  </small>
                </p>
              );
            })
          ) : (
            <p className="faded filedisclaimer">
              Släpp filer här eller klicka för att öppna filväljaren
            </p>
          )}
        </div>
      </div>
      <div></div>
      <button type="button" onClick={submit}>
        <img src={CheckMark} className="doinvert" alt="" />
        Skapa bokning
      </button>
    </form>
  );
};

export default React.memo(BookingForm);
