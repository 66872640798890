import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import {
  containsURL,
  createUser,
  getURLParameter,
  getUserPermissions,
  removeFromURL,
} from "../../../functions";
import { collection, onSnapshot, getDoc, doc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

// components:
import Backbutton from "../../global/Backbutton";
import { toast } from "react-toastify";

// assets:
import CheckMark from "../../../assets/checkmark.svg";

const AddUser = ({ db }) => {
  const [permissions, setPermissions] = useState(null);
  useEffect(() => {
    getUserPermissions((callback) => {
      setPermissions(callback);
    });
  }, []);

  const [usertype, setUsertype] = useState("DISALLOWED");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("DISALLOWED");
  const [jobTitle, setJobTitle] = useState("");
  const [emailAdress, setEmailAdress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    var listener;
    const orgid = getURLParameter("orgid");
    if (orgid) {
      getDoc(doc(db, "organizations", orgid)).then((res) => {
        if (res.exists) {
          setOrganizations([res.data()]);
          setOrganization(res.data().id);
        }
      });
    } else {
      listener = onSnapshot(collection(db, "organizations"), (snapshot) => {
        const tempOrganizations = [];
        snapshot.forEach((doc) => {
          tempOrganizations.push(doc.data());
        });
        tempOrganizations.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        setOrganizations(tempOrganizations);
      });
    }

    return () => {
      if (listener != null) {
        listener();
      }
    };
  }, []);

  const submit = (e) => {
    e.preventDefault();

    if (usertype === "" || usertype === "DISALLOWED") {
      toast.error("Ange användarens typ");
      return;
    }
    if (usertype === "superadmin" && organization !== "INTERNAL") {
      toast.error("Denna användartyp är inte tillåten inom kundorganisationer");
      return;
    }
    if (firstName === "") {
      toast.error("Ange användarens förnamn");
      return;
    }
    if (lastName === "") {
      toast.error("Ange användarens efternamn");
      return;
    }
    if (organization === "" || organization === "DISALLOWED") {
      toast.error("Ange användarens organisation");
      return;
    }
    if (jobTitle === "") {
      toast.error("Ange användarens jobbtitel");
      return;
    }
    if (emailAdress === "") {
      toast.error("Ange användarens e-postadress");
      return;
    }
    if (phoneNumber === "") {
      toast.error("Ange användarens telefonnummer");
      return;
    }

    createUser(
      usertype,
      firstName,
      lastName,
      organization,
      jobTitle,
      emailAdress,
      phoneNumber,
      (callback) => {
        if (callback) {
          removeFromURL("adduser");
        }
      }
    );
  };

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <animated.div style={springprops}>
      <div className="createbooking board">
        <Backbutton
          target={"Hantera användare"}
          onClick={() => removeFromURL("adduser")}
        />
        <h1 className="title">Lägg till användare</h1>
        <p className="subtitle">Skapa en ny användare i portalen</p>
        <form onSubmit={submit}>
          <label htmlFor="usertype" className="required">
            Typ av användare
          </label>
          <select
            value={usertype}
            onChange={(e) => setUsertype(e.target.value)}
            id="usertype"
          >
            <option value="DISALLOWED" selected disabled>
              Välj
            </option>
            <option value="client">Mäklare</option>
            <option value="assistant">Assistent</option>
            {permissions === "superadmin" || permissions === "admin" ? (
              <option value="admin">Admin</option>
            ) : null}
            {permissions === "superadmin" && organization === "INTERNAL" ? (
              <option value="superadmin">Superadmin</option>
            ) : null}
          </select>
          <label htmlFor="first-name" className="required">
            Förnamn
          </label>
          <input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            id="first-name"
            placeholder="Förnamn"
          />
          <label htmlFor="last-name" className="required">
            Efternamn
          </label>
          <input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            id="last-name"
            placeholder="Efternamn"
          />
          <label htmlFor="organization" className="required">
            Organisation
          </label>
          <select
            id="organization"
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
          >
            <option value="DISALLOWED" selected disabled>
              Välj
            </option>
            {organizations.map((org) => {
              if (org.id === "INTERNAL") {
                if (permissions === "superadmin") {
                  return (
                    <option value={org.id} key={uuidv4()}>
                      {org.name}
                    </option>
                  );
                }
              } else {
                return (
                  <option value={org.id} key={uuidv4()}>
                    {org.name}
                  </option>
                );
              }
            })}
          </select>
          <label htmlFor="job-title" className="required">
            Jobbtitel
          </label>
          <input
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            id="job-title"
            placeholder="Jobbtitel"
          />
          <label htmlFor="email" className="required">
            E-postadress
          </label>
          <input
            type="email"
            value={emailAdress}
            onChange={(e) => setEmailAdress(e.target.value)}
            id="email"
            placeholder="E-postadress"
          />
          <label htmlFor="phone" className="required">
            Telefonnummer
          </label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            id="phone"
            placeholder="Telefonnummer"
          />
          <div></div>
          <button onClick={submit} type="button">
            <img src={CheckMark} className="doinvert" alt="" />
            Skapa användare
          </button>
        </form>
      </div>
    </animated.div>
  );
};

export default AddUser;
