import React, { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import idx from 'idx';
import {
  addToURL,
  containsURL,
  getDiceBearAvatar,
  getUserPermissions,
} from '../../functions';
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  limit,
} from 'firebase/firestore';
import { signOut } from '@firebase/auth';

// components:
import Loadingboard from '../global/Loadingboard';
import CreateBooking from './booking/CreateBooking';
import Modal from '../global/Modal';
import Backbutton from '../global/Backbutton';
import HandleUsers from './handleusers/HandleUsers';
import HandleBookingsBoard from './handlebookings/HandleBookingsBoard';
import HandleOrganization from './handleusers/HandleOrganization';
import AddUser from './handleusers/AddUser';
import AddOrganization from './handleusers/AddOrganization';
import NotificationPopup from './NotificationPopup';
import StatisticsBoard from './statistics/StatisticsBoard';
import EditMeta from './handleusers/EditMeta';
import EditUserPopup from './EditUserPopup';

// assets:
import Booking from '../../assets/booking.svg';
import Archive from '../../assets/archive.svg';
import Users from '../../assets/users.svg';
import NewsFlash from '../../assets/newsflash.svg';
import Dropbox from '../../assets/dropbox.svg';
import BellOutlined from '../../assets/bell_outlined.svg';
import BellFilled from '../../assets/bell_filled.svg';
import Settings from '../../assets/settings.svg';
import Statistics from '../../assets/statistics.svg';
import HandleNewsflash from './handlenewsflash/HandleNewsflash';
import NewsflashWrapper from './wrappers/NewsflashWrapper';

const Dashboard = ({ auth, user, db, messaging }) => {
  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    getUserPermissions((callback) => {
      setPermissions(callback);
    });
  }, [user]);

  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    const orgid = idx(user, (_) => _.organization);
    if (orgid == null) return;
    const db = getFirestore();
    onSnapshot(doc(db, 'organizations', orgid), (snapshot) => {
      if (snapshot.exists) {
        setOrganization(snapshot.data());
      }
    });
  }, [user]);

  const [isSignoutConfirmOpen, setIsSignoutConfirmOpen] = useState(false);

  const [notifications, setNotifications] = useState([]);
  const [isShowingNotifications, setIsShowingNotifications] = useState(false);

  useEffect(() => {
    if (containsURL('notifications')) {
      setIsShowingNotifications(true);
    }
  }, []);

  const [isShowingUserSettings, setIsShowingUserSettings] = useState(false);

  useEffect(() => {
    const uid = idx(user, (_) => _.uid);
    if (!uid) return;
    const db = getFirestore();
    const q = query(
      collection(db, 'users', uid, 'notifications'),
      where('seen', '==', false)
    );
    const listener = onSnapshot(
      q,
      (snapshot) => {
        var tempNotifications = [];
        snapshot.forEach((d) => {
          tempNotifications.push(d.data());
        });
        setNotifications(tempNotifications);
      },
      (err) => {
        console.error(err);
      }
    );
    return () => {
      listener();
    };
  }, [user]);

  const [latestNewsflash, setLatestNewsflash] = useState(null);
  useEffect(() => {
    const orgid = idx(user, (_) => _.organization);
    if (orgid == null) return;
    const db = getFirestore();
    const q = query(
      collection(db, 'newsflashes'),
      where('published', '==', true),
      where('organizations', 'array-contains', orgid),
      orderBy('timeCreated', 'desc'),
      limit(1)
    );
    const listener = onSnapshot(
      q,
      (snapshot) => {
        const firstDoc = idx(snapshot, (_) => _.docs[0]);
        if (!firstDoc) return;
        var newsflashData = firstDoc.data();
        newsflashData.id = firstDoc.id;
        setLatestNewsflash(newsflashData);
      },
      (err) => {
        console.error(err);
      }
    );

    return () => {
      listener();
    };
  }, [user]);

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: '100%', maxHeight: '100%' },
    from: { opacity: 0, x: 16, maxWidth: '100%', maxHeight: '100%' },
    config: { friction: 20, tension: 400 },
  });

  if (!user) {
    return <Loadingboard />;
  }

  if (containsURL('newbooking')) {
    return <CreateBooking user={user} organization={organization} />;
  }

  if (containsURL('bookings')) {
    return <HandleBookingsBoard user={user} />;
  }

  if (containsURL('statistics')) {
    return <StatisticsBoard />;
  }

  if (containsURL('adduser')) {
    return <AddUser db={db} />;
  }

  if (containsURL('addorganization')) {
    return <AddOrganization db={db} />;
  }

  if (containsURL('editmeta')) {
    return <EditMeta />;
  }

  if (containsURL('handleorganization')) {
    return (
      <HandleOrganization
        userPermissions={permissions}
        parent={containsURL('users') ? 'Hantera användare' : 'Dashboard'}
      />
    );
  }

  if (containsURL('users')) {
    return <HandleUsers db={db} />;
  }

  if (containsURL('newsflash') && permissions === 'superadmin') {
    return <HandleNewsflash />;
  }

  return (
    <>
      <animated.div style={springprops}>
        <section className="dashboard board">
          <Backbutton
            normal={'Logga ut'}
            target={'Logga ut'}
            onClick={() => setIsSignoutConfirmOpen(true)}
          />
          {isSignoutConfirmOpen ? (
            <Modal
              isOpen={isSignoutConfirmOpen}
              setIsOpen={setIsSignoutConfirmOpen}
              backNormal={'Avbryt'}
              backTarget={'Dashboard'}
            >
              <h2
                style={{
                  marginBottom: '1.25rem',
                }}
              >
                Logga ut?
              </h2>
              <p>Vill du logga ut?</p>
              <form className="fullwidth">
                <button
                  className="destructive"
                  onClick={() => {
                    signOut(auth);
                  }}
                >
                  Logga ut
                </button>
              </form>
            </Modal>
          ) : null}
          <div className="actionRow">
            <div
              className="notificationbadge"
              tooltip="Profilinställningar"
              onClick={() => {
                setIsShowingUserSettings(true);
              }}
            >
              <div className="icon">
                <img src={Settings} alt="" className="doinvert" />
              </div>
            </div>
            <div
              className="notificationbadge"
              tooltip="Notifikationer"
              onClick={() => {
                setIsShowingNotifications(true);
              }}
            >
              <div className="notificationicon icon">
                <img
                  src={notifications.length > 0 ? BellFilled : BellOutlined}
                  alt=""
                  className="doinvert"
                />
                {notifications.length > 0 ? (
                  <div className="badge">
                    <p>
                      {notifications.length > 9 ? '9+' : notifications.length}
                    </p>
                  </div>
                ) : null}
              </div>
              <label htmlFor="">
                {notifications.length > 0
                  ? 'Nya notifikationer!'
                  : 'Inget oläst'}
              </label>
            </div>
          </div>
          <div className="title_avatar">
            <img
              src={
                idx(user, (_) => _.photoURL.w128) ||
                getDiceBearAvatar(idx(user, (_) => _.uid) || 'RAND')
              }
              className="db_avatar"
              alt=""
            />
            <h1 className="title">
              Välkommen {idx(user, (_) => _.name.first)}
            </h1>
          </div>

          {latestNewsflash ? (
            <NewsflashWrapper data={latestNewsflash} />
          ) : (
            <p className="subtitle">
              Vad vill du göra idag?
              {permissions ? (
                <>
                  <br />
                  <small className="mono faded">
                    Inloggad som {permissions}
                  </small>
                </>
              ) : null}
            </p>
          )}
          <div className="options">
            <div className="option" onClick={() => addToURL('newbooking')}>
              <img src={Booking} alt="" className="doinvert" />
              <p>Ny bokning</p>
            </div>
            <div className="option" onClick={() => addToURL('bookings')}>
              <img src={Archive} alt="" className="doinvert" />
              <p>Se bokningar</p>
            </div>
            <div className="option" onClick={() => addToURL('statistics')}>
              <img src={Statistics} alt="" className="doinvert" />
              <p>Statistik</p>
            </div>
            {permissions === 'superadmin' || permissions === 'admin' ? (
              <div
                className="option"
                onClick={() => {
                  switch (permissions) {
                    case 'superadmin':
                      addToURL('users');
                      break;
                    case 'admin':
                      addToURL(
                        `handleorganization?orgid=${idx(
                          user,
                          (_) => _.organization
                        )}`
                      );
                      break;
                    default:
                      break;
                  }
                }}
              >
                <img src={Users} alt="" className="doinvert" />
                <p>
                  {permissions === 'superadmin' ? 'Användare' : 'Organisation'}
                </p>
              </div>
            ) : null}
            {permissions === 'superadmin' ? (
              <div
                className="option"
                onClick={() => {
                  addToURL('newsflash');
                }}
              >
                <img src={NewsFlash} alt="" className="doinvert" />
                <p>Hantera Newsflash</p>
              </div>
            ) : null}
            {idx(organization, (_) => _.dropbox.link) ? (
              <div
                className="option"
                onClick={() => window.open(organization.dropbox.link, '_blank')}
              >
                <img src={Dropbox} alt="" />
                <p>Dropbox</p>
              </div>
            ) : null}
          </div>
        </section>
      </animated.div>
      {isShowingNotifications ? (
        <NotificationPopup showingSelf={setIsShowingNotifications} />
      ) : null}
      {isShowingUserSettings ? (
        <EditUserPopup showingSelf={setIsShowingUserSettings} />
      ) : null}
    </>
  );
};

export default Dashboard;
