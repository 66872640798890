import {
  updateDoc,
  collection,
  getDocs,
  getFirestore,
  serverTimestamp,
} from '@firebase/firestore';
import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { toast } from 'react-toastify';
import idx from 'idx';
import { v4 as uuid } from 'uuid';
import Backbutton from '../../global/Backbutton';
import MultiSelectInput from '../../global/MultiSelectInput';

import CheckMark from '../../../assets/checkmark.svg';
import UnChecked from '../../../assets/radio-unchecked.svg';
import Checked from '../../../assets/radio-checked.svg';

const EditNewsflashPopup = ({ providedNewsflash, setEditObject }) => {
  const [preTitle, setPreTitle] = useState(
    idx(providedNewsflash, (_) => _.preTitle) || ''
  );
  const [title, setTitle] = useState(
    idx(providedNewsflash, (_) => _.title) || ''
  );
  const [subTitle, setSubTitle] = useState(
    idx(providedNewsflash, (_) => _.subTitle) || ''
  );
  const [content, setContent] = useState(
    idx(providedNewsflash, (_) => _.content) || ''
  );
  const [link, setLink] = useState(idx(providedNewsflash, (_) => _.link) || '');
  const [organizations, setOrganizations] = useState([]);
  const [organizationRows, setOrganizationRows] = useState([]);
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [organizationDropdownKey, setOrganizationDropdownKey] = useState(
    uuid()
  );

  const [isPublished, setIsPublished] = useState(
    idx(providedNewsflash, (_) => _.published) || false
  );

  useEffect(() => {
    const db = getFirestore();
    getDocs(collection(db, 'organizations'))
      .then((snapshot) => {
        setAllOrganizations((_) => {
          return snapshot.docs.map((e) => {
            return e.data();
          });
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    setOrganizationRows((_) => {
      return allOrganizations.map((e) => {
        return {
          label: e.name,
          value: e.id,
        };
      });
    });
    if (!idx(providedNewsflash, (_) => _.organizations.length)) {
      setOrganizations((_) => {
        return allOrganizations.map((e) => {
          return e.id;
        });
      });
    } else {
      setOrganizations(idx(providedNewsflash, (_) => _.organizations));
    }
    setOrganizationDropdownKey(uuid());
  }, [allOrganizations]);

  const [isUpdatingNewsflash, setIsUpdatingNewsflash] = useState(false);
  const submit = () => {
    if (isUpdatingNewsflash) return;
    var valid = true;
    if (!title) {
      valid = false;
      toast.error('Ange en titel');
    }
    if (!organizations.length) {
      valid = false;
      toast.error('Välj minst en organisation');
    }
    if (!valid) return;
    setIsUpdatingNewsflash(true);

    const documentData = {
      preTitle: preTitle,
      title: title,
      subTitle: subTitle,
      content: content,
      link: link,
      organizations: organizations,
      published: isPublished,
    };

    if (isPublished !== providedNewsflash.isPublished && isPublished) {
      documentData['timeCreated'] = serverTimestamp();
    }

    const promise = updateDoc(providedNewsflash.ref, documentData)
      .then(() => {
        setIsUpdatingNewsflash(false);
        setEditObject(null);
      })
      .catch((err) => {
        console.error(err);
        setIsUpdatingNewsflash(false);
      });

    toast.promise(promise, {
      pending: 'Sparar ändringar... Vänligen lämna inte sidan',
      error: 'Någonting gick fel! Vänligen försök igen senare.',
      success: 'Ändringar sparade utan problem!',
    });
  };

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: '100%', maxHeight: '100%' },
    from: { opacity: 0, x: 16, maxWidth: '100%', maxHeight: '100%' },
    config: { friction: 20, tension: 400 },
  });

  return (
    <div className="modal blur">
      <animated.div style={springprops}>
        <div className="card medium">
          <Backbutton
            normal={'Stäng'}
            target={'Hantera Newsflash'}
            onClick={() => {
              setEditObject(null);
            }}
          />
          <form>
            <label>Förtitel</label>
            <input
              type="text"
              placeholder="T.ex. 'NYHET!'"
              value={preTitle}
              onChange={(e) => setPreTitle(e.target.value)}
            />
            <label className="required">Titel</label>
            <input
              type="text"
              placeholder="Titel"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <label>Undertitel</label>
            <input
              type="text"
              placeholder="T.ex. 'Läs mer på bloggen'"
              value={subTitle}
              onChange={(e) => setSubTitle(e.target.value)}
            />
            <label>Innehåll</label>
            <textarea
              placeholder="Längre innehåll"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <label>Länk</label>
            <input
              type="text"
              placeholder="Länk"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
            <label className="required">Organisationer</label>
            <MultiSelectInput
              rows={organizationRows}
              state={organizations}
              setState={setOrganizations}
              hasSelectAll={true}
              key={organizationDropdownKey}
            />
            <label>Status</label>
            <div className="checkboxgroup">
              <div
                className="checkboxrow"
                onClick={() => setIsPublished((p) => !p)}
              >
                <img
                  src={isPublished ? Checked : UnChecked}
                  className="doinvert radio"
                  alt=""
                />
                <p>Publicerad</p>
              </div>
            </div>
            <div></div>
            <button onClick={submit} type="button">
              <img src={CheckMark} className="doinvert" alt="" />
              Spara ändringar
            </button>
          </form>
        </div>
      </animated.div>
    </div>
  );
};

export default EditNewsflashPopup;
