import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "@firebase/firestore";
import { React, useState, useEffect } from "react";
import {
  getFullname,
  getHumanDate,
  getUserPermissions,
  removeFromURL,
} from "../../../functions";
import AutocompleteInput from "../../global/AutocompleteInput";
import SelectionPillGroup from "../../global/SelectionPillGroup";
import TimeRangePopup from "./popups/TimeRangePopup";
import { v4 as uuidv4 } from "uuid";
import { getAuth } from "@firebase/auth";
import Modal from "../../global/Modal";
import { toast } from "react-toastify";

const StatsOptions = ({
  setCreatedBookings,
  setDeliveredBookings,
  isFetching,
  setIsFetching,
  setTimeRange,
}) => {
  const [userPermissions, setUserPermissions] = useState(null);
  useEffect(() => {
    getUserPermissions((res) => {
      setUserPermissions(res);
    });
  }, []);

  const [autocompleteKey, setAutocompleteKey] = useState(uuidv4());

  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);

  const [organizations, setOrganizations] = useState([]);
  const [org, setOrg] = useState(null);

  useEffect(() => {
    setAutocompleteKey(uuidv4());
  }, [organizations, clients]);

  useEffect(() => {
    const db = getFirestore();
    if (userPermissions !== "superadmin") {
      const auth = getAuth();
      getDoc(doc(db, "users", auth.currentUser.uid))
        .then((res) => {
          if (!res.exists) {
            removeFromURL("statistics");
            toast.error("Någonting gick fel. Vänligen försök igen senare.");
          }
          setOrg(res.data().organization);
        })
        .catch((err) => {
          removeFromURL("statistics");
          toast.error("Någonting gick fel. Vänligen försök igen senare.");
        });
      return;
    }
    const listener = onSnapshot(collection(db, "organizations"), (snapshot) => {
      var tempOrganizations = [
        {
          name: "Alla",
          id: "",
        },
      ];
      snapshot.forEach((org) => {
        tempOrganizations.push(org.data());
      });
      setOrganizations(tempOrganizations);
      setOrg(tempOrganizations[0].id);
    });

    const uid = getAuth().currentUser.uid;
    getDoc(doc(db, "users", uid)).then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        setOrg(data.organization);
      }
    });

    return () => {
      listener();
    };
  }, [userPermissions]);

  useEffect(() => {
    if (!org && userPermissions !== "superadmin") return;
    const db = getFirestore();
    var q = query(collection(db, "users"), orderBy("name.first"));
    if (org) {
      q = query(q, where("organization", "==", org));
    }
    const listener = onSnapshot(q, (snapshot) => {
      var tempClients = [
        {
          fullName: "Alla",
          uid: "",
        },
      ];
      snapshot.forEach((cli) => {
        let data = cli.data();
        data.fullName = getFullname(data);
        tempClients.push(data);
      });
      setClients(tempClients);
      setClient(tempClients[0].uid);
    });

    return () => {
      listener();
    };
  }, [org]);

  const [showTimeRangePopup, setShowTimeRangePopup] = useState(false);
  const [quickOptions, setQuickOptions] = useState(
    getQuickOptions(setShowTimeRangePopup)
  );
  const [timeInterval, setTimeInterval] = useState(quickOptions[0].value);
  const [timeIntervalStart, setTimeIntervalStart] = useState(null);
  const [timeIntervalEnd, setTimeIntervalEnd] = useState(null);

  useEffect(() => {
    if (timeInterval === "OWN_DATERANGE") {
      setTimeIntervalStart(Timestamp.fromDate(new Date()));
      setTimeIntervalEnd(Timestamp.fromDate(new Date()));
    } else {
      const intervalComponents = timeInterval.split(":");
      setTimeIntervalStart(
        Timestamp.fromMillis(Number(intervalComponents[0]) * 1000)
      );
      setTimeIntervalEnd(
        Timestamp.fromMillis(Number(intervalComponents[1]) * 1000)
      );
    }
  }, [timeInterval]);

  useEffect(() => {
    if (
      timeIntervalStart == null ||
      timeIntervalEnd == null ||
      userPermissions == null
    ) {
      return;
    }
    if (userPermissions !== "superadmin" && !org) {
      return;
    }
    setIsFetching(true);
    setTimeRange({
      start: timeIntervalStart.toDate(),
      end: timeIntervalEnd.toDate(),
    });

    if (timeInterval === "OWN_DATERANGE") {
      const tempQOptions = [...quickOptions];
      tempQOptions.forEach((e, index) => {
        if (e.value === "OWN_DATERANGE") {
          tempQOptions[index].label = `${getHumanDate(
            timeIntervalStart
          )} - ${getHumanDate(timeIntervalEnd)}`;
        }
      });
      setQuickOptions(tempQOptions);
    } else {
      const tempQOptions = [...quickOptions];
      tempQOptions.forEach((e, index) => {
        if (e.value === "OWN_DATERANGE") {
          tempQOptions[index].label = "Egen";
        }
      });
      setQuickOptions(tempQOptions);
    }

    var completedOperations = 0;
    const attemptResolve = (toComplete) => {
      if (toComplete === completedOperations) {
        setIsFetching(false);
      }
    };
    constructQueries(
      timeIntervalStart,
      timeIntervalEnd,
      org,
      client,
      setCreatedBookings,
      setDeliveredBookings
    ).forEach((q, _, array) => {
      getDocs(q.q)
        .then((snapshot) => {
          const tempBookings = [];
          snapshot.forEach((d) => {
            tempBookings.push(d.data());
          });
          completedOperations++;
          attemptResolve(array.length);
          q.state(tempBookings);
        })
        .catch((err) => {
          console.error(err);
          completedOperations++;
          attemptResolve(array.length);
        });
    });
  }, [timeIntervalStart, timeIntervalEnd, org, client, userPermissions]);

  return (
    <>
      <SelectionPillGroup
        options={quickOptions}
        state={timeInterval}
        setState={setTimeInterval}
        disabled={isFetching}
      />
      <form className="statsOrgClientPicker">
        {userPermissions === "superadmin" ? (
          <>
            <div>
              <label htmlFor="name">Organisation</label>
              <AutocompleteInput
                id={"organization"}
                placeholder={"Välj organisation"}
                options={organizations}
                displaydict={"name"}
                resdict={"id"}
                state={org}
                setState={setOrg}
                key={autocompleteKey}
              />
            </div>
          </>
        ) : null}
        {userPermissions === "assistant" ||
        userPermissions === "admin" ||
        userPermissions === "superadmin" ? (
          <>
            <div>
              <label htmlFor="client">Bokare</label>
              <AutocompleteInput
                id={"client"}
                placeholder={"Välj kund"}
                options={clients}
                displaydict={"fullName"}
                resdict={"uid"}
                state={client}
                setState={setClient}
                key={autocompleteKey}
              />
            </div>
          </>
        ) : null}
      </form>
      {showTimeRangePopup ? (
        <Modal
          isOpen={showTimeRangePopup}
          setIsOpen={setShowTimeRangePopup}
          backNormal="Stäng"
          backTarget="Statistik"
          size="medium"
        >
          <TimeRangePopup
            start={timeIntervalStart}
            setStart={setTimeIntervalStart}
            end={timeIntervalEnd}
            setEnd={setTimeIntervalEnd}
            setShowingSelf={setShowTimeRangePopup}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default StatsOptions;

const getQuickOptions = (setShowTimeRangePopup) => {
  // quick option object should be formatted like:
  // {
  //     label: String,
  //     value: `${Timestamp (seconds) (start)}:${Timestamp (seconds) (end)}`
  // }

  const QOptions = [];

  // Add today to quick options
  const today = new Date();
  const todayStart = new Date(today);
  todayStart.setHours(0, 0, 0, 0);
  const todayEnd = new Date(today);
  todayEnd.setHours(23, 59, 59, 0);

  QOptions.push({
    label: "Idag",
    value: `${Timestamp.fromDate(todayStart).seconds}:${
      Timestamp.fromDate(todayEnd).seconds
    }`,
  });

  // Add yesterday to quick options
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const yesterdayStart = new Date(yesterday);
  yesterdayStart.setHours(0, 0, 0, 0);
  const yesterdayEnd = new Date(yesterday);
  yesterdayEnd.setHours(23, 59, 59, 0);

  QOptions.push({
    label: "Igår",
    value: `${Timestamp.fromDate(yesterdayStart).seconds}:${
      Timestamp.fromDate(yesterdayEnd).seconds
    }`,
  });

  // Add last week to quick options
  const lastWeekToday = new Date();
  lastWeekToday.setDate(lastWeekToday.getDate() - 7);
  const lastWeekStart = new Date(lastWeekToday);
  lastWeekStart.setDate(lastWeekStart.getDate() + 1 - lastWeekStart.getDay());
  lastWeekStart.setHours(0, 0, 0, 0);
  const lastWeekEnd = new Date(lastWeekToday);
  lastWeekEnd.setDate(lastWeekEnd.getDate() + (7 - lastWeekEnd.getDay()));
  lastWeekEnd.setHours(23, 59, 59, 0);

  QOptions.push({
    label: "Förra veckan",
    value: `${Timestamp.fromDate(lastWeekStart).seconds}:${
      Timestamp.fromDate(lastWeekEnd).seconds
    }`,
  });

  // Add last month to quick options
  const lastDayOfLastMonth = new Date();
  lastDayOfLastMonth.setDate(0);
  lastDayOfLastMonth.setHours(23, 59, 59, 0);
  const firstDayOfLastMonth = new Date(lastDayOfLastMonth);
  firstDayOfLastMonth.setDate(1);
  firstDayOfLastMonth.setHours(0, 0, 0, 0);

  QOptions.push({
    label: "Förra månaden",
    value: `${Timestamp.fromDate(firstDayOfLastMonth).seconds}:${
      Timestamp.fromDate(lastDayOfLastMonth).seconds
    }`,
  });

  // Add past 4 yearly quarters to quick options
  const month = new Date().getMonth();
  const year = new Date().getFullYear();

  var prevQs = [];
  switch (true) {
    case month < 3:
      prevQs = [0, -1, -2, -3];
      break;
    case month < 6:
      prevQs = [1, 0, -1, -2];
      break;
    case month < 9:
      prevQs = [2, 1, 0, -1];
      break;
    case month < 12:
      prevQs = [3, 2, 1, 0];
      break;
    default:
      prevQs = [3, 2, 1, 0];
      break;
  }

  prevQs.forEach((e) => {
    let y = year;
    if (e < 1) {
      e += 4;
      y = year - 1;
    }
    let mStart;
    let mEnd;
    switch (e) {
      case 1:
        mStart = "12";
        mEnd = "03";
        break;
      case 2:
        mStart = "03";
        mEnd = "06";
        break;
      case 3:
        mStart = "06";
        mEnd = "09";
        break;
      case 4:
        mStart = "09";
        mEnd = "12";
        break;
      default:
        mStart = "09";
        mEnd = "12";
        break;
    }
    const start = Timestamp.fromDate(
      new Date(year, mStart, 1, 0, 0, 0, 0)
    ).seconds;
    const end = Timestamp.fromDate(
      new Date(year, mEnd, 0, 23, 59, 59, 0)
    ).seconds;
    QOptions.push({
      label: `Q${e} ${y}`,
      value: `${start}:${end}`,
    });
  });

  // Add own date range to options
  QOptions.push({
    label: "Egen",
    value: "OWN_DATERANGE",
    onClick: () => {
      setShowTimeRangePopup(true);
    },
  });

  return QOptions;
};

const constructQueries = (
  start,
  end,
  org,
  client,
  setCreatedBookings,
  setDeliveredBookings
) => {
  const db = getFirestore();
  var deliveredQuery = query(
    collectionGroup(db, "bookings"),
    where("timedelivered", ">=", start),
    where("timedelivered", "<=", end)
  );
  var createdQuery = query(
    collectionGroup(db, "bookings"),
    where("timecreated", ">=", start),
    where("timecreated", "<=", end)
  );
  if (org) {
    deliveredQuery = query(deliveredQuery, where("organizationID", "==", org));
    createdQuery = query(createdQuery, where("organizationID", "==", org));
  }
  if (client) {
    deliveredQuery = query(deliveredQuery, where("uid", "==", client));
    createdQuery = query(createdQuery, where("uid", "==", client));
  }
  return [
    {
      q: deliveredQuery,
      state: setDeliveredBookings,
    },
    {
      q: createdQuery,
      state: setCreatedBookings,
    },
  ];
};
