import React from "react";
import { animated, useSpring } from "react-spring";

const Loadingboard = ({ title, margin }) => {
  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <animated.div style={springprops}>
      <section
        className={`dashboard board${margin ? " nomargin" : ""}`}
        style={{
          margin: margin ? margin : null,
        }}
      >
        <div className="sk-circle">
          <div className="sk-circle1 sk-child"></div>
          <div className="sk-circle2 sk-child"></div>
          <div className="sk-circle3 sk-child"></div>
          <div className="sk-circle4 sk-child"></div>
          <div className="sk-circle5 sk-child"></div>
          <div className="sk-circle6 sk-child"></div>
          <div className="sk-circle7 sk-child"></div>
          <div className="sk-circle8 sk-child"></div>
          <div className="sk-circle9 sk-child"></div>
          <div className="sk-circle10 sk-child"></div>
          <div className="sk-circle11 sk-child"></div>
          <div className="sk-circle12 sk-child"></div>
        </div>
        <h1 className="title">{title}</h1>
      </section>
    </animated.div>
  );
};

export default Loadingboard;
