import React from "react";
import {v4 as uuid} from 'uuid'

const SelectionPillGroup = ({ options, state, setState, disabled }) => {
  return (
    <div className={`pillgrid${disabled ? " disabled" : ""}`}>
      {options.map((option) => {
        return (
          <p
            className={`pilloption ${state === option.value ? "selected" : ""}`}
            onClick={() => {
              setState(option.value);
              if (option.onClick) {
                option.onClick();
              }
            }}
            key={uuid()}
          >
            {option.label}
          </p>
        );
      })}
    </div>
  );
};

export default SelectionPillGroup;

// option should be structured like:
// {
//     "label" : "XXX",
//     "value" : "YYY"
// }
