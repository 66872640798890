import idx from "idx";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import OrganizationWrapper from "./wrappers/OrganizationWrapper";

const OrganizationList = ({ organizations }) => {
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [organizationFilter, setOrganizationFilter] = useState("");

  useEffect(() => {
    if (organizationFilter === "") {
      setFilteredOrganizations(organizations);
      return;
    }
    const result = organizations.filter((e) =>
      e.name.toUpperCase().includes(organizationFilter.toUpperCase())
    );
    setFilteredOrganizations(result);
  }, [organizations, organizationFilter]);

  return (
    <div className="organizationlistwrapper">
      <form>
        <input
          type="text"
          value={organizationFilter}
          onChange={(e) => setOrganizationFilter(e.target.value)}
          placeholder="Sök..."
        />
      </form>
      <div className="organizationlist">
        {filteredOrganizations.map((org) => {
          return (
            <OrganizationWrapper
              key={idx(org, (_) => _.id) || uuidv4()}
              organization={org}
            />
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(OrganizationList);
