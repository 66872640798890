import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const AutocompleteInput = ({
  id,
  placeholder,
  type,
  options,
  displaydict,
  resdict,
  state,
  setState,
  defaultValue
}) => {
  const [filter, setFilter] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [showingDropdown, setShowingDropdown] = useState(false);

  useEffect(() => {
    setFocusedIndex(-1);
    if (filter === "") {
      setFilteredOptions(options);
      return;
    }
    var filtered;
    if (displaydict) {
      filtered = options.filter((e) =>
        e[displaydict].toUpperCase().includes(filter.toUpperCase())
      );
    } else {
      filtered = options.filter((e) =>
        e.toUpperCase().includes(filter.toUpperCase())
      );
    }
    setFilteredOptions(filtered);
  }, [filter]);

  const inputRef = useRef(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);

  useEffect(() => {
    if (!showingDropdown) return;

    const selectOption = () => {
      if (focusedIndex < 0 || focusedIndex > filteredOptions.length - 1) return;
      let option = filteredOptions[focusedIndex];

      setState(resdict ? option[resdict] : option);
      // inputRef.current.value = displaydict ? option[displaydict] : option;
      inputRef.current.blur();
    };

    const onKeyDown = (e) => {
      switch (e.keyCode) {
        case 13:
          selectOption();
          break;
        case 38:
          // up key
          setFocusedIndex((prev) => {
            if (prev <= 0) {
              return prev;
            }
            return prev - 1;
          });
          break;
        case 40:
          // down key
          setFocusedIndex((prev) => {
            if (prev >= filteredOptions.length - 1) {
              return prev;
            }
            return prev + 1;
          });
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [showingDropdown, filteredOptions, focusedIndex]);

  useEffect(() => {
    var filtered = options.filter((e) => {
      let curr = resdict ? e[resdict] : e;
      return curr === state;
    });
    if (filtered.length > 0) {
      let option = filtered[0];
      inputRef.current.value = displaydict ? option[displaydict] : option;
    }
  }, [state]);

  useEffect(() => {
    if (defaultValue != null) {
      inputRef.current.value = defaultValue
    }
  }, [defaultValue])

  return (
    <div className="autocompleteinput">
      <input
        type={type || "text"}
        id={id || ""}
        placeholder="Börja skriva för att söka"
        onChange={(e) => setFilter(e.target.value)}
        onFocus={() => setShowingDropdown(true)}
        onBlur={() => setShowingDropdown(false)}
        ref={inputRef}
        autoComplete="nope"
      />
      {showingDropdown ? (
        <div
          className={`optiondropdown ${
            filteredOptions.length < 1 ? "empty" : ""
          }`}
        >
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => {
              return (
                <div
                  className={`option ${
                    focusedIndex === index ? "focused" : ""
                  }`}
                  key={uuidv4()}
                  onMouseDown={() => {
                    setState(resdict ? option[resdict] : option);
                  }}
                >
                  <p>{displaydict ? option[displaydict] : option}</p>
                </div>
              );
            })
          ) : (
            <p className="emptydisclaimer">Hittar inga resultat</p>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default AutocompleteInput;
