import idx from "idx";
import React, { useEffect, useState } from "react";
import {
  getDiceBearAvatar,
  getFullname,
  getOrganization,
  getRelativeDateTime,
  getURLComponents,
  getURLParams,
  getUser,
} from "../../../../functions";

import LikeOutlined from "../../../../assets/like_outlined.svg";
import LikeFilled from "../../../../assets/like_filled.svg";
import { getAuth } from "@firebase/auth";
import { toast } from "react-toastify";
import {
  arrayRemove,
  arrayUnion,
  doc,
  getFirestore,
  updateDoc,
} from "@firebase/firestore";

const MessageWrapper = ({ message, booking }) => {
  const [user, setUser] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [liked, setLiked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!user) {
      getUser(message.sender).then((res) => {
        setUser(res);
      });
    }
  }, []);

  useEffect(() => {
    if (user == null) return;
    getOrganization(user.organization, (callback) => {
      setOrganization(callback);
    });
    const likes = idx(message, (_) => _.likes);
    if (likes == null) return;
    if (likes.length === 0) return;
    const auth = getAuth();
    const uid = idx(auth, (_) => _.currentUser.uid);
    if (uid != null) {
      if (likes.some((e) => e === uid)) {
        setLiked(true);
      }
    }
  }, [user]);

  const toggleLiked = () => {
    const auth = getAuth();
    const uid = idx(auth, (_) => _.currentUser.uid);
    if (booking == null || uid == null) {
      toast.error("Någonting gick fel. Vänligen försök igen senare.");
    }
    const db = getFirestore();
    const ref = doc(
      db,
      "users",
      booking.uid,
      "bookings",
      booking.id,
      "discussion",
      message.id
    );
    if (liked) {
      updateDoc(ref, {
        likes: arrayRemove(uid),
      }).catch((err) => {
        toast.error("Någonting gick fel. Vänligen försök igen senare.");
        console.error(err);
      });
    } else {
      updateDoc(ref, {
        likes: arrayUnion(uid),
      }).catch((err) => {
        toast.error("Någonting gick fel. Vänligen försök igen senare.");
        console.error(err);
      });
    }
  };

  useEffect(() => {
    let components = getURLComponents();
    components.forEach((comp) => {
      if (comp.includes("?") && comp.includes("messageid")) {
        comp = comp.split("?")[1];
        const params = getURLParams(comp);
        if (params.messageid === message.id) {
          setIsFocused(true);
        }
      }
    });
  }, []);

  return (
    <div className={`messagewrapper${isFocused ? " focused" : ""}`}>
      <div className="usermeta">
        <img
          src={idx(user, _ => _.photoURL.w64) || getDiceBearAvatar(message.sender)}
          className="avatar db_avatar"
          alt=""
        />
        <div className="text">
          <p>
            <strong>{getFullname(user)}</strong>
          </p>
          <p>
            <small className="faded">
              {idx(organization, (_) => _.name) || "_"}
            </small>
          </p>
        </div>
      </div>
      <p className="message">{message.message}</p>
      <small className="faded">{getRelativeDateTime(message.timeSent)}</small>
      <div className="actionsheet">
        <div className="action" onClick={toggleLiked}>
          <img
            src={liked ? LikeFilled : LikeOutlined}
            className="doinvert"
            alt=""
          />
          <p>{liked ? "Gillar" : "Gilla"}</p>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MessageWrapper);
