import idx from "idx";
import React from "react";
import { animated, useSpring } from "react-spring";
import {
  formatPhoneNumber,
  getFullname,
  removeFromURL,
} from "../../../functions";

// components:
import Backbutton from "../../global/Backbutton";

// assets:
import EditUser from "../../../assets/edituser.svg";
import { useState } from "react";
import { useEffect } from "react";
import { doc, getDoc, getFirestore } from "@firebase/firestore";

const UserInfo = ({ organization, user, setEditing }) => {
  const email = idx(user, (_) => _.email) || "Ingen e-postadress";
  const phone = idx(user, (_) => _.phone);
  const formattedPhone = () => {
    if (phone == null || phone === "") return;
    return formatPhoneNumber(phone);
  };

  const [role, setRole] = useState(null);

  useEffect(() => {
    try {
      const db = getFirestore();
      getDoc(doc(db, "organizations", organization.id)).then((res) => {
        try {
          const data = res.data();
          data.members.forEach((member) => {
            if (member.uid === user.uid) {
              switch (member.role) {
                case "superadmin":
                  setRole("Superadmin");
                  break;
                case "admin":
                  setRole("Administratör");
                  break;
                case "assistant":
                  setRole("Assistent");
                  break;
                case "client":
                  setRole("Mäklare");
                  break;
                default:
                  setRole(member.role);
                  break;
              }
            }
          });
        } catch (err) {
          console.log({ err });
        }
      });
    } catch (err) {
      console.log({ err });
    }
  }, [organization, setEditing, user]);

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });
  return (
    <animated.div style={springprops}>
      <Backbutton
        normal={"Stäng"}
        target={"Hantera organisation"}
        onClick={() => removeFromURL("handleuser")}
      />
      <h2
        style={{
          marginBottom: "1.25rem",
        }}
      >
        {getFullname(user)}
      </h2>
      <p>
        {idx(user, (_) => _.jobTitle) || "Ingen jobbtitel"} på{" "}
        {idx(organization, (_) => _.name)}
      </p>
      <p>
        <small className="mono faded">
          E-post:{" "}
          {email === "Ingen e-postadress" ? (
            email
          ) : (
            <a className="mono" href={`mailto:${email}`}>
              {email}
            </a>
          )}{" "}
          <br />
          Telefonnummer:{" "}
          {idx(formattedPhone(), (_) => _.URI) ? (
            <a className="mono" href={idx(formattedPhone(), (_) => _.URI)}>
              {idx(formattedPhone(), (_) => _.formatted)}
            </a>
          ) : idx(formattedPhone(), (_) => _.formatted) ? (
            idx(formattedPhone(), (_) => _.formatted)
          ) : (
            "Inget telefonnummer"
          )}{" "}
          <br />
          Användartyp: {role || "Ingen användartyp"} <br />
        </small>
      </p>
      <div className="buttonrow">
        <button onClick={() => setEditing(true)}>
          <img src={EditUser} className="doinvert" alt="" />
          Redigera användare
        </button>
      </div>
    </animated.div>
  );
};

export default React.memo(UserInfo);
