import React from "react";
import { animated, useSpring } from "react-spring";
import Backbutton from "../global/Backbutton";
import NotificationWrapper from "./wrappers/NotificationWrapper";
import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";
import {
  collection,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
} from "@firebase/firestore";
import { getAuth } from "@firebase/auth";
import idx from "idx";
import { removeFromURL } from "../../functions";

const NotificationPopup = ({ showingSelf }) => {
  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    const db = getFirestore();
    const uid = idx(getAuth(), (_) => _.currentUser.uid);
    if (!uid) return;
    const q = query(
      collection(db, "users", uid, "notifications"),
      orderBy("timecreated", "desc")
    );
    const listener = onSnapshot(
      q,
      (snapshot) => {
        const tempNotifications = [];
        snapshot.forEach((it) => {
          const data = it.data()
          const notification = {
            ...data,
            ref: it.ref
          }
          tempNotifications.push(notification);
        });
        setNotifications(tempNotifications);
      },
      (err) => {
        console.error(err);
      }
    );

    return () => {
      listener();
    };
  }, []);

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });
  return (
    <div className="modal blur">
      <animated.div style={springprops}>
        <div className="card small">
          <div>
            <Backbutton
              normal={"Stäng"}
              target={"Dashboard"}
              onClick={() => {
                showingSelf(false);
                removeFromURL("notifications")
              }}
            />
          </div>
          {notifications ? (
            <div className="notificationlist">
              {notifications.map((notification) => {
                return (
                  <NotificationWrapper
                    notification={notification}
                    key={uuidv4()}
                  />
                );
              })}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </animated.div>
    </div>
  );
};

export default React.memo(NotificationPopup);
