import { useEffect, useState } from "react";
import reactDom from "react-dom";
import "./App.css";
import "./styles/style.css";
import {
  addToURL,
  containsURL,
  getFirebaseConfig,
  getURLComponents,
  removeFromURL,
} from "./functions";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToolTip from "./components/global/ToolTip";

// components:
import SignIn from "./components/auth/SignIn";
import Dashboard from "./components/dashboard/Dashboard";
import Loadingboard from "./components/global/Loadingboard";

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  doc,
  onSnapshot,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { getMessaging, isSupported, getToken, onMessage } from "firebase/messaging";
import idx from "idx";

initializeApp(getFirebaseConfig());
const auth = getAuth();
const db = getFirestore();

let messaging;
if (isSupported) {
  messaging = getMessaging();
}

function App() {
  const [user, setUser] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (firUser) => {
      setUser(firUser);
      initMessaging();
    });
    registerLocale("sv", sv);
    setDefaultLocale("sv");
  }, []);

  const [usermeta, setUsermeta] = useState(null);

  useEffect(() => {
    if (!user) {
      setUsermeta(null);
      return;
    }
    const listener = onSnapshot(doc(db, "users", user.uid), (doc) => {
      setUsermeta(doc.data());
    });

    return () => {
      listener();
    };
  }, [user]);

  useEffect(() => {
    if (usermeta) {
      addToURL("dashboard");
    } else {
      removeFromURL("dashboard");
    }
  }, [usermeta]);

  const [key, setKey] = useState("init");

  useEffect(() => {
    const handleURLUpdate = () => {
      setKey(window.location.href);

      const forceDashboard = () => {
        addToURL("dashboard");
      };
      let components = getURLComponents();
      if (components.length === 0) {
        forceDashboard();
      }
      if (components.length === 1) {
        if (components[0] === "") {
          forceDashboard();
        }
      }
    };
    handleURLUpdate();
    window.addEventListener("popstate", handleURLUpdate);
    return () => {
      window.removeEventListener("popstate", handleURLUpdate);
    };
  }, []);

  const [colorMode, setColorMode] = useState(null);

  useEffect(() => {
    const setDark = (e) => {
      e.matches && setColorMode("dark");
    };
    const setLight = (e) => {
      e.matches && setColorMode("light");
    };

    try {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", setDark);
      window
        .matchMedia("(prefers-color-scheme: light)")
        .addEventListener("change", setLight);
    } catch {
      console.error("Cannot listen for color scheme changes");
    }

    return () => {
      try {
        window
          .matchMedia("(prefers-color-scheme: dark)")
          .removeEventListener("change", setDark);
        window
          .matchMedia("(prefers-color-scheme: light)")
          .removeEventListener("change", setLight);
      } catch {
        console.error("Cannot listen for color scheme changes");
      }
    };
  }, []);

  const getContent = () => {
    if (user == null) {
      return <SignIn auth={auth} />;
    }
    if (containsURL("dashboard")) {
      return (
        <Dashboard auth={auth} user={usermeta} db={db} messaging={messaging} />
      );
    }
    return <Loadingboard title={"Startar..."} />;
  };

  return (
    <>
      <main className="App" key={key}>
        {getContent()}
      </main>
      {reactDom.createPortal(
        <ToastContainer className="toast" theme={colorMode} />,
        document.getElementById("toastportal")
      )}
      <ToolTip />
    </>
  );
}

export default App;

const initMessaging = () => {
  if (!messaging) {
    return;
  }

  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    try {
      var notification = new Notification(payload.notification.title, {
        icon: "https://resources.everydayphotos.se/logo512.png",
        body: payload.notification.body
      })
      notification.onclick = () => {
        window.open("https://portal.everydayphotos.se/#/dashboard/notifications", "_self")
      }
    } catch (err) {
      console.error({err})
    }
  });

  const db = getFirestore();
  const uid = idx(getAuth(), (_) => _.currentUser.uid);

  if (!uid) {
    return;
  }

  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  // messaging = getMessaging();
  getToken(messaging, {
    vapidKey:
      "BLoGax4CrIbC0MqHrnYU2ZV433OOgaWMkUmag2Lph3RsdIFM_YcsHVYc4xiwKvwhtOoN5J71YQm-dOWr9N19y4Y",
  })
    .then((currentToken) => {
      console.log({ currentToken });
      if (currentToken) {
        updateDoc(doc(db, "users", uid), {
          fcmtokens: arrayUnion(currentToken),
        })
          .then(() => {})
          .catch((err) => {
            console.error({ err });
          });
        // ...
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    })
    .catch((err) => {
      console.log(
        "An error occurred while retrieving token. ",
        err,
        "code: ",
        err.code
      );
      switch (err.code) {
        case "messaging/permission-blocked":
          break;
        default:
          break;
      }
      //messaging/permission-blocked should lead to asking for allow again
      // ...
    });
};
