import { animated, useSpring } from "react-spring";
import React from "react";

// assets:
import EmptyBox from "../../assets/emptybox.svg";

const EmptyScreen = ({ title, subtitle, message }) => {
  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <animated.div style={springprops}>
      <div className="emptyscreen">
        <img src={EmptyBox} className="doinvert" alt="" />
        {title ? <h3>{title}</h3> : null}
        {subtitle ? <h5>{subtitle}</h5> : null}
        {message ? <p>{message}</p> : null}
      </div>
    </animated.div>
  );
};

export default EmptyScreen;
