import React from "react";
import { animated, useSpring } from "react-spring";

// assets:
import NoData from "../../../assets/nodata.svg";

const NoDataView = () => {
  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <animated.div style={springprops}>
      <div className="noDataWrapper">
        <img src={NoData} className="doinvert" alt="" />
        <h3>Ingen data</h3>
        <p>
          Det finns ingen data för den valda tidsperioden. Testa att ändra
          tidsspannet.
        </p>
      </div>
    </animated.div>
  );
};

export default NoDataView;
