import {
  collectionGroup,
  getFirestore,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import idx from "idx";
import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import {
  containsURL,
  getURLComponents,
  getURLParams,
  removeFromURL,
} from "../../../functions";

// components:
import BookingDetails from "./BookingDetails";
import DiscussionModal from "./DiscussionModal";
import EditBookingForm from "./EditBookingForm";

const HandleBooking = ({ providedBooking, setProvidedBooking }) => {
  const [bookingID, setBookingID] = useState(null);
  const [booking, setBooking] = useState(providedBooking);
  const [editing, setEditing] = useState(false);
  const [showingDiscussion, setShowingDiscussion] = useState(null);

  useEffect(() => {
    setBooking(providedBooking);
  }, [providedBooking]);

  useEffect(() => {
    if (booking != null) return;
    let components = getURLComponents();
    components.forEach((comp) => {
      if (comp.includes("?") && comp.includes("bookingid")) {
        comp = comp.split("?")[1];
        const params = getURLParams(comp);
        setBookingID(idx(params, (_) => _.bookingid));
      }
    });
  }, []);

  useEffect(() => {
    if (!booking) return;
    if (containsURL("messageid")) {
      setShowingDiscussion(true)
    }
  }, [booking])

  useEffect(() => {
    if (bookingID == null || bookingID === "") return;

    const db = getFirestore();

    onSnapshot(
      query(collectionGroup(db, "bookings"), where("id", "==", bookingID)),
      (snapshot) => {
        var doc;
        snapshot.forEach((it) => {
          doc = it.data();
        });
        setBooking(doc);
      }
    );
  }, [bookingID]);

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <div className="modal blur">
      <animated.div style={springprops} className="prelative">
        <div className="card large">
          {editing ? (
            <EditBookingForm
              booking={booking}
              backaction={() => {
                setEditing(false);
              }}
            />
          ) : (
            <BookingDetails
              booking={booking}
              backaction={() => {
                if (providedBooking != null) {
                  setProvidedBooking(null);
                  return;
                }
                removeFromURL("bookingdetails");
              }}
              setEditing={setEditing}
              setShowingDiscussion={setShowingDiscussion}
            />
          )}
        </div>
        {showingDiscussion ? (
          <DiscussionModal setShowingDiscussion={setShowingDiscussion} booking={booking} />
        ) : null}
      </animated.div>
    </div>
  );
};

export default React.memo(HandleBooking);
