import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";

// components:
import Backbutton from "../../global/Backbutton";
import RadioGroup from "../../global/RadioGroup";
import SelectionPillGroup from "../../global/SelectionPillGroup";
import idx from "idx";
import CheckboxGroup from "../../global/CheckboxGroup";

// assets:
import CheckMark from "../../../assets/checkmark.svg";
import Clear from "../../../assets/xmark.svg";
import { getUserPermissions } from "../../../functions";

const SortingOptionsClient = ({
  config,
  setConfig,
  configDesc,
  setConfigDesc,
  setShowingSelf,
  user,
  organization,
}) => {
  const taxonomyTypeOptions = [
    {
      label: "Dig själv",
      value: "SELF",
    },
    {
      label: "Din organisation",
      value: "ORGANIZATION",
    },
  ];
  const [taxonomyType, setTaxonomyType] = useState(
    taxonomyTypeOptions[0].value
  );
  const [userPermissions, setUserPermissions] = useState(null);
  useEffect(() => {
    getUserPermissions((callback) => {
      setUserPermissions(callback);
    });
  }, []);
  useEffect(() => {
    try {
      if (Object.keys(configDesc).length > 0) return;
    } catch (err) {
      console.error({ err });
    }
    if (userPermissions === "assistant" || userPermissions === "admin") {
      setTaxonomyType(taxonomyTypeOptions[1].value);
    }
  }, [userPermissions]);

  const sortByOptions = [
    {
      label: "Nyaste",
      value: "NEWEST",
    },
    {
      label: "Äldsta",
      value: "OLDEST",
    },
    {
      label: "Deadline",
      value: "DEADLINE",
    },
    {
      label: "Bokningsdatum",
      value: "BOOKING",
    },
  ];
  const [sortBy, setSortBy] = useState(sortByOptions[0].value);

  const showStatusesRows = [
    {
      label: 'Obokade',
      value: 'NOT_BOOKED',
    },
    {
      label: 'Bokade',
      value: 'BOOKED',
    },
    {
      label: 'Avbokade',
      value: 'CANCELED',
    },
    {
      label: 'Levererade',
      value: 'DELIVERED',
    },
  ];
  const [showStatuses, setShowStatuses] = useState(() => {
    var tempRes = [
      showStatusesRows[0].value,
      showStatusesRows[1].value,
      showStatusesRows[2].value,
    ];
    return tempRes;
  });

  const returnSorting = () => {
    var wheres = [];
    var wheresDesc = {};
    wheres.push(["status", "in", showStatuses]);
    if (taxonomyType === "ORGANIZATION") {
      wheres.push(["organizationID", "==", user.organization]);
      wheresDesc = {
        key: "ORGANIZATION",
        name: "din organisation",
      };
    }
    if (taxonomyType === "SELF") {
      wheres.push(["uid", "==", user.uid]);
      wheresDesc = {
        key: "SELF",
        name: "dig själv",
      };
    }
    wheresDesc.showStatuses = showStatuses;
    var showStatusesLabels = [];
    showStatuses.forEach((it) => {
      showStatusesRows.forEach((row) => {
        if (row.value === it) {
          showStatusesLabels.push(row.label);
        }
      });
    });
    wheresDesc.showStatusesLabels = showStatusesLabels;
    var order = [];
    var ordersDesc = {};
    switch (sortBy) {
      case "OLDEST":
        order.push(["timecreated", "asc"]);
        ordersDesc = {
          key: sortBy,
          name: "Äldsta",
        };
        break;
      case "DEADLINE":
        order.push(["deadline", "desc"]);
        ordersDesc = {
          key: sortBy,
          name: "Deadline",
        };
        break;
      case "BOOKING":
        order.push(["bookedtime", "desc"]);
        ordersDesc = {
          key: sortBy,
          name: "Bokningsdatum",
        };
        break;
      default:
        // order by newest:
        order.push(["timecreated", "desc"]);
        ordersDesc = {
          key: sortBy,
          name: "Nyaste",
        };
        break;
    }
    setConfig({
      wheres: wheres,
      order: order,
    });
    setConfigDesc({
      wheres: wheresDesc,
      order: ordersDesc,
    });

    setShowingSelf(false);
  };

  useEffect(() => {
    let where = idx(configDesc, (_) => _.wheres);
    if (where) {
      if (where.key === "ORGANIZATION") {
        setTaxonomyType(where.key);
      }
      if (where.key === "SELF") {
        setTaxonomyType(where.key);
      }
      setShowStatuses(where.showStatuses);
    }

    let order = idx(configDesc, (_) => _.order);
    if (order) {
      setSortBy(order.key);
    }
  }, [configDesc]);

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });
  return (
    <div className="modal blur">
      <animated.div style={springprops}>
        <div className="card medium">
          <Backbutton
            normal={"Stäng"}
            target={"Bokningar"}
            onClick={() => setShowingSelf(false)}
          />
          <h2>Filtreringsinställningar</h2>
          <form onSubmit={(e) => e.preventDefault()}>
            <label htmlFor="taxonomytype">Vad vill du filtrera efter?</label>
            <RadioGroup
              rows={taxonomyTypeOptions}
              id={"taxonomytype"}
              state={taxonomyType}
              setState={setTaxonomyType}
            />
            <label>Sortera efter:</label>
            <SelectionPillGroup
              options={sortByOptions}
              state={sortBy}
              setState={setSortBy}
            />
            <label>Visa:</label>
            <CheckboxGroup
              rows={showStatusesRows}
              state={showStatuses}
              setState={setShowStatuses}
              minchecked={1}
            />
            <div className="buttonrow">
              <button onClick={() => returnSorting()} type="button">
                <img src={CheckMark} className="doinvert" alt="" />
                Sätt filtrering
              </button>
              <button
                onClick={() => {
                  setShowingSelf(false);
                  setConfig(null);
                  setConfigDesc({});
                }}
                className="destructive"
                type="button"
              >
                <img src={Clear} className="doinvert" alt="" />
                Rensa filtrering
              </button>
            </div>
          </form>
        </div>
      </animated.div>
    </div>
  );
};

export default React.memo(SortingOptionsClient);
