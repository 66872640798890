import { getAuth } from "@firebase/auth";
import { arrayUnion, collection, doc, getFirestore, serverTimestamp, setDoc, updateDoc } from "@firebase/firestore";
import idx from "idx";
import React, { useCallback } from "react";
import { useState } from "react";
import { toast } from "react-toastify";

import PaperPlane from "../../../../assets/paperplane.svg";

const MessageInputWrapper = ({ booking }) => {
  const [message, setMessage] = useState("");

  const handleMessageChange = useCallback((e) => {
    setMessage(e.target.value);
  }, []);

  const [sendingMessage, setSendingMessage] = useState(false);

  const listenForEnterKey = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      setMessage((p) => {
        return p.substring(0, p.length - 1);
      });
      let msg = message.substring(0, message.length - 1);
      sendMessage(msg);
    }
  };

  const sendMessage = (msg) => {
    if (sendingMessage) return;
    const auth = getAuth();
    const uid = idx(auth, (_) => _.currentUser.uid);
    if (booking == null || uid == null) {
      toast.error("Någonting gick fel. Vänligen försök igen senare.");
    }
    setSendingMessage(true);
    var messagePipe = message;
    setMessage("");
    const db = getFirestore();
    const ref = doc(
      collection(db, "users", booking.uid, "bookings", booking.id, "discussion")
    );
    const id = ref.id;
    setDoc(ref, {
      timeSent: serverTimestamp(),
      message: messagePipe,
      id: id,
      sender: uid,
    })
      .then(() => {
        setSendingMessage(false);
        updateDoc(doc(db, "users", booking.uid, "bookings", booking.id), {
          discussionMembers: arrayUnion(uid),
        });
      })
      .catch((err) => {
        toast.error("Någonting gick fel. Vänligen försök igen senare.");
        console.error(err);
        setSendingMessage(false);
        setMessage(messagePipe);
      });
  };

  return (
    <div
      className={`messageinputwrapper${message.length > 0 ? " sendable" : ""}`}
    >
      <textarea
        placeholder="Meddelande"
        value={message}
        onChange={handleMessageChange}
        onKeyUp={listenForEnterKey}
      ></textarea>
      <button onClick={() => sendMessage(message)}>
        <img src={PaperPlane} className="doinvert" alt="" />
      </button>
    </div>
  );
};

export default MessageInputWrapper;
