import React from "react";
import { v4 as uuidv4 } from "uuid";

// assets:
import UnChecked from "../../assets/radio-unchecked.svg";
import Checked from "../../assets/radio-checked.svg";
import idx from "idx";

const RadioGroup = ({ rows, className, id, state, setState }) => {
  return (
    <div className={className || "checkboxgroup"} id={id}>
      {rows.map((row) => {
        return (
          <div
            className="checkboxrow"
            onClick={() => setState(row.value)}
            key={idx(row, (_) => _.value) || uuidv4()}
          >
            <img
              src={state === row.value ? Checked : UnChecked}
              className="doinvert radio"
              alt=""
            />
            <p>{row.label}</p>
          </div>
        );
      })}
    </div>
  );
};

export default RadioGroup;

// a row is supposed to look like:
// {
//     label: 'XXX',
//     value: 'XXX',
// }
