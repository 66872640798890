import React, { useState, useEffect } from 'react';
import idx from 'idx';
import HyperLink from '../../global/HyperLink';
import { getRelativeDateTime } from '../../../functions';
import { doc, getDoc, getFirestore } from '@firebase/firestore';

const SEEN_NF_KEY = 'SEEN_NF_KEY';

const NewsflashWrapper = ({ data }) => {
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const getOrganizations = async () => {
      const db = getFirestore();
      const tempOrganizations = [];
      const promises = data.organizations.map((e) => {
        return new Promise((resolve, reject) => {
          getDoc(doc(db, 'organizations', e))
            .then((res) => {
              if (!res.exists) resolve();
              const orgName = idx(res, (_) => _.data().name);
              if (!orgName) resolve();
              tempOrganizations.push(orgName);
              resolve();
            })
            .catch((err) => {
              resolve();
            });
        });
      });
      await Promise.all(promises);
      setOrganizations(tempOrganizations);
    };
    getOrganizations();
  }, [data]);

  const isNewsflashSeen = (id) => {
    const seenNewsFlashes = JSON.parse(localStorage.getItem(SEEN_NF_KEY)) || [];

    return seenNewsFlashes.some((e) => e === id);
  };

  const setNewsflashSeen = (id) => {
    const seenNewsFlashes = JSON.parse(localStorage.getItem(SEEN_NF_KEY)) || [];

    seenNewsFlashes.push(id);

    localStorage.setItem(SEEN_NF_KEY, JSON.stringify(seenNewsFlashes));
  };

  useEffect(() => {
    const throttle = setTimeout(() => {
      if (!idx(data, (_) => _.timeCreated.seconds)) return;
      if (!isNewsflashSeen(idx(data, (_) => _.timeCreated.seconds))) {
        setNewsflashSeen(idx(data, (_) => _.timeCreated.seconds));
      }
    }, 1000);

    return () => {
      clearTimeout(throttle);
    };
  }, [data]);

  return (
    <div
      className={`dashboardNewsflash ${
        isNewsflashSeen(idx(data, (_) => _.timeCreated.seconds)) ? '' : 'unseen'
      }`}
    >
      <div className="newsflashWrapper">
        <label>{getRelativeDateTime(idx(data, (_) => _.timeCreated))}</label>
        <div className="titleWrapper">
          <h5>
            {idx(data, (_) => _.preTitle) ? (
              <span className="preTitle">
                <strong>{idx(data, (_) => _.preTitle)}</strong>
              </span>
            ) : null}
            <span>{idx(data, (_) => _.title)}</span>
          </h5>
        </div>
        {idx(data, (_) => _.subTitle) ? (
          <p className="faded subTitle">{idx(data, (_) => _.subTitle)}</p>
        ) : null}
        {idx(data, (_) => _.content) ? (
          <p className="content">{idx(data, (_) => _.content)}</p>
        ) : null}
        {idx(data, (_) => _.link) ? (
          <a href={idx(data, (_) => _.link)} target="_blank" rel="noreferrer">
            <HyperLink target={'Läs mer'} />
          </a>
        ) : null}
      </div>
    </div>
  );
};

export default NewsflashWrapper;
