import React, { useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import domtoimage from "dom-to-image";

// components:
import Backbutton from "../../global/Backbutton";

// assets:
import CheckMark from "../../../assets/checkmark.svg";
import OneByOne from "../../../assets/1x1.png";
import TwoByOne from "../../../assets/2x1.png";
import idx from "idx";
import { toast } from "react-toastify";
import { uploadSingleBlob } from "../../../functions";
import { doc, getFirestore, updateDoc } from "@firebase/firestore";

const AddLogoPopup = ({ setShowingSelf, organization }) => {
  const colorInputRef = useRef(null);

  const [logoColor, setLogoColor] = useState("#ffffff");
  const [logoPadding1x1, setLogoPadding1x1] = useState(0);
  const [image1x1, setImage1x1] = useState(
    idx(organization, (_) => _.avatars.size1x1) || OneByOne
  );
  const image1x1ref = useRef(null);
  const [logoPadding2x1, setLogoPadding2x1] = useState(0);
  const [image2x1, setImage2x1] = useState(
    idx(organization, (_) => _.avatars.size2x1) || TwoByOne
  );
  const image2x1ref = useRef(null);

  const handle1x1ImageInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (idx(e, (_) => _.target.files[0])) {
      var reader = new FileReader();
      reader.onload = function (e) {
        setImage1x1(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handle2x1ImageInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (idx(e, (_) => _.target.files[0])) {
      var reader = new FileReader();
      reader.onload = function (e) {
        setImage2x1(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const submit = () => {
    var toProcess = [];
    if (
      image1x1 !== OneByOne &&
      image1x1 !== idx(organization, (_) => _.avatars.size1x1)
    ) {
      toProcess.push({
        node: image1x1ref.current,
        filename: "1x1",
        dimens: {
          height: 256,
          width: 256,
        },
      });
    }
    if (
      image2x1 !== TwoByOne &&
      image1x1 !== idx(organization, (_) => _.avatars.size2x1)
    ) {
      toProcess.push({
        node: image2x1ref.current,
        filename: "2x1",
        dimens: {
          height: 220,
          width: 440,
        },
      });
    }

    toProcess.forEach((e) => {
      domtoimage
        .toJpeg(e.node, {
          quality: 0.95,
          height: e.dimens.height,
          width: e.dimens.width,
        })
        .then((dataUrl) => {
          const filename = `${e.filename}.jpg`;
          const path = idx(organization, (_) => _.id);
          const promise = uploadSingleBlob(dataUrl, path, filename)
            .then((res) => {
              const db = getFirestore();
              const dbValue = `avatars.size${e.filename}`;
              var updateData = {};
              updateData[dbValue] = res;
              updateDoc(doc(db, "organizations", organization.id), updateData);
            })
            .catch((err) => {
              console.error(err);
            });
          toast.promise(promise, {
            pending: `Laddar upp logotyp ${e.filename}. Vänligen lämna inte sidan.`,
            error: "Någonting gick fel. Vänligen försök igen senare.",
            success: `Uppladdningen av logotyp ${e.filename} lyckades!`,
          });
        });
    });
  };

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <div className="modal blur">
      <animated.div style={springprops}>
        <div className="card medium">
          <Backbutton
            normal={"Stäng"}
            target={"Redigera organisation"}
            onClick={() => {
              setShowingSelf(false);
            }}
          />

          <div className="addphotowrapper">
            <small>
              <strong>Lägg till logotyp</strong>
            </small>
            <form>
              <small className="flex row aligncenter mono cgap">
                Bakgrundsfärg:
                <span
                  className="roundedcolorinput"
                  style={{
                    background: logoColor,
                  }}
                  onClick={() => {
                    colorInputRef.current.click();
                  }}
                >
                  <input
                    type="color"
                    className="colorinput"
                    ref={colorInputRef}
                    onChange={(e) => {
                      setLogoColor(e.target.value);
                    }}
                  />
                </span>
              </small>
              <small className="flex row aligncenter mono cgap">
                Mellanrum 1x1:
                <input
                  type="number"
                  min="0"
                  max="64"
                  value={logoPadding1x1}
                  onChange={(e) => {
                    setLogoPadding1x1(e.target.value);
                  }}
                />
              </small>
              <small className="flex row aligncenter mono cgap">
                Mellanrum 2x1:
                <input
                  type="number"
                  min="0"
                  max="64"
                  value={logoPadding2x1}
                  onChange={(e) => {
                    setLogoPadding2x1(e.target.value);
                  }}
                />
              </small>
            </form>
            <div className="inputwrapper">
              <div
                className="imageinput"
                style={{
                  width: "8rem",
                }}
              >
                <img
                  src={image1x1}
                  alt=""
                  style={{
                    background: logoColor,
                    padding: `${logoPadding1x1}px`,
                  }}
                />
                <input
                  type="file"
                  onChange={handle1x1ImageInput}
                  accept="image/png, image/jpeg, .svg"
                />
              </div>
              <div
                className="imageinput"
                style={{
                  width: "16rem",
                }}
              >
                <img
                  src={image2x1}
                  alt=""
                  style={{
                    background: logoColor,
                    padding: `${logoPadding2x1}px`,
                  }}
                />
                <input
                  type="file"
                  onChange={handle2x1ImageInput}
                  accept="image/png, image/jpeg, .svg"
                />
              </div>
              <div className="resultwrapper">
                <img
                  src={image1x1}
                  alt=""
                  ref={image1x1ref}
                  style={{
                    background: logoColor,
                    padding: `${logoPadding1x1}px`,
                    width: "8rem",
                    height: "8rem",
                  }}
                />
                <img
                  src={image2x1}
                  alt=""
                  ref={image2x1ref}
                  style={{
                    background: logoColor,
                    padding: `${logoPadding2x1}px`,
                    width: "16rem",
                    height: "8rem",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="buttonrow">
            <button onClick={submit}>
              <img src={CheckMark} className="doinvert" alt="" />
              Spara logotyper
            </button>
          </div>
        </div>
      </animated.div>
    </div>
  );
};

export default AddLogoPopup;
