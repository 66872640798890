import idx from "idx";
import React, { useEffect, useState } from "react";
import {
  getDiceBearAvatar,
  getFullname,
  getHumanDate,
  getHumanDateTime,
  getUser,
} from "../../../../functions";
import { animated, useSpring } from "react-spring";

const BookingWrapper = ({ booking, setBooking }) => {
  const [booker, setBooker] = useState(null);

  useEffect(() => {
    getUser(idx(booking, (_) => _.uid)).then((user) => setBooker(user));
  }, []);

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 8, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  if (booking.type === "HEADING") {
    return <h3 className="intuitivetitle">{booking.title}</h3>;
  }

  return (
    <animated.div style={springprops}>
      <div onClick={() => setBooking(booking)} className="bookingWrapper">
        <h4 className="title">
          {idx(booking, (_) => _.address.address)}{" "}
          <span>
            <small className="mono">
              {" "}
              {idx(booking, (_) => _.address.postalcode)},{" "}
              {idx(booking, (_) => _.address.city)}
            </small>
          </span>
        </h4>
        <div className="metawrapper faded">
          <small>
            Bokat av
            <img
              src={
                idx(booker, (_) => _.photoURL.w48) ||
                getDiceBearAvatar(idx(booker, (_) => _.uid))
              }
              className="db_avatar"
              alt=""
            />
            <strong>{getFullname(booker)}</strong> för säljaren{" "}
            <strong>{idx(booking, (_) => _.sellername)}</strong>
          </small>
        </div>
        <div className="metawrapper faded">
          <small>
            {booking.deadline ? (
              <>
                Deadline{" "}
                <strong>
                  {getHumanDate(idx(booking, (_) => _.deadline))}
                  {booking.bookedtime ? " | " : ""}
                </strong>
              </>
            ) : null}
            {booking.bookedtime ? (
              <>
                Fotografering bokad{" "}
                <strong>
                  {getHumanDateTime(idx(booking, (_) => _.bookedtime))}
                </strong>
              </>
            ) : null}
          </small>
        </div>
      </div>
    </animated.div>
  );
};

export default React.memo(BookingWrapper);
