import { React, useState } from "react";
import { animated, useSpring } from "react-spring";
import { removeFromURL } from "../../../functions";

// components:
import Backbutton from "../../global/Backbutton";
import Loadingboard from "../../global/Loadingboard";
import NoDataView from "./NoDataView";
import StatisticsWrapper from "./StatisticsWrapper";
import StatsOptions from "./StatsOptions";

const StatisticsBoard = () => {
  const [createdBookings, setCreatedBookings] = useState([]);
  const [deliveredBookings, setDeliveredBookings] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [timeRange, setTimeRange] = useState(null);

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <animated.div style={springprops}>
      <div className="createbooking board">
        <Backbutton
          target={"Dashboard"}
          onClick={() => removeFromURL("statistics")}
        />
        <h1 className="title">Statistik</h1>
        <p className="subtitle">
          Se statistik för organisationer och mäklare{" "}
          <span className="showAtMobile">
            <br />
            Notera att denna funktion fungerar bäst på större skärmar.
          </span>
        </p>
        <StatsOptions
          isFetching={isFetching}
          setIsFetching={setIsFetching}
          setCreatedBookings={setCreatedBookings}
          setDeliveredBookings={setDeliveredBookings}
          setTimeRange={setTimeRange}
        />
        {isFetching ? (
          <Loadingboard margin="3rem 0" />
        ) : createdBookings.length > 0 || deliveredBookings.length > 0 ? (
          <StatisticsWrapper
            createdBookings={createdBookings}
            deliveredBookings={deliveredBookings}
            timeRange={timeRange}
          />
        ) : (
          <NoDataView />
        )}
      </div>
    </animated.div>
  );
};

export default StatisticsBoard;
