import idx from "idx";
import { React, useState, useEffect } from "react";
import { animated, useSpring } from "react-spring";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import interpolate from "color-interpolate";
import { getFullname, getHumanDate, getUser } from "../../../functions";
import { Timestamp } from "@firebase/firestore";

const StatisticsWrapper = ({
  createdBookings,
  deliveredBookings,
  timeRange,
}) => {
  const [perService, setPerService] = useState([]);
  const [perServiceDoughnutData, setPerServiceDoughnutData] = useState(null);
  const [perRealtor, setPerRealtor] = useState([]);
  const [perRealtorBarData, setPerRealtorBarData] = useState(null);
  const [amountPerDate, setAmountPerDate] = useState([]);
  const [amountPerDateLineData, setAmountPerDateLineData] = useState(null);
  const [amountPerEstateType, setAmountPerEstateType] = useState([]);
  const [amountPerEstateTypeDoughnutData, setAmountPerEstateTypeDoughnutData] =
    useState(null);

  useEffect(() => {
    const tempPerService = [];
    setPerServiceDoughnutData(null);
    createdBookings.forEach((b) => {
      b.services.forEach((s) => {
        const i = tempPerService.map((e) => e.label).indexOf(s);
        if (i < 0) {
          tempPerService.push({
            label: s,
            amount: 1,
          });
        } else {
          tempPerService[i].amount++;
        }
      });
    });
    setPerService(tempPerService);
  }, [createdBookings]);

  useEffect(() => {
    const labels = perService.map((e) => e.label.toLowerCase());
    const amounts = perService.map((e) => e.amount);

    var palette = getColors(labels.length);

    if (labels.length === 0 || amounts.length === 0) {
      setPerServiceDoughnutData(null);
      return;
    }

    setPerServiceDoughnutData({
      labels: labels,
      datasets: [
        {
          label: "# av tjänsten",
          data: amounts,
          backgroundColor: palette,
          borderColor: isDarkMode() ? "rgb(0, 0, 0)" : "rgb(250, 250, 250)",
          borderWidth: 1,
        },
      ],
    });
  }, [perService]);

  useEffect(() => {
    const tempPerEstate = [];
    setAmountPerEstateTypeDoughnutData(null);
    createdBookings.forEach((b) => {
      const i = tempPerEstate.map((e) => e.label).indexOf(b.estatetype);
      if (i < 0) {
        tempPerEstate.push({
          label: b.estatetype,
          amount: 1,
        });
      } else {
        tempPerEstate[i].amount++;
      }
    });
    tempPerEstate.sort((a, b) =>
      a.amount < b.amount ? 1 : b.amount < a.amount ? -1 : 0
    );
    setAmountPerEstateType(tempPerEstate);
  }, [createdBookings]);

  useEffect(() => {
    const labels = amountPerEstateType.map((e) => e.label.toLowerCase());
    const amounts = amountPerEstateType.map((e) => e.amount);

    var palette = getColors(labels.length);
    palette.reverse();

    if (labels.length === 0 || amounts.length === 0) {
      setAmountPerEstateTypeDoughnutData(null);
      return;
    }

    setAmountPerEstateTypeDoughnutData({
      labels: labels,
      datasets: [
        {
          label: "# av fastighetstypen",
          data: amounts,
          backgroundColor: palette,
          borderColor: isDarkMode() ? "rgb(0, 0, 0)" : "rgb(250, 250, 250)",
          borderWidth: 1,
        },
      ],
    });
  }, [amountPerEstateType]);

  useEffect(() => {
    const tempPerRealtor = [];
    if (createdBookings.length === 0) {
      setPerRealtor([]);
    }
    setPerRealtorBarData(null);
    var uids = [];
    createdBookings.forEach((b) => {
      if (!uids.includes(b.uid)) {
        uids.push(b.uid);
      }
    });
    var completedOperations = 0;
    const doFilter = () => {
      if (completedOperations !== uids.length) return;
      const tempPerRealtorWithAmount = [];
      tempPerRealtor.forEach((r, i) => {
        const amount = createdBookings.filter((e) => e.uid === r.uid).length;
        if (amount === 0) return;
        tempPerRealtorWithAmount.push({
          label: r.label,
          amount: amount,
          uid: r.uid,
        });
      });
      setPerRealtor(tempPerRealtorWithAmount);
    };
    uids.forEach((uid) => {
      getUser(uid).then((user) => {
        tempPerRealtor.push({
          label: getFullname(user),
          amount: 0,
          uid: uid,
        });
        completedOperations++;
        doFilter();
      });
    });
  }, [createdBookings]);

  useEffect(() => {
    const perRealtorCopy = [...perRealtor];
    perRealtorCopy.sort((a, b) =>
      a.amount < b.amount ? 1 : b.amount < a.amount ? -1 : 0
    );

    const labels = perRealtorCopy.map((e) => e.label);
    const amounts = perRealtorCopy.map((e) => e.amount);

    var palette = getColors(labels.length);

    if (labels.length === 0 || amounts.length === 0) {
      setPerRealtorBarData(null);
      return;
    }

    setPerRealtorBarData({
      labels: labels,
      datasets: [
        {
          label: "st bokningar",
          data: amounts,
          backgroundColor: palette,
          borderColor: isDarkMode() ? "rgb(0, 0, 0)" : "rgb(250, 250, 250)",
          borderWidth: 1,
        },
      ],
    });
  }, [perRealtor]);

  useEffect(() => {
    setAmountPerDateLineData(null);
    if (!timeRange) return;
    const dateRange = getDateRange(timeRange.start, timeRange.end);
    const tempBookingAmount = [];
    var totBooked = 0;
    var totDelivered = 0;
    dateRange.forEach((d, i, a) => {
      tempBookingAmount.push({
        label: getHumanDate(Timestamp.fromDate(d)),
      });
      const nextDay = new Date(d);
      nextDay.setDate(nextDay.getDate() + 1);
      const dayCreated = createdBookings.filter((e) => {
        return (
          idx(e, (_) => _.timecreated.seconds) >
            Timestamp.fromDate(d).seconds &&
          idx(e, (_) => _.timecreated.seconds) <
            Timestamp.fromDate(nextDay).seconds
        );
      });
      tempBookingAmount[i].amountBooked = dayCreated.length + totBooked;
      totBooked += dayCreated.length;

      const dayDelivered = deliveredBookings.filter((e) => {
        return (
          idx(e, (_) => _.timedelivered.seconds) >
            Timestamp.fromDate(d).seconds &&
          idx(e, (_) => _.timedelivered.seconds) <
            Timestamp.fromDate(nextDay).seconds
        );
      });
      tempBookingAmount[i].amountDelivered = dayDelivered.length + totDelivered;
      totDelivered += dayDelivered.length;
    });
    setAmountPerDate(tempBookingAmount);
  }, [createdBookings, deliveredBookings, timeRange]);

  useEffect(() => {
    const labels = amountPerDate.map((e) => e.label);
    const amountBooked = amountPerDate.map((e) => e.amountBooked);
    const totalBooked = amountBooked.reduce(
      (partial_sum, a) => partial_sum + a,
      0
    );
    const amountDelivered = amountPerDate.map((e) => e.amountDelivered);
    const totalDelivered = amountDelivered.reduce(
      (partial_sum, a) => partial_sum + a,
      0
    );

    if (labels.length === 0) {
      setPerRealtorBarData(null);
      return;
    }

    if (totalBooked === 0 && totalDelivered === 0) {
      setPerRealtorBarData(null);
      return;
    }

    var palette = getColors(2);

    setAmountPerDateLineData({
      labels: labels,
      datasets: [
        {
          label: "Antal bokningar",
          data: amountBooked,
          fill: false,
          borderWidth: 2,
          backgroundColor: palette[0],
          borderColor: palette[0],
        },
        {
          label: "Antal leveranser",
          data: amountDelivered,
          fill: false,
          borderWidth: 2,
          backgroundColor: palette[1],
          borderColor: palette[1],
        },
      ],
    });
  }, [amountPerDate]);

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <animated.div style={springprops}>
      <div className="statisticsWrapper">
        <div className="summarySection">
          <h3>Sammanfattning</h3>
          <div className="summaryGrid">
            <div className="summaryWrapper">
              <h1>{idx(createdBookings, (_) => _.length) || 0}</h1>
              <p>
                <small>Bokningar skapade</small>
              </p>
            </div>
            <div className="summaryWrapper">
              <h1>{idx(deliveredBookings, (_) => _.length) || 0}</h1>
              <p>
                <small>Bokningar levererade</small>
              </p>
            </div>
          </div>
        </div>
        <div className="realtorSection">
          <h3>Efter mäklare</h3>
          <label>Bokningar per mäklare under perioden</label>
          {perRealtorBarData ? (
            <div>
              <Bar
                data={perRealtorBarData}
                height={450}
                options={{
                  indexAxis: "y",
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "right",
                    },
                  },
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="amountSection">
          <h3>Efter datum</h3>
          <label>Antal bokningar över perioden</label>
          {amountPerDateLineData ? (
            <div>
              <Line
                data={amountPerDateLineData}
                height={450}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="serviceSection">
          <h3>Efter tjänst</h3>
          <label>Totalt antal av varje tjänst under periodens bokningar</label>
          {perServiceDoughnutData ? (
            <div>
              <Doughnut
                data={perServiceDoughnutData}
                height={250}
                options={{ maintainAspectRatio: false }}
              />
            </div>
          ) : null}
        </div>
        <div className="estateSection">
          <h3>Efter fastighetstyp</h3>
          <label>
            Totalt antal av varje fastighetstyp under periodens bokningar
          </label>
          {amountPerEstateTypeDoughnutData ? (
            <div>
              <Doughnut
                data={amountPerEstateTypeDoughnutData}
                height={250}
                options={{ maintainAspectRatio: false }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </animated.div>
  );
};

export default StatisticsWrapper;

const getColors = (amount) => {
  const dark = isDarkMode();

  const c1 = {
    r: dark ? 10 : 54,
    g: dark ? 169 : 50,
    b: dark ? 134 : 201,
  };

  const c2 = {
    r: dark ? 255 : 246,
    g: dark ? 169 : 96,
    b: dark ? 0 : 255,
  };

  const c1RGB = `rgb(${c1.r}, ${c1.g}, ${c1.b})`;
  const c2RGB = `rgb(${c2.r}, ${c2.g}, ${c2.b})`;

  const result = [];
  const pal = interpolate([c1RGB, c2RGB]);

  for (var i = 0; i < amount; i++) {
    const dividend = amount - 1 || 1;
    const fraction = (1 / dividend) * i;
    result.push(pal(fraction));
  }

  return result;
};

const isDarkMode = () => {
  var dark = false;
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    dark = true;
  }
  return dark;
};

const getDateRange = (start, end) => {
  if (!start || !end) return;
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};
