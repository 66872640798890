import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

// assets:
import UnChecked from '../../assets/checkbox-unchecked.svg';
import Checked from '../../assets/checkbox-checked.svg';
import idx from 'idx';

const CheckboxGroup = ({
  rows,
  state,
  setState,
  className,
  id,
  minchecked,
  hasSelectAll,
}) => {
  const updateChecked = (input) => {
    setState((prev) => {
      if (prev.some((e) => e === input.value)) {
        return prev.filter((e) => e !== input.value);
      } else {
        return [...prev, input.value];
      }
    });
  };

  useEffect(() => {
    if (minchecked != null) {
      if (state.length < minchecked) {
        setState([rows[0].value]);
      }
    }
  }, [state]);

  return (
    <div className={className || 'checkboxgroup'} id={id}>
      {hasSelectAll ? (
        <div
          className="checkboxrow selectall"
          onClick={() => {
            if (state.length === rows.length) {
              setState([]);
            } else {
              setState((_) => {
                return rows.map((e) => {
                  return e.value;
                });
              });
            }
          }}
        >
          <img
            src={state.length === rows.length ? Checked : UnChecked}
            className="doinvert"
            alt=""
          />
          <p>
            {state.length === rows.length ? 'Avmarkera alla' : 'Markera alla'}
          </p>
        </div>
      ) : null}
      {rows.map((row) => {
        return (
          <div
            className="checkboxrow"
            onClick={() => updateChecked(row)}
            key={idx(row, (_) => _.value) || uuidv4()}
          >
            <img
              src={state.some((e) => e === row.value) ? Checked : UnChecked}
              className="doinvert"
              alt=""
            />
            <p>{row.label}</p>
          </div>
        );
      })}
    </div>
  );
};

export default CheckboxGroup;

// a row is supposed to look like:
// {
//     label: 'XXX',
//     value: 'XXX'
// }
