import idx from "idx";
import React from "react";
import { animated, useSpring } from "react-spring";
import {
  addToURL,
  formatPhoneNumber,
  getDiceBearAvatar,
  getFullname,
} from "../../../../functions";

const UserWrapper = ({ member }) => {
  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 8, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <animated.div style={springprops}>
      <div
        className="memberwrapper"
        onClick={() => {
          addToURL("handleuser?uid=" + member.uid);
        }}
      >
        <div className="membermetawrapper">
          <img
            src={idx(member, _ => _.photoURL.w128) || getDiceBearAvatar(member.uid)}
            className="db_avatar"
            alt=""
          />
          <div className="membermeta">
            <h4>{getFullname(member)}</h4>
            <p>
              <small className="mono faded">
                {idx(
                  member,
                  (_) =>
                    `${member.jobTitle || "ingen jobbtitel"}, ${
                      member.email || "ingen e-postadress"
                    }, ${
                      idx(
                        formatPhoneNumber(member.phone),
                        (_) => _.formatted
                      ) || "inget telefonnummer"
                    }`
                )}
              </small>
            </p>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

export default React.memo(UserWrapper);
