import { animated, useSpring } from "react-spring";
import React from "react";
import reactDom from "react-dom";
import Backbutton from "./Backbutton";

const Modal = ({ children, isOpen, setIsOpen, backNormal, backTarget, size }) => {
  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  if (!children || !isOpen) return null;

  return reactDom.createPortal(
    <div className="modal blur">
      <animated.div style={springprops}>
        <div className={`card ${size ? size : "small"}`}>
          <Backbutton
            normal={backNormal || "Avbryt"}
            target={backTarget || "Avbryt"}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }}
          />
          {children}
        </div>
      </animated.div>
    </div>,
    document.getElementById("modalportal")
  );
};

export default Modal;
