import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";
import idx from "idx";
import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import {
  addToURL,
  containsURL,
  getDiceBearInitials,
  getFullname,
  getURLComponents,
  getURLParams,
  removeFromURL,
} from "../../../functions";

// components:
import Backbutton from "../../global/Backbutton";
import EditOrganization from "./EditOrganization";
import HandleUser from "./HandleUser";
import MemberList from "./MemberList";

// assets:
import Edit from "../../../assets/edit.svg";
import AddUser from "../../../assets/adduser.svg";

const HandleOrganization = ({ userPermissions, parent }) => {
  const [organizationID, setOrganizationID] = useState("");
  const [organization, setOrganization] = useState(null);
  const [organizationMembers, setOrganizationMembers] = useState(null);

  useEffect(() => {
    let components = getURLComponents();
    components.forEach((comp) => {
      if (comp.includes("?") && comp.includes("orgid")) {
        comp = comp.split("?")[1];
        const params = getURLParams(comp);
        setOrganizationID(idx(params, (_) => _.orgid));
      }
    });
  }, []);

  useEffect(() => {
    if (organizationID === "" || organizationID == null) return;

    const db = getFirestore();

    const listener = onSnapshot(
      doc(db, "organizations", organizationID),
      (snapshot) => {
        setOrganization(snapshot.data());
      }
    );

    return () => {
      listener();
    };
  }, [organizationID]);

  useEffect(() => {
    if (organization == null) return;

    const db = getFirestore();

    const memberUids = idx(organization, (_) => _.members);
    if (memberUids == null) {
      setOrganizationMembers([]);
      return;
    }
    if (memberUids.length === 0) {
      setOrganizationMembers([]);
      return;
    }

    memberUids.forEach((member) => {
      getDoc(doc(db, "users", member.uid)).then((doc) => {
        const data = doc.data();
        if (data != null) {
          setOrganizationMembers((prev) => {
            const tempMembers = prev ? [...prev] : [];
            data.role = member.role;
            tempMembers.push(data);
            tempMembers.sort((a, b) =>
              a.name.first > b.name.first
                ? 1
                : b.name.first > a.name.first
                ? -1
                : 0
            );
            return tempMembers;
          });
        }
      });
    });
  }, [organization]);

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  const [admins, setAdmins] = useState("");

  useEffect(() => {
    if (organizationMembers == null) return;
    var tempAdmins = "";
    organizationMembers.forEach((member) => {
      if (member.role === "admin") {
        if (tempAdmins !== "") {
          tempAdmins += ", ";
        }
        tempAdmins += getFullname(member);
      }
    });
    setAdmins(tempAdmins);
  }, [organizationMembers]);

  const shouldShowUserPopup = containsURL("handleuser");

  if (containsURL("editorganization")) {
    return <EditOrganization organizationID={organizationID} />;
  }

  return (
    <>
      <animated.div style={shouldShowUserPopup ? null : springprops}>
        <div className="createbooking board">
          <Backbutton
            target={parent || "Hantera användare"}
            onClick={() => removeFromURL("handleorganization")}
          />
          <div className="title_avatar">
            <img
              src={
                idx(organization, (_) => _.avatars.size1x1) ||
                getDiceBearInitials(idx(organization, (_) => _.name))
              }
              className="db_initials"
              alt=""
            />
            <h1 className="title">
              {idx(organization, (_) => _.name) || "Hantera organisation"}
            </h1>
          </div>
          <p className="subtitle">
            {idx(organization, (_) => _.members.length) || 0}{" "}
            {idx(organization, (_) => _.members.length) === 1
              ? "medlem"
              : "medlemmar"}{" "}
            <br />
            <small className="mono faded">
              {idx(
                organization,
                (_) =>
                  `${organization.address || "ingen adress"}, ${
                    organization.postalcode || "ingen postkod"
                  }, ${organization.city || "ingen stad"}`
              )}
            </small>
            {admins !== "" ? (
              <>
                <br />
                <small className="mono faded">Administratörer: {admins}</small>
              </>
            ) : null}
          </p>
          <div className="buttonrow">
            <button onClick={() => addToURL("adduser")}>
              <img src={AddUser} className="doinvert" alt="" />
              Lägg till användare
            </button>
            <button onClick={() => addToURL("editorganization")}>
              <img src={Edit} className="doinvert" alt="" />
              Redigera organisation
            </button>
          </div>
          <MemberList members={organizationMembers} />
        </div>
      </animated.div>
      {shouldShowUserPopup ? (
        <HandleUser userPermissions={userPermissions} />
      ) : null}
    </>
  );
};

export default React.memo(HandleOrganization);
