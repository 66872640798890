import idx from "idx";
import { compressString } from "../../../functions";
import Trash from "../../../assets/trash.svg";
import { getFunctions, httpsCallable } from "@firebase/functions";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const FileWrapper = ({ file, editing, refresh }) => {
  const base64 = idx(file, (_) => _.thumbnail);

  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    setDeleting(false);
  }, [file]);

  const deleteFile = () => {
    var filePath = idx(file, (_) => _.metadata.path_display);
    if (!filePath) return;
    setDeleting(true);
    const functions = getFunctions();
    const deleteFileFromDropbox = httpsCallable(
      functions,
      "deleteFileFromDropbox"
    );
    const promise = deleteFileFromDropbox({ filePath: filePath })
      .then((res) => {
        if (refresh != null) {
          refresh((prev) => !prev);
        }
      })
      .catch((err) => {
        setDeleting(false);
      });

    toast.promise(promise, {
      pending: "Tar bort en fil...",
      error: "Någonting gick fel. Vänligen försök igen senare!",
      success: "Filen raderades utan problem!",
    });
  };

  return (
    <div
      className={`filewrapper${editing ? " editing" : ""}${
        deleting ? " deleting" : ""
      }`}
    >
      <img
        src={base64 ? `data:image/jpeg;base64,${base64}` : null}
        className="thumbnail"
        alt=""
      />
      <p>
        <small>
          {compressString(
            idx(file, (_) => _.metadata.name),
            20
          )}
          <span className="mono faded">
            {" "}
            -{" "}
            {Math.round(
              ((idx(file, (_) => _.metadata.size) || 0) / 1000) * 100
            ) / 100}
            kb
          </span>
        </small>
      </p>
      {editing ? (
        <img
          src={Trash}
          className="deletebutton"
          alt=""
          onClick={deleteFile}
          tooltip="Ta bort fil"
        />
      ) : null}
    </div>
  );
};

export default FileWrapper;
