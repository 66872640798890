import { getAuth } from "@firebase/auth";
import {
  arrayUnion,
  collection,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "@firebase/firestore";
import idx from "idx";
import React, { useState, useEffect, useCallback } from "react";
import { animated, useSpring } from "react-spring";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import Backbutton from "../../global/Backbutton";
import MessageInputWrapper from "./wrappers/MessageInputWrapper";
import MessageWrapper from "./wrappers/MessageWrapper";

const DiscussionModal = ({ setShowingDiscussion, booking }) => {
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    if (booking == null) return;
    const db = getFirestore();
    const listener = onSnapshot(
      query(
        collection(
          db,
          "users",
          booking.uid,
          "bookings",
          booking.id,
          "discussion"
        ),
        orderBy("timeSent", "desc")
      ),

      (snapshot) => {
        const tempmessages = [];
        snapshot.forEach((it) => {
          tempmessages.push(it.data());
        });
        setMessages(tempmessages);
      }
    );

    return () => {
      listener();
    };
  }, []);

  const springpropsModal = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 0, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });
  const springpropsWrapper = useSpring({
    to: { opacity: 1, x: "0%", maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: "100%", maxWidth: "100%", maxHeight: "100%" },
    config: {
      mass: 1,
      tension: 210,
      friction: 20,
      precision: 0.01,
      velocity: 0,
    },
  });

  return (
    <animated.div
      style={springpropsModal}
      className="discussionmodal cardwrapper"
      onClick={() => setShowingDiscussion(false)}
    >
      <animated.div
        style={springpropsWrapper}
        className="discussionwindow"
        onClick={(e) => e.stopPropagation()}
      >
        <Backbutton
          normal={"Stäng"}
          target={"Bokningsdetaljer"}
          onClick={() => setShowingDiscussion(false)}
        />
        <div className="discussionwrapper">
          {messages ? (
            messages.length > 0 ? (
              <div className="discussionlist">
                {messages.map((msg) => {
                  return (
                    <MessageWrapper
                      message={msg}
                      booking={booking}
                      key={uuidv4()}
                    />
                  );
                })}
              </div>
            ) : (
              <p>Inga meddelanden ännu</p>
            )
          ) : null}
        </div>
        <MessageInputWrapper booking={booking} />
      </animated.div>
    </animated.div>
  );
};

export default React.memo(DiscussionModal);
