import React from 'react';

// assets:
import Back from '../../assets/back.svg';

const HyperLink = ({ target, onClick }) => {
  return (
    <div className="hyperlink" onClick={onClick}>
      <p>
        <strong>{target}</strong>
      </p>
      <img src={Back} alt="" className="doinvert" />
    </div>
  );
};

export default HyperLink;
