import { deleteApp, initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytes,
  uploadString,
  getDownloadURL,
} from 'firebase/storage';
import idx from 'idx';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

export const getFirebaseConfig = () => {
  return {
    apiKey: 'AIzaSyD51VdRqQPAZv3_6pfdygEloQnogI1JQQM',
    authDomain: 'hofvander-f88b0.firebaseapp.com',
    projectId: 'hofvander-f88b0',
    storageBucket: 'hofvander-f88b0.appspot.com',
    messagingSenderId: '158333365812',
    appId: '1:158333365812:web:5fc18635fe6582cf1fe461',
    measurementId: 'G-CYJTLCW47J',
  };
};

export const addToURL = (input, target) => {
  const location = window.location;
  const origin = location.origin;
  const components = getURLComponents();
  var result = `${origin}/#/`;
  components.forEach((it, index) => {
    if (!it.includes(input) && it !== '') {
      result += it + '/';
    }
  });
  result += input;
  // window.location = result;

  window.open(result, target || '_self');
};

export const removeFromURL = (input) => {
  const location = window.location;
  const origin = location.origin;
  const components = getURLComponents();
  if (components.length === 0) {
    return;
  }
  var result = `${origin}/#/`;
  components.forEach((it, index) => {
    if (!it.includes(input)) {
      if (index !== 0) {
        result += '/';
      }
      result += it;
    }
  });
  window.location = result;
};

export const toggleURL = (input) => {
  const components = getURLComponents();
  if (components.length === 0) {
    return;
  }
  if (components.some((e) => e.includes(input))) {
    removeFromURL(input);
  } else {
    addToURL(input);
  }
};

export const containsURL = (input) => {
  const components = getURLComponents();
  if (components.length === 0) {
    return;
  }
  if (components.some((e) => e.includes(input))) {
    return true;
  } else {
    return false;
  }
};

export const getURLComponents = () => {
  var result = [];
  const location = window.location;
  if (location.href.includes('/#/')) {
    const components = location.href.split('/#/');
    if (components.length > 1) {
      const current = components[1];
      result = current.split('/');
    }
  }
  return result;
};

export const getURLParams = (input) => {
  const urlSearchParams = new URLSearchParams(input);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
};

export const getURLParameter = (input) => {
  let components = getURLComponents();
  let result;
  components.forEach((comp) => {
    if (comp.includes('?') && comp.includes(input)) {
      comp = comp.split('?')[1];
      const params = getURLParams(comp);
      result = idx(params, (_) => _[input]);
    }
  });
  return result;
};

export const getDiceBearAvatar = (seed) => {
  if (seed == null) {
    seed = uuidv4();
  }
  let result = `https://avatars.dicebear.com/api/micah/${seed}.svg`;
  return result;
};

export const getDiceBearInitials = (name) => {
  if (name == null) {
    name = '';
  }
  var initials = '';
  let components = name.split(' ');
  components.forEach((e) => {
    initials += e[0];
  });
  let result = `https://avatars.dicebear.com/api/initials/${initials}.svg`;
  return result;
};

export const getFullname = (member) => {
  let name = idx(member, (_) => _.name);
  if (name) {
    return `${member.name.first} ${member.name.last}`;
  }
};

export const formatPhoneNumber = (input) => {
  if (input == null) return;
  const phoneNumber = parsePhoneNumberFromString(input, 'SE');
  if (phoneNumber == null) return;
  return {
    formatted: phoneNumber.formatNational(),
    URI: phoneNumber.getURI(),
  };
};

export const validateFile = (filename, registry) => {
  // registry should be formatted like:
  // /(\.TYPE1|\.TYPE2|\.TYPE3)$/i
  if (!registry.exec(filename)) {
    return false;
  } else {
    return true;
  }
};

export const setStateSafely = (setState, input) => {
  if (setState == null || input == null) return;
  setState((prev) => {
    return input || prev;
  });
};

export const createOrganization = (
  db,
  name,
  address,
  postalcode,
  city,
  orgnr,
  formoptions,
  notificationoptions,
  callback
) => {
  const ref = doc(collection(db, 'organizations'));
  const id = ref.id;
  pushOrganization(
    ref,
    name,
    address,
    postalcode,
    city,
    orgnr,
    id,
    formoptions,
    notificationoptions,
    callback
  );
};

export const updateOrganization = (
  db,
  name,
  address,
  postalcode,
  city,
  orgnr,
  id,
  formoptions,
  notificationoptions,
  callback
) => {
  const ref = doc(db, 'organizations', id);
  pushOrganization(
    ref,
    name,
    address,
    postalcode,
    city,
    orgnr,
    id,
    formoptions,
    notificationoptions,
    callback
  );
};

export const pushOrganization = (
  ref,
  name,
  address,
  postalcode,
  city,
  orgnr,
  id,
  formoptions,
  notificationoptions,
  callback
) => {
  const promise = setDoc(
    ref,
    {
      name: name || null,
      address: address || null,
      postalcode: postalcode || null,
      city: city || null,
      orgnr: orgnr || null,
      id: id || null,
      formoptions: formoptions || null,
      notificationoptions: notificationoptions || null,
    },
    { merge: true }
  )
    .then(() => {
      callback(true);
    })
    .catch((err) => {
      callback(false);
    });

  toast.promise(promise, {
    pending: 'Skapar organisation. Vänligen lämna inte sidan.',
    success: 'Allt gick bra - organisationen är sparad.',
    error: 'Något gick snett! Vänligen försök igen senare.',
  });
};

export const createUser = (
  type,
  firstName,
  lastName,
  organization,
  jobTitle,
  email,
  phone,
  callback
) => {
  const detachedApp = initializeApp(getFirebaseConfig(), 'authApp');
  const detachedAuth = getAuth(detachedApp);

  const promise = createUserWithEmailAndPassword(detachedAuth, email, uuidv4())
    .then((userCredential) => {
      const uid = userCredential.user.uid;
      pushUser(
        uid,
        type,
        firstName,
        lastName,
        organization,
        jobTitle,
        email,
        phone,
        callback,
        detachedAuth,
        detachedApp
      );
      const promise2 = sendPasswordResetEmail(detachedAuth, email)
        .then(() => {})
        .catch((err) => {
          console.error({ err });
        });

      toast.promise(promise2, {
        pending:
          'Skickar mejl med återställningslänk för lösenord. Vänligen lämna inte sidan.',
        success:
          'Mejlet skickades utan problem! Kolla skräpposten om ni inte ser det direkt.',
        error:
          'Något gick snett! Tryck på "Återställ lösenord" på inloggningssidan för att få en ny länk.',
      });
    })
    .catch((err) => {
      console.error({ err });
      deleteApp(detachedApp);
      callback(false);
    });

  toast.promise(promise, {
    pending: 'Skapar inloggning. Vänligen lämna inte sidan.',
    success:
      'Inloggningen skapades! Vi skickar ett mejl med instruktioner för att återställa deras lösenord.',
    error: 'Något gick snett! Vänligen försök igen senare.',
  });
};

export const pushUser = (
  uid,
  type,
  firstName,
  lastName,
  organization,
  jobTitle,
  email,
  phone,
  callback,
  detachedAuth,
  detachedApp
) => {
  const db = getFirestore();
  const ref = doc(db, 'users', uid);

  const promise = setDoc(
    ref,
    {
      name: {
        first: firstName,
        last: lastName,
      },
      organization: organization,
      jobTitle: jobTitle,
      email: email,
      phone: phone,
      uid: uid,
    },
    { merge: true }
  )
    .then(() => {
      const setAsGlobalAdmin = () => {
        const promise2 = updateDoc(doc(db, 'meta', 'admins'), {
          users: arrayUnion({
            uid: uid,
            role: type,
          }),
        })
          .then(() => {
            callback(true);
            deleteApp(detachedApp);
          })
          .catch((err) => {
            console.error({ err });
            callback(false);
            deleteApp(detachedApp);
          });

        toast.promise(promise2, {
          pending:
            'Tillhandahåller extra behörigheter för adminanvändare. Vänligen lämna inte sidan.',
          success: 'Allt gick bra - användaren har nu rätt behörigheter.',
          error: 'Något gick snett! Vänligen kontakta kundtjänst.',
        });
      };

      const promise2 = updateOrganizationMembers(organization, uid, type)
        .then(() => {
          callback(true);
          deleteApp(detachedApp);
        })
        .catch((err) => {
          console.error({ err });
          callback(false);
          deleteApp(detachedApp);
        });

      toast.promise(promise2, {
        pending: 'Tillhandahåller behörigheter. Vänligen lämna inte sidan.',
        success: 'Allt gick bra - användaren har nu rätt behörigheter.',
        error: 'Något gick snett! Vänligen kontakta kundtjänst.',
      });

      if (organization === 'INTERNAL') {
        if (type === 'superadmin' || type === 'admin') {
          setAsGlobalAdmin();
        }
      }

      const promise3 = updateDoc(doc(db, 'organizations', organization), {
        members: arrayUnion({
          uid: uid,
          role: type,
        }),
      });

      toast.promise(promise3, {
        pending:
          'Tillhandahåller extra behörigheter för adminanvändare. Vänligen lämna inte sidan.',
        success: 'Allt gick bra - användaren har nu rätt behörigheter.',
        error: 'Något gick snett! Vänligen kontakta kundtjänst.',
      });
    })
    .catch((err) => {
      console.error({ err });
      const promise2 = deleteUser(detachedAuth.currentUser)
        .then(() => {
          callback(false);
          deleteApp(detachedApp);
        })
        .catch(() => {
          callback(false);
          deleteApp(detachedApp);
        });

      toast.promise(promise2, {
        pending:
          'Tar bort användare p.g.a. tekniskt fel. Vänligen lämna inte sidan.',
        success:
          'Allt gick bra - du kan försöka skapa användaren vid ett senare tillfälle.',
        error: 'Något gick snett! Vänligen kontakta kundtjänst.',
      });
    });

  toast.promise(promise, {
    pending: 'Laddar upp användardata. Vänligen lämna inte sidan.',
    success: 'Allt gick bra - användaren är nu färdigskapad.',
    error: 'Något gick snett! Vänligen försök igen senare.',
  });
};

export const updateUser = (
  firstName,
  lastName,
  type,
  jobTitle,
  phone,
  uid,
  organization,
  callback
) => {
  if (uid == null || uid === '') {
    callback(false);
    return;
  }

  const db = getFirestore();

  const promise = updateDoc(doc(db, 'users', uid), {
    'name.first': firstName,
    'name.last': lastName,
    jobTitle: jobTitle,
    phone: phone,
  })
    .then(() => {
      const promise2 = updateOrganizationMembers(organization, uid, type)
        .then(() => {
          callback(true);
        })
        .catch((err) => {
          console.error({ err });
          callback(false);
        });
      toast.promise(promise2, {
        pending: 'Tillhandahåller behörigheter. Vänligen lämna inte sidan.',
        success: 'Allt gick bra - användaren har nu rätt behörigheter.',
        error: 'Något gick snett! Vänligen kontakta kundtjänst.',
      });
    })
    .catch((err) => {
      console.error({ err });
      callback(false);
    });

  toast.promise(promise, {
    pending: 'Uppdaterar användardata. Vänligen lämna inte sidan.',
    success: 'Allt gick bra - användaren är nu uppdaterad.',
    error: 'Något gick snett! Vänligen försök igen senare.',
  });
};

export const updateOrganizationMembers = (organization, uid, type) => {
  return new Promise((resolve, reject) => {
    if (!organization || !uid || !type) reject(new Error('missing parameters'));
    const db = getFirestore();
    getDoc(doc(db, 'organizations', organization))
      .then((res) => {
        if (!res.exists) {
          reject(new Error('Organization does not exist'));
        }
        const data = res.data();
        var members = data.members || [];
        var newMembers = [];
        members.forEach((it) => {
          if (it.uid !== uid) {
            newMembers.push(it);
          }
        });
        newMembers.push({
          uid: uid,
          role: type,
        });
        updateDoc(res.ref, {
          members: newMembers,
        })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createBooking = (
  name,
  uid,
  organizationID,
  deadline,
  address,
  postalcode,
  city,
  instructions,
  estatetype,
  propertysize,
  services,
  editingstyle,
  sellername,
  sellerphone,
  selleremail,
  other,
  whoisbooking,
  bookedtime,
  files,
  callback
) => {
  if (uid == null || uid === '') {
    callback(false);
    return;
  }

  const db = getFirestore();

  const ref = doc(collection(db, 'users', uid, 'bookings'));
  const bookingId = ref.id;

  const firDeadline = Timestamp.fromDate(deadline);

  const promise = setDoc(ref, {
    name: name || null,
    uid: uid || null,
    organizationID: organizationID || null,
    deadline: firDeadline || serverTimestamp(),
    address: {
      address: address,
      postalcode: postalcode,
      city: city,
    },
    instructions: instructions || null,
    estatetype: estatetype || null,
    propertysize: propertysize || null,
    services: services || null,
    editingstyle: editingstyle || null,
    sellername: sellername || null,
    sellerphone: sellerphone || null,
    selleremail: selleremail || null,
    other: other || null,
    whoisbooking: whoisbooking || null,
    bookedtime: bookedtime || null,
    timecreated: serverTimestamp(),
    id: bookingId,
    status: '',
    timedelivered: null,
  })
    .then(async () => {
      toast.info(
        'Om du inte får ett bekräftelsemejl inom några minuter har någonting gått snett. Vänligen kontakta kundtjänst isåfall.'
      );

      await addDoc(collection(ref, 'status'), {
        comment: '',
        status: bookedtime ? 'BOOKED' : 'NOT_BOOKED',
        timeCreated: serverTimestamp(),
      });

      if (files.length < 1) {
        callback(true);
      } else {
        const storage = getStorage();
        const bucketref = `${organizationID}/${bookingId}/files/`;

        const promise2 = uploadMultipleFiles(files, storage, bucketref)
          .then(() => {
            callback(true);
          })
          .catch((err) => {
            console.log({ err });
            callback(false);
          });

        toast.promise(promise2, {
          pending: 'Laddar upp filer. Vänligen lämna inte sidan.',
          success: 'Allt gick bra - filerna är uppladdade.',
          error: 'Något gick snett! Vänligen försök igen senare.',
        });
      }
    })
    .catch((err) => {
      callback(false);
    });

  toast.promise(promise, {
    pending: 'Skapar bokning. Vänligen lämna inte sidan.',
    success: 'Allt gick bra - bokningen är nu skapad.',
    error: 'Något gick snett! Vänligen försök igen senare.',
  });
};

export const updateBooking = (
  name,
  uid,
  bookingId,
  organizationID,
  deadline,
  address,
  postalcode,
  city,
  instructions,
  estatetype,
  propertysize,
  services,
  editingstyle,
  sellername,
  sellerphone,
  selleremail,
  other,
  whoisbooking,
  bookedtime,
  files,
  callback
) => {
  if (uid == null || uid === '') {
    callback(false);
    return;
  }

  const db = getFirestore();

  const ref = doc(db, 'users', uid, 'bookings', bookingId);

  const firDeadline = Timestamp.fromDate(deadline);

  const promise = updateDoc(ref, {
    name: name || null,
    uid: uid || null,
    organizationID: organizationID || null,
    deadline: firDeadline || serverTimestamp(),
    address: {
      address: address,
      postalcode: postalcode,
      city: city,
    },
    instructions: instructions || null,
    estatetype: estatetype || null,
    propertysize: propertysize || null,
    services: services || null,
    editingstyle: editingstyle || null,
    sellername: sellername || null,
    sellerphone: sellerphone || null,
    selleremail: selleremail || null,
    other: other || null,
    whoisbooking: whoisbooking || null,
    bookedtime: bookedtime || null,
    id: bookingId,
    timedelivered: null,
  })
    .then(() => {
      if (files.length < 1) {
        callback(true);
      } else {
        const storage = getStorage();
        const bucketref = `${organizationID}/${bookingId}/files/`;

        const promise2 = uploadMultipleFiles(files, storage, bucketref);

        toast.promise(promise2, {
          pending: 'Laddar upp filer. Vänligen lämna inte sidan.',
          success: 'Allt gick bra - filerna är uppladdade.',
          error: 'Något gick snett! Vänligen försök igen senare.',
        });
      }
    })
    .catch((err) => {
      callback(false);
    });

  toast.promise(promise, {
    pending: "Uppdaterar' bokning. Vänligen lämna inte sidan.",
    success: 'Allt gick bra - bokningen är nu uppdaterad.',
    error: 'Något gick snett! Vänligen försök igen senare.',
  });
};

export const uploadMultipleFiles = (files, storage, bucketref) => {
  // bucketref should be formatted like:
  // 'path/subpath/'
  return new Promise((resolve, reject) => {
    var completedOperations = 0;
    var results = [];

    const attemptResolve = () => {
      if (completedOperations === files.length) {
        resolve(results);
      }
    };

    files.forEach((file) => {
      var filename = file.name.replaceAll(' ', '_');
      const storageRef = ref(storage, `${bucketref}${filename}`);
      uploadBytes(storageRef, file)
        .then((snapshot) => {
          completedOperations++;
          results.push(snapshot);
          attemptResolve();
        })
        .catch((err) => {
          completedOperations++;
          console.error({ err });
          toast.error(`Misslyckades med filuppladdningen av ${file.name}`);
          attemptResolve();
        });
    });
  });
};

export const uploadSingleBlob = (blob, path, filename) => {
  return new Promise((resolve, reject) => {
    const storage = getStorage();
    if (blob == null || path == null || storage == null) {
      throw new Error('Någonting gick fel. Vänligen försök igen senare.');
    }
    if (idx(blob, (_) => _.name) == null && filename == null) {
      throw new Error('Saknar filnamn');
    }
    const storageRef = ref(
      storage,
      `${path}/${filename || idx(blob, (_) => _.name)}`
    );
    uploadString(storageRef, blob, 'data_url')
      .then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then((downloadURL) => {
            resolve(downloadURL);
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        throw err;
      });
  });
};

export var USER_CACHE = [];
export const getUser = (uid) => {
  return new Promise((resolve, reject) => {
    if (!uid) reject();
    let index = USER_CACHE.findIndex((e) => e.uid === uid);
    if (index >= 0) {
      resolve(USER_CACHE[index]);
    }

    const db = getFirestore();
    getDoc(doc(db, 'users', uid)).then((document) => {
      if (document.exists) {
        cacheUser(document.data());
        resolve(document.data());
      }
    });
  });
};

export const cacheUser = (user) => {
  if (!user) return;
  let index = USER_CACHE.findIndex((e) => e.uid === user.uid);
  if (index < 0) {
    USER_CACHE.push(user);
  } else {
    USER_CACHE[index] = user;
  }
};

export var ORGANIZATION_CACHE = [];
export const getOrganization = (id, callback) => {
  if (!id) return;
  let index = ORGANIZATION_CACHE.findIndex((e) => e.id === id);
  if (index >= 0) {
    callback(ORGANIZATION_CACHE[index]);
    return;
  }

  const db = getFirestore();
  getDoc(doc(db, 'organizations', id)).then((document) => {
    if (document.exists) {
      cacheOrganization(document.data());
      callback(document.data());
    }
  });
};

export const cacheOrganization = (organization) => {
  if (!organization) return;
  let index = ORGANIZATION_CACHE.findIndex((e) => e.uid === organization.uid);
  if (index < 0) {
    ORGANIZATION_CACHE.push(organization);
  } else {
    ORGANIZATION_CACHE[index] = organization;
  }
};

var userPermissions = {};
export const getUserPermissions = (callback) => {
  const uid = idx(getAuth(), (_) => _.currentUser.uid);
  if (uid == null) return 'client';
  if (idx(userPermissions, (_) => _.uid) === uid) {
    callback(idx(userPermissions, (_) => _.permissions) || 'client');
    return;
  }
  const db = getFirestore();
  getDoc(doc(db, 'users', uid))
    .then((res) => {
      if (!res.exists) {
        callback('client');
        return;
      }
      const user = res.data();
      getDoc(doc(db, 'organizations', user.organization))
        .then((res) => {
          if (!res.exists) {
            callback('client');
            return;
          }
          const organization = res.data();
          organization.members.forEach((e) => {
            if (e.uid === uid) {
              userPermissions = {
                permissions: e.role,
                uid: uid,
              };
            }
          });
          callback(idx(userPermissions, (_) => _.permissions) || 'client');
          return;
        })
        .catch((err) => {
          console.log({ err });
          callback('client');
          return;
        });
    })
    .catch((err) => {
      console.log({ err });
      callback('client');
      return;
    });
};

export const getUserPermissionsFromIDS = (uid, organizationID) => {
  return new Promise((resolve, reject) => {
    if (!uid || !organizationID) reject(new Error('missing parameters'));
    const db = getFirestore();
    getDoc(doc(db, 'organizations', organizationID))
      .then((res) => {
        if (!res.exists) reject(new Error("organization doesn't exist"));
        const data = res.data();
        try {
          data.members.forEach((it) => {
            if (it.uid === uid) {
              resolve(it.role);
            }
          });
          reject(new Error('found no user corresponding to this uid'));
        } catch (err) {
          reject(err);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHumanDate = (timestamp) => {
  if (timestamp == null) return;
  let date = timestamp.toDate();
  return date.toLocaleDateString('sv-SE');
};

export const getHumanDateTime = (timestamp) => {
  let date = idx(timestamp, (_) => _.toDate());
  if (!date) return;
  let options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return date.toLocaleString('sv-SE', options);
};

export const getRelativeDateTime = (timestamp) => {
  let date = idx(timestamp, (_) => _.toDate());
  if (!date) return;
  let diff = Math.floor((new Date().getTime() - date.getTime()) / 1000 / 60);

  switch (true) {
    case diff < 1:
      return 'nyss';
    case diff < 60:
      return `${diff} min`;
    case diff < 240:
      return `${Math.floor(diff / 60)} h`;
    default:
      break;
  }
  if (
    date.getDate() === new Date().getDate() &&
    date.getMonth() === new Date().getMonth() &&
    date.getFullYear() === new Date().getFullYear()
  ) {
    let options = {
      hour: '2-digit',
      minute: '2-digit',
    };
    return date.toLocaleString('sv-SE', options);
  }
  const MS_IN_DAY = 1000 * 60 * 60 * 24;
  const compInput = new Date(date.getTime()).setHours(0, 0, 0, 0);
  const compNow = new Date().setHours(0, 0, 0, 0);

  const isYesterday = compNow - compInput < MS_IN_DAY * 1.5;
  if (isYesterday) {
    let options = {
      hour: '2-digit',
      minute: '2-digit',
    };
    return `igår ${date.toLocaleString('sv-SE', options)}`;
  }

  const isWithin3Days = compNow - compInput < MS_IN_DAY * 3.5;
  const isSameWeek = getWeekNumber(new Date()) === getWeekNumber(date);
  if (isWithin3Days || isSameWeek) {
    let options = {
      weekday: 'short',
      hour: '2-digit',
      minute: '2-digit',
    };
    return `${date.toLocaleString('sv-SE', options)}`;
  }

  return getHumanDateTime(timestamp);
};

const getWeekNumber = (date) => {
  try {
    var dayNum = date.getUTCDay() || 7;
    date.setUTCDate(date.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    return Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
  } catch {}
};

export const shortenString = (input, length, suffix) => {
  if (input == null || length == null) return input;
  return input.length > length
    ? input.substr(0, length - 1) + (suffix || '…')
    : input;
};

export const compressString = (input, length, suffix) => {
  if (input == null || length == null) return input;
  if (input.length > length) {
    let part1 = input.substr(0, length - 8);
    let part2 = input.substr(input.length - 8, input.length);
    return part1 + '…' + part2;
  }
  return input;
};

export const statusOptions = [
  {
    label: 'Bokad',
    value: 'BOOKED',
  },
  {
    label: 'Obokad',
    value: 'NOT_BOOKED',
  },
  {
    label: 'Avbokad',
    value: 'CANCELED',
  },
  {
    label: 'Levererad',
    value: 'DELIVERED',
  },
];

export const getStatus = (booking) => {
  var result = 'Okänt';
  statusOptions.forEach((e) => {
    if (e.value === idx(booking, (_) => _.status)) {
      result = e.label;
    }
  });
  return result;
};
