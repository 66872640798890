import React, { useEffect, useRef } from "react";

const ToolTip = () => {
  const ref = useRef(null);
  const containerRef = useRef(null)

  useEffect(() => {
    const updateTooltip = (e) => {
      let tooltip = ref.current;
      let container = containerRef.current;
      if (tooltip == null || container == null) return;
      let target = e.path[0];
      let targetTooltip = target.getAttribute("tooltip");
      if (targetTooltip == null) {
        container.style.opacity = 0;
        container.style.transform = "translateY(8px)";
        return;
      }
      tooltip.textContent = targetTooltip;

      const coords = getStaticPos(e, tooltip, target);

      container.style.left = `${coords.left}px`;
      container.style.top = `${coords.top}px`;
      container.style.opacity = 1;
      container.style.transform = "none";
    };

    window.addEventListener("mousemove", updateTooltip);

    return () => {
      window.removeEventListener("mousemove", updateTooltip);
    };
  }, []);

  return (
    <div ref={containerRef} className="stooltip">
      <div className="arrow"></div>
      <div className="textwrapper">
        <p ref={ref}></p>
      </div>
    </div>
  );
};

export default ToolTip;

const getStaticPos = (e, tooltip, target) => {
  let tooltipBounds = tooltip.getBoundingClientRect();
  let targetBounds = target.getBoundingClientRect();
  var leftScrollOffset =
    window.pageXOffset || document.documentElement.scrollLeft;
  var topScrollOffset =
    window.pageYOffset || document.documentElement.scrollTop;

  let left =
    targetBounds.left +
    targetBounds.width / 2 -
    tooltipBounds.width / 2 -
    12 +
    leftScrollOffset;
  let top = targetBounds.top - tooltipBounds.height - 8 - 8 + topScrollOffset;

  if (left < 0) left = 0;
  if (top < 0) top = 0;

  return { left: left, top: top };
};
