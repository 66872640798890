import { getFirestore, updateDoc } from "@firebase/firestore";
import idx from "idx";
import React from "react";
import { getRelativeDateTime } from "../../../functions";

const NotificationWrapper = ({ notification }) => {
  const dateStamp = getRelativeDateTime(notification.timecreated);

  const setAsSeen = () => {
    if (idx(notification, (_) => _.seen)) return;
    let ref = idx(notification, (_) => _.ref);
    if (!ref) return;
    updateDoc(ref, {
      seen: true,
    });
  };

  const setAsOpened = () => {
    if (idx(notification, (_) => _.opened)) return;
    let ref = idx(notification, (_) => _.ref);
    if (!ref) return;
    updateDoc(ref, {
      seen: true,
      opened: true,
    });
  };

  return (
    <div
      className={`notificationwrapper${notification.opened ? "" : " unopened"}`}
      onMouseOver={setAsSeen}
      onClick={() => {
        setAsOpened();
        if (notification.intent) {
          window.open(notification.intent, "_self");
        }
      }}
    >
      <h5>
        {notification.seen ? null : <span className="faded">Ny! </span>}
        {idx(notification, (_) => _.title)}
      </h5>
      <p>
        {idx(notification, (_) => _.description) ? (
          <>
            {idx(notification, (_) => _.description)}
            <br />
          </>
        ) : (
          ""
        )}
        <small className="faded">{dateStamp}{notification.opened ? null : " · inte öppnad"}</small>
      </p>
    </div>
  );
};

export default React.memo(NotificationWrapper);
