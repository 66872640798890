import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { toast } from 'react-toastify';
import {
  getOrganization,
  getUserPermissions,
  updateBooking,
  uploadMultipleFiles,
  validateFile,
} from '../../../functions';
import idx from 'idx';
import { v4 as uuid } from 'uuid';

// components:
import DatePicker from 'react-datepicker';
import Backbutton from '../../global/Backbutton';
import CheckboxGroup from '../../global/CheckboxGroup';
import EditingStyleSelector from '../booking/EditingStyleSelector';
import FileWrapper from './FileWrapper';
import { getFunctions, httpsCallable } from '@firebase/functions';
import { getStorage } from '@firebase/storage';

// assets:
import Checkmark from '../../../assets/checkmark.svg';
import AddFile from '../../../assets/addfile.svg';
import Clear from '../../../assets/xmark.svg';
import { doc, getDoc, getFirestore } from '@firebase/firestore';
import StatusTimeline from './wrappers/StatusTimeline';

const EditBookingForm = ({ backaction, booking }) => {
  const [userPermissions, setUserPermissions] = useState(null);
  useEffect(() => {
    getUserPermissions((res) => {
      setUserPermissions(res);
    });
  }, []);

  const [name, setName] = useState(idx(booking, (_) => _.name) || '');
  const [deadline, setDeadline] = useState(
    idx(booking, (_) => _.deadline.toDate()) || new Date()
  );
  const [address, setAddress] = useState(
    idx(booking, (_) => _.address.address) || ''
  );
  const [postalCode, setPostalCode] = useState(
    idx(booking, (_) => _.address.postalcode) || ''
  );
  const [city, setCity] = useState(idx(booking, (_) => _.address.city) || '');
  const [instructions, setInstructions] = useState(
    idx(booking, (_) => _.instructions) || ''
  );
  const [estateType, setEstateType] = useState(
    idx(booking, (_) => _.estatetype) || ''
  );
  const [propertySize, setPropertySize] = useState(
    idx(booking, (_) => _.propertysize) || 0
  );

  const [services, setServices] = useState(
    idx(booking, (_) => _.services) || []
  );
  const [serviceOptions, setServiceOptions] = useState([]);

  useEffect(() => {
    const db = getFirestore();
    getDoc(doc(db, 'meta', 'services'))
      .then((snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.data();
          const sOpt = data.services.map((it) => {
            return {
              label: it.label,
              value: it.id,
            };
          });
          setServiceOptions(sOpt);
        }
      })
      .catch((err) => {
        console.error({ err });
      });
  }, []);

  const [editingStyle, setEditingStyle] = useState(
    idx(booking, (_) => _.editingstyle) || ''
  );

  const [sellerName, setSellerName] = useState(
    idx(booking, (_) => _.sellername) || ''
  );
  const [sellerPhone, setSellerPhone] = useState(
    idx(booking, (_) => _.sellerphone) || ''
  );
  const [sellerEmail, setSellerEmail] = useState(
    idx(booking, (_) => _.selleremail) || ''
  );

  const [other, setOther] = useState(idx(booking, (_) => _.other) || '');

  const [bookedTime, setBookedTime] = useState(
    idx(booking, (_) => _.bookedtime.toDate()) || null
  );

  const [files, setFiles] = useState([]);
  const fileRef = useRef(null);
  const updateFiles = () => {
    const registry =
      /(\.png|\.jpg|\.jpeg|\.pdf|\.gif|\.heic|\.zip|\.rar|\.txt|\.docx|\.pages|\.rtf|\.eps|\.svg)$/i;
    var tempFiles = [];
    Object.keys(fileRef.current.files).forEach((key) => {
      let file = fileRef.current.files[key];
      if (validateFile(file.name, registry)) {
        if (file.size < 1000 * 1000 * 10) {
          tempFiles.push(file);
        } else {
          toast.error(
            `${file.name} kommer inte laddas upp p.g.a: Filstorleken överskrider maxgränsen om 10mb.`
          );
        }
      } else {
        toast.error(
          `${file.name} kommer inte laddas upp p.g.a: Otillåtet filformat.`
        );
      }
    });
    setFiles(tempFiles);
  };

  useEffect(() => {
    if (files.length === 0) return;
    let fileCopy = [...files];
    setFiles([]);

    let promise = doUploadOperation(fileCopy)
      .then((res) => {
        setTimeout(() => {}, 7500);
        setRefreshDropbox((prev) => !prev);
      })
      .catch((err) => {
        console.error(err);
      });

    toast.promise(promise, {
      pending: `Laddar upp ${fileCopy.length} st ${
        fileCopy.length > 1 ? 'filer' : 'fil'
      }`,
      error: 'Uppladdningen misslyckades. Vänligen försök igen senare.',
      success: 'Uppladdningen gick felfritt!',
    });
  }, [files]);

  const doUploadOperation = (files) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage();
      const bucketref = `${idx(booking, (_) => _.organizationID)}/${idx(
        booking,
        (_) => _.id
      )}/temp/`;
      uploadMultipleFiles(files, storage, bucketref)
        .then((res) => {
          var fileObjects = [];
          res.forEach((meta) => {
            const filePath = idx(meta, (_) => _.metadata.fullPath);
            if (!filePath) return;
            const fileName = filePath.split('/').pop();
            const bookingDropboxPath = idx(booking, (_) => _.dropbox.path);
            if (bookingDropboxPath == null) return;
            const folderPath = `${bookingDropboxPath}/bifogade filer`;
            fileObjects.push({
              name: fileName,
              path: filePath,
              dropboxpath: folderPath,
            });
          });

          const functions = getFunctions();
          const forceFiles = httpsCallable(functions, 'forceFilesToDropbox');
          forceFiles({ files: fileObjects, booking: idx(booking, (_) => _.id) })
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const [dropboxFiles, setDropboxFiles] = useState(null);
  const [refreshDropbox, setRefreshDropbox] = useState(false);

  useEffect(() => {
    const functions = getFunctions();
    const listFiles = httpsCallable(functions, 'listFilesFromDropbox');
    const bookingDropboxPath = idx(booking, (_) => _.dropbox.path);
    if (bookingDropboxPath == null) return;
    const folderPath = `${bookingDropboxPath}/bifogade filer`;
    listFiles({ folderPath: folderPath })
      .then((result) => {
        setDropboxFiles(idx(result, (_) => _.data.entries) || []);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [refreshDropbox]);

  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    getOrganization(
      idx(booking, (_) => _.organizationID),
      (callback) => {
        setOrganization(callback);
      }
    );
  }, []);

  const submit = (e) => {
    e.preventDefault();

    // verify fields:
    var valid = true;
    if (name == null || name === '') {
      toast.error('Ange namn');
      valid = false;
    }
    if (deadline == null || deadline === '') {
      toast.error('Ange när bilderna behöver vara klara');
      valid = false;
    }
    if (address == null || address === '') {
      toast.error('Ange fastighetens adress');
      valid = false;
    }
    if (estateType == null || estateType === '') {
      toast.error('Ange typ av fastighet');
      valid = false;
    }
    if (propertySize == null || propertySize === '') {
      toast.error('Ange fastighetens storlek');
      valid = false;
    }
    if (services == null || services === '' || services.length < 1) {
      toast.error('Ange minst en tjänst');
      valid = false;
    }
    if (idx(organization, (_) => _.formoptions.includes('SHOW_EDITINGSTYLE'))) {
      if (editingStyle == null || editingStyle === '') {
        toast.error('Ange redigeringstyp');
        valid = false;
      }
    }
    if (sellerName == null || sellerName === '') {
      toast.error('Ange namn på säljaren');
      valid = false;
    }
    if (sellerPhone == null || sellerPhone === '') {
      toast.error('Ange telefonnummer till säljaren');
      valid = false;
    }

    if (!valid) return;

    updateBooking(
      name,
      booking.uid,
      booking.id,
      booking.organizationID,
      deadline,
      address,
      postalCode,
      city,
      instructions,
      estateType,
      propertySize,
      services,
      editingStyle,
      sellerName,
      sellerPhone,
      sellerEmail,
      other,
      null,
      bookedTime,
      files,
      backaction
    );
  };

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: '100%', maxHeight: '100%' },
    from: { opacity: 0, x: 16, maxWidth: '100%', maxHeight: '100%' },
    config: { friction: 20, tension: 400 },
  });

  return (
    <animated.div style={springprops} className="editbooking">
      <div>
        <Backbutton
          normal={'Tillbaka'}
          target={'Bokningsdetailjer'}
          onClick={backaction}
        />
      </div>
      <form onSubmit={() => {}}>
        <label htmlFor="deadline" className="required">
          När behöver bilderna vara klara?
        </label>
        <DatePicker
          selected={deadline}
          onChange={(date) => setDeadline(date)}
          id="deadline"
        />
        <label htmlFor="address" className="required">
          Adress
        </label>
        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          id="address"
          placeholder="Adress"
          autoComplete="off"
        />
        <label className="required">Postnummer</label>
        <input
          type="text"
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
          id="postalcode"
          placeholder="Postnummer"
          autoComplete="off"
        />
        <label className="required">Ort</label>
        <input
          type="text"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          id="city"
          placeholder="Ort"
          autoComplete="off"
        />
        <label htmlFor="instructions">
          Övrig information gällande fastigheten
        </label>
        <textarea
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          id="instructions"
          placeholder="T.ex portkod, kodlås, gömd nyckel"
          rows="6"
        />
        <label className="required" htmlFor="estatetype">
          Typ av fastighet
        </label>
        <select id="estatetype" onChange={(e) => setEstateType(e.target.value)}>
          <option value="DISALLOWED" selected disabled>
            Välj
          </option>
          <option value="LÄGENHET">Lägenhet</option>
          <option value="VILLA">Villa</option>
          <option value="RADHUS">Radhus</option>
          <option value="FRITIDSHUS">Fritidshus</option>
        </select>
        <label className="required" htmlFor="propertysize">
          Fastighetens storlek (i m²)
        </label>
        <input
          type="number"
          value={propertySize}
          onChange={(e) => setPropertySize(e.target.value)}
          id="propertysize"
        />
        <label className="required" htmlFor="requiredservices">
          Vilka typer av fototjänster behövs?
        </label>
        <CheckboxGroup
          rows={serviceOptions}
          state={services}
          setState={setServices}
          id="requiredservices"
        />
        {idx(organization, (_) =>
          _.formoptions.includes('SHOW_EDITINGSTYLE')
        ) ? (
          <>
            <label className="required" htmlFor="editingstyle">
              Vilken typ av redigering efterfrågas?
            </label>
            <EditingStyleSelector
              state={editingStyle}
              setState={setEditingStyle}
              id={'editingstyle'}
            />
          </>
        ) : null}
        <label htmlFor="sellername" className="required">
          Namn på säljaren
        </label>
        <input
          type="text"
          value={sellerName}
          onChange={(e) => setSellerName(e.target.value)}
          id="sellername"
          placeholder="Säljarens namn"
          autoComplete="off"
        />
        <label htmlFor="sellerphone" className="required">
          Telefonnummer till säljaren
        </label>
        <input
          type="text"
          value={sellerPhone}
          onChange={(e) => setSellerPhone(e.target.value)}
          id="sellerphone"
          placeholder="Säljarens telefonnummer"
          autoComplete="off"
        />
        <label htmlFor="selleremail" className="required">
          Mejladress till säljaren
        </label>
        <input
          type="email"
          value={sellerEmail}
          onChange={(e) => setSellerEmail(e.target.value)}
          id="selleremail"
          placeholder="Säljarens e-postadress"
          autoComplete="off"
        />
        <label htmlFor="other">Ytterligare information och önskemål här:</label>
        <textarea
          value={other}
          onChange={(e) => setOther(e.target.value)}
          id="other"
          rows="6"
          placeholder="Övrigt"
        />
        {userPermissions === 'superadmin' ? (
          <>
            <label htmlFor="bookedTime">När är det inbokat?</label>
            <div className="inputwithclear">
              <DatePicker
                selected={bookedTime}
                onChange={(date) => setBookedTime(date)}
                id="bookedTime"
                showTimeSelect
                timeIntervals={10}
              />
              {bookedTime ? (
                <img
                  src={Clear}
                  className="imagebutton clearbutton"
                  alt=""
                  tooltip="Rensa datum"
                  onClick={(e) => {
                    e.stopPropagation();
                    setBookedTime(null);
                  }}
                />
              ) : null}
            </div>
            <div></div>
            <StatusTimeline
              userPermissions={userPermissions}
              booking={booking}
            />
          </>
        ) : null}
        <label htmlFor="fileinput">
          Om du har några filer, t.ex. planritning, kan du bifoga dem här.
        </label>
        {dropboxFiles == null ? (
          <p>Laddar filer...</p>
        ) : dropboxFiles.length === 0 ? (
          <p>Inga bifogade filer</p>
        ) : (
          <div className="filelist">
            {dropboxFiles.map((file) => {
              return (
                <FileWrapper
                  file={file}
                  editing={true}
                  refresh={setRefreshDropbox}
                  key={uuid()}
                />
              );
            })}
          </div>
        )}
        <input
          style={{
            position: 'absolute',
            top: '-200vh',
            opacity: '0',
          }}
          type="file"
          multiple
          ref={fileRef}
          onChange={updateFiles}
          accept=".png, .jpg, .jpeg, .pdf, .gif, .heic, .zip, .rar, .txt, .docx, .pages, .rtf, .eps, .svg"
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            fileRef.current.click();
          }}
        >
          <img src={AddFile} className="doinvert" alt="" />
          Lägg till filer
        </button>
        <div></div>
        <button type="button" onClick={submit}>
          <img src={Checkmark} className="doinvert" alt="" />
          Spara ändringar
        </button>
      </form>
    </animated.div>
  );
};

export default React.memo(EditBookingForm);
