import idx from 'idx';
import React, { useRef, useState, useEffect } from 'react';

import CheckboxGroup from './CheckboxGroup';

const MultiSelectInput = ({ rows, state, setState, hasSelectAll }) => {
  const [showingDropdown, setShowingDropdown] = useState(false);

  const [formattedState, setFormattedState] = useState('');

  useEffect(() => {
    let res = [];
    state.forEach((e) => {
      rows.forEach((e2) => {
        if (e2.value === e) {
          res.push(e2.label);
        }
      });
    });
    setFormattedState(res.join(', '));
  }, [state]);

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!showingDropdown) return;
    const handleClick = (e) => {
      if (
        !e.path.includes(inputRef.current) &&
        !e.path.includes(dropdownRef.current)
      ) {
        setShowingDropdown(false);
      }
    };
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [showingDropdown]);

  return (
    <div className="autocompleteinput">
      <input
        placeholder="Inga"
        onClick={() => {
          setShowingDropdown((p) => !p);
        }}
        value={formattedState}
        ref={inputRef}
        readOnly={true}
      />
      {showingDropdown ? (
        <div className="optiondropdown" ref={dropdownRef}>
          <div className="checkboxgroupwrapper">
            <CheckboxGroup
              rows={rows}
              state={state}
              setState={setState}
              hasSelectAll={hasSelectAll}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MultiSelectInput;

// a row is supposed to look like:
// {
//     label: 'XXX',
//     value: 'XXX'
// }
