import {
  collection,
  doc,
  getDoc,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from '@firebase/firestore';
import idx from 'idx';
import React, { useState, useEffect } from 'react';
import { getHumanDateTime, getRelativeDateTime } from '../../../../functions';
import EmptyScreen from '../../../global/EmptyScreen';
import HyperLink from '../../../global/HyperLink';
import { v4 as uuid } from 'uuid';

import Edit from '../../../../assets/edit.svg';

const OrganizationNewsflashWrapper = ({ organization, setEditObject }) => {
  const [latestNewsflash, setLatestNewsflash] = useState(null);
  useEffect(() => {
    const db = getFirestore();
    const q = query(
      collection(db, 'newsflashes'),
      where('published', '==', true),
      where('organizations', 'array-contains', organization.id),
      orderBy('timeCreated', 'desc'),
      limit(1)
    );
    const listener = onSnapshot(
      q,
      (snapshot) => {
        const firstDoc = idx(snapshot, (_) => _.docs[0]);
        if (!firstDoc) {
          setLatestNewsflash(null);
          return;
        }
        setLatestNewsflash(firstDoc.data());
      },
      (err) => {
        console.error(err);
      }
    );

    return () => {
      listener();
    };
  }, []);

  const [organizations, setOrganizations] = useState([]);
  useEffect(() => {
    if (!latestNewsflash) return;
    const getOrganizations = async () => {
      const db = getFirestore();
      const tempOrganizations = [];
      const promises = latestNewsflash.organizations.map((e) => {
        return new Promise((resolve, reject) => {
          getDoc(doc(db, 'organizations', e))
            .then((res) => {
              if (!res.exists) resolve();
              const orgName = idx(res, (_) => _.data().name);
              if (!orgName) resolve();
              tempOrganizations.push(orgName);
              resolve();
            })
            .catch((err) => {
              resolve();
            });
        });
      });
      await Promise.all(promises);
      setOrganizations(tempOrganizations);
    };
    getOrganizations();
  }, [latestNewsflash]);

  if (!latestNewsflash) {
    return (
      <div>
        <h4>{organization.name}</h4>
        <EmptyScreen title={'Ingen newsflash här.'} />
      </div>
    );
  }

  return (
    <div className="newsflashWrapperShowcase">
      <h4>{organization.name}</h4>
      <div className="newsflashWrapper">
        <label>
          {getRelativeDateTime(idx(latestNewsflash, (_) => _.timeCreated))}
        </label>
        <div className="titleWrapper">
          <h5>
            {idx(latestNewsflash, (_) => _.preTitle) ? (
              <span className="preTitle">
                <strong>{idx(latestNewsflash, (_) => _.preTitle)}</strong>
              </span>
            ) : null}
            <span>{idx(latestNewsflash, (_) => _.title)}</span>
          </h5>
        </div>
        {idx(latestNewsflash, (_) => _.subTitle) ? (
          <p className="faded subTitle">
            {idx(latestNewsflash, (_) => _.subTitle)}
          </p>
        ) : null}
        {idx(latestNewsflash, (_) => _.content) ? (
          <p className="content">{idx(latestNewsflash, (_) => _.content)}</p>
        ) : null}
        {idx(latestNewsflash, (_) => _.link) ? (
          <a
            href={idx(latestNewsflash, (_) => _.link)}
            target="_blank"
            rel="noreferrer"
          >
            <HyperLink target={'Läs mer'} />
          </a>
        ) : null}
      </div>
      <div className="newsflashMeta faded">
        <p>
          <span className="faded">Publicerad: </span>
          {getHumanDateTime(idx(latestNewsflash, (_) => _.timeCreated))}
        </p>
        <p>
          <span className="faded">Organisationer: </span> <br />
          {organizations.map((e) => {
            return (
              <>
                {e}
                <br />
              </>
            );
          })}
        </p>
      </div>
      <div className="buttonrow">
        <button
          onClick={() => {
            setEditObject(latestNewsflash);
          }}
        >
          <img src={Edit} className="doinvert" alt="" />
          Redigera
        </button>
      </div>
    </div>
  );
};

export default OrganizationNewsflashWrapper;
