import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import { animated, useSpring } from 'react-spring';
import {
  getDiceBearAvatar,
  removeFromURL,
  uploadSingleBlob,
} from '../../functions';
import Backbutton from '../global/Backbutton';
import { useState, useEffect, useRef } from 'react';

import Dropzone from 'react-dropzone';

import Checkmark from '../../assets/checkmark.svg';
import Image from '../../assets/image.svg';
import {
  arrayRemove,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  updateDoc,
} from '@firebase/firestore';
import { getAuth } from '@firebase/auth';
import idx from 'idx';
import { toast } from 'react-toastify';
import { AsYouType } from 'libphonenumber-js';

const EditUserPopup = ({ showingSelf }) => {
  const [avatar, setAvatar] = useState('');
  const avatarEditorRef = useRef(null);
  const [avatarScale, setAvatarScale] = useState(100);
  const [avatarRotation, setAvatarRotation] = useState(0);
  const handleDrop = (e) => {
    setAvatar(e[0]);
  };

  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [jobTitle, setJobTitle] = useState('');

  useEffect(() => {
    const db = getFirestore();
    const auth = getAuth();
    const uid = auth.currentUser.uid;
    getDoc(doc(db, 'users', uid))
      .then((snapshot) => {
        if (!snapshot.exists) {
          showingSelf(false);
        }
        const userData = snapshot.data();
        setUser(userData);
      })
      .catch((err) => {
        console.error(err);
        showingSelf(false);
      });
  }, []);

  useEffect(() => {
    if (!user) return;
    setFirstName(idx(user, (_) => _.name.first) || '');
    setLastName(idx(user, (_) => _.name.last) || '');
    setPhoneNumber(idx(user, (_) => _.phone) || '');
    setJobTitle(idx(user, (_) => _.jobTitle) || '');
    setAvatar(
      idx(user, (_) => _.photoURL.w256) ||
        getDiceBearAvatar(idx(user, (_) => _.uid))
    );
  }, [user]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const submit = () => {
    if (
      firstName === '' ||
      lastName === '' ||
      phoneNumber === '' ||
      jobTitle === '' ||
      isSubmitting
    ) {
      toast.error('Inget av fälten får vara tomma.');
      return;
    }
    setIsSubmitting(true);
    const avatarPromise = new Promise((resolve, reject) => {
      if (typeof avatar === 'object') {
        const canvas = avatarEditorRef.current.getImage();
        const jpg = canvas.toDataURL('image/jpeg');
        const path = `${idx(user, (_) => _.uid)}/avatar`;
        const fileName = 'avatar.jpg';
        const promise = uploadSingleBlob(jpg, path, fileName)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });

        toast.promise(promise, {
          pending: 'Laddar upp profilbild. Vänligen lämna inte sidan.',
          error: 'Någonting gick fel. Vänligen försök igen senare.',
          success:
            'Uppladdningen av profilbilden lyckades! Det kan dröja några minuter innan den syns i portalen medan den bearbetas.',
        });
      } else {
        resolve();
      }
    });
    const db = getFirestore();
    const uid = getAuth().currentUser.uid;
    const documentPromise = updateDoc(doc(db, 'users', uid), {
      'name.first': firstName,
      'name.last': lastName,
      phone: phoneNumber,
      jobTitle: jobTitle,
    });
    toast.promise(documentPromise, {
      pending: 'Sparar ändringar. Vänligen lämna inte sidan.',
      error: 'Någonting gick fel. Vänligen försök igen senare.',
      success: 'Lyckades! Din profil är nu uppdaterad.',
    });

    Promise.all([avatarPromise, documentPromise])
      .then(() => {
        showingSelf(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: '100%', maxHeight: '100%' },
    from: { opacity: 0, x: 16, maxWidth: '100%', maxHeight: '100%' },
    config: { friction: 20, tension: 400 },
  });
  return (
    <div className="modal blur">
      <animated.div style={springprops}>
        <div className="card small">
          <div>
            <Backbutton
              normal={'Stäng'}
              target={'Dashboard'}
              onClick={() => {
                showingSelf(false);
                removeFromURL('notifications');
              }}
            />
          </div>
          <label>Profilbild</label>
          <div className="editAvatarWrapper">
            <Dropzone onDrop={handleDrop} noClick>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <AvatarEditor
                    ref={avatarEditorRef}
                    width={128}
                    height={128}
                    border={0}
                    color={[0, 0, 0, 0.9]}
                    image={avatar}
                    scale={avatarScale / 100}
                    rotate={avatarRotation}
                    className="avatarEditor"
                  />
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
            <div className="valueEditors">
              <label>Skala ({avatarScale}%)</label>
              <input
                type="range"
                min={100}
                max={300}
                value={avatarScale}
                onChange={(e) => setAvatarScale(e.target.value)}
              />
              <label>Rotation ({avatarRotation}°)</label>
              <input
                type="range"
                min={0}
                max={360}
                value={avatarRotation}
                onChange={(e) => setAvatarRotation(e.target.value)}
              />
              <Dropzone onDrop={handleDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <button
                      style={{
                        width: '100%',
                        marginTop: '1rem',
                      }}
                    >
                      <img src={Image} className="doinvert" alt="" />
                      Välj bild
                    </button>
                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
          <form onSubmit={submit}>
            <label>Förnamn</label>
            <input
              type="text"
              placeholder="Förnamn"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <label>Efternamn</label>
            <input
              type="text"
              placeholder="Förnamn"
              value={lastName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <label>Telefonnummer</label>
            <input
              type="text"
              placeholder="Telefonnummer"
              value={new AsYouType('SE').input(phoneNumber)}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <label>Jobbtitel</label>
            <input
              type="text"
              placeholder="Jobbtitel"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
            <div></div>
            <button type="button" onClick={submit}>
              <img src={Checkmark} className="doinvert" alt="" />
              Spara ändringar
            </button>
          </form>
          <NotificationLinkRequester />
        </div>
      </animated.div>
    </div>
  );
};

export default EditUserPopup;

const NotificationLinkRequester = () => {
  const [user, setUser] = useState(null);
  const [accessLink, setAccessLink] = useState('');
  useEffect(() => {
    const db = getFirestore();
    const auth = getAuth();

    const listener = onSnapshot(
      doc(db, 'users', auth.currentUser.uid),
      (snapshot) => {
        if (snapshot.exists()) {
          setUser(snapshot.data());
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return () => {
      listener();
    };
  }, []);

  useEffect(() => {
    setAccessLink(idx(user, (_) => _.iOSAccessLink));
  }, [user]);

  const [isRetrievingAccessLink, setIsRetrievingAccessLink] = useState(false);
  const getAccessLink = async () => {
    if (isRetrievingAccessLink) return;
    setIsRetrievingAccessLink(true);

    const db = getFirestore();
    const auth = getAuth();

    const promise = getDoc(doc(db, 'meta', 'iOS_APP_KEYS'))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const key = snapshot.data().keys[0];
          updateDoc(snapshot.ref, {
            keys: arrayRemove(key),
          })
            .then(() => {
              updateDoc(doc(db, 'users', auth.currentUser.uid), {
                iOSAccessLink: key,
              });
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });

    toast.promise(promise, {
      pending: 'Hämtar åtkomstlänk... Vänligen lämna inte sidan.',
      error: 'Någonting gick fel! Vänligen försök igen senare.',
      success:
        'Länk hämtad! Nu kan du använda den för att hämta notifikationsappen på iOS.',
    });

    await promise;
    setIsRetrievingAccessLink(false);
  };

  const qrLink = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${accessLink}`;

  return (
    <div className="notificationLinkRequester">
      <label>Begär åtkomstlänk till notifikationsapp (iOS)</label>
      {accessLink ? (
        <p>
          Länk: <a href={accessLink}>{accessLink}</a>
        </p>
      ) : (
        <p>Ingen åtkomstlänk för denna användare</p>
      )}
      {accessLink ? (
        <a
          className="getLinkButton"
          href={qrLink}
          target="_blank"
          referrerPolicy="no-referrer"
          rel="noreferrer"
        >
          Öppna som QR-kod
        </a>
      ) : null}
      <p className="getLinkButton" onClick={getAccessLink}>
        Skapa åtkomstlänk
      </p>
    </div>
  );
};
