import idx from "idx";
import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { toast } from "react-toastify";
import { getUserPermissionsFromIDS, updateUser } from "../../../functions";
import Backbutton from "../../global/Backbutton";

import CheckMark from "../../../assets/checkmark.svg";

const EditUserForm = ({ organization, user, setEditing, userPermissions }) => {
  const [usertype, setUsertype] = useState("DISALLOWED");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (user == null) return;

    getUserPermissionsFromIDS(user.uid, organization.id)
      .then((res) => {
        setUsertype(res);
      })
      .catch((err) => {
        console.log({ err });
      });
    setFirstName(idx(user, (_) => _.name.first));
    setLastName(idx(user, (_) => _.name.last));
    setJobTitle(idx(user, (_) => _.jobTitle));
    setPhoneNumber(idx(user, (_) => _.phone));
  }, [user, organization]);

  const submit = (e) => {
    e.preventDefault();

    if (usertype === "" || usertype === "DISALLOWED") {
      toast.error("Ange användarens typ");
      return;
    }
    if (usertype === "superadmin" && organization.id !== "INTERNAL") {
      toast.error("Denna användartyp är inte tillåten inom kundorganisationer");
      return;
    }
    if (firstName === "") {
      toast.error("Ange användarens förnamn");
      return;
    }
    if (lastName === "") {
      toast.error("Ange användarens efternamn");
      return;
    }
    if (jobTitle === "") {
      toast.error("Ange användarens jobbtitel");
      return;
    }
    if (phoneNumber === "") {
      toast.error("Ange användarens telefonnummer");
      return;
    }

    updateUser(
      firstName,
      lastName,
      usertype,
      jobTitle,
      phoneNumber,
      user.uid,
      user.organization,
      (callback) => {
        if (callback) {
          setEditing(false);
        }
      }
    );
  };

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <animated.div style={springprops}>
      <Backbutton
        target={"Användarinformation"}
        onClick={() => setEditing(false)}
      />
      <h2>Redigera användare</h2>
      <form>
        <label htmlFor="usertype">Typ av användare</label>
        <select
          value={usertype}
          onChange={(e) => setUsertype(e.target.value)}
          id="usertype"
        >
          <option value="DISALLOWED" selected disabled>
            Välj
          </option>
          <option value="client">Kund</option>
          <option value="admin">Admin</option>
          <option value="assistant">Assistent</option>
          {userPermissions === "superadmin" &&
          organization.id === "INTERNAL" ? (
            <option value="superadmin">Superadmin</option>
          ) : null}
        </select>
        <label htmlFor="first-name">Förnamn</label>
        <input
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          id="first-name"
          placeholder="Förnamn"
        />
        <label htmlFor="last-name">Efternamn</label>
        <input
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          id="last-name"
          placeholder="Efternamn"
        />
        <label htmlFor="job-title">Jobbtitel</label>
        <input
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
          id="job-title"
          placeholder="Jobbtitel"
        />
        <label htmlFor="phone">Telefonnummer</label>
        <input
          type="tel"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          id="phone"
          placeholder="Telefonnummer"
        />
        <div></div>
        <button onClick={submit} type="button">
          <img src={CheckMark} className="doinvert" alt="" />
          Spara ändringar
        </button>
      </form>
    </animated.div>
  );
};

export default EditUserForm;
