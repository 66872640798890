import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import {
  addToURL,
  getUserPermissions,
  removeFromURL,
} from "../../../functions";

// components:
import Backbutton from "../../global/Backbutton";
import OrganizationList from "./OrganizationList";

// assets:
import Plus from "../../../assets/plus.svg";
import AddUser from "../../../assets/adduser.svg";
import Edit from "../../../assets/edit.svg";

const HandleUsers = ({ db }) => {
  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    getUserPermissions((callback) => {
      setPermissions(callback);
    });
  }, []);

  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const listener = onSnapshot(collection(db, "organizations"), (snapshot) => {
      const tempOrganizations = [];
      snapshot.forEach((doc) => {
        tempOrganizations.push(doc.data());
      });
      tempOrganizations.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      setOrganizations(tempOrganizations);
    });

    return () => {
      listener();
    };
  }, []);

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <animated.div style={springprops}>
      <div className="createbooking board">
        <Backbutton
          target={"Dashboard"}
          onClick={() => removeFromURL("users")}
        />
        <h1 className="title">Hantera användare</h1>
        <p className="subtitle">
          Lägg till, ta bort, eller ändra användare och organisationer. <br />
          Klicka på en organisation för att se dess användare.
        </p>
        <div className="buttonrow">
          <button onClick={() => addToURL("adduser")}>
            <img src={AddUser} className="doinvert" alt="" />
            Lägg till användare
          </button>
          <button onClick={() => addToURL("addorganization")}>
            <img src={Plus} className="doinvert" alt="" />
            Lägg till organisation
          </button>
          {permissions === "superadmin" ? (
            <button onClick={() => addToURL("editmeta")}>
              <img src={Edit} className="doinvert" alt="" />
              Redigera metadata
            </button>
          ) : null}
        </div>
        <OrganizationList organizations={organizations} />
      </div>
    </animated.div>
  );
};

export default HandleUsers;
