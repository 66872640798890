import React from "react";
import { animated, useSpring } from "react-spring";
import { removeFromURL } from "../../../functions";

// components:
import Backbutton from "../../global/Backbutton";
import BookingForm from "./BookingForm";
import idx from "idx";

// assets:

const CreateBooking = ({ user, organization }) => {
  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <animated.div style={springprops}>
      <div className="createbooking board">
        <Backbutton
          target={"Dashboard"}
          onClick={() => removeFromURL("newbooking")}
        />
        {idx(organization, (_) => _.avatars.size2x1) ? (
          <img
            src={idx(organization, (_) => _.avatars.size2x1)}
            className="logo"
            alt=""
          />
        ) : null}
        <h1 className="title">Ny bokning</h1>
        <p className="subtitle">Skapa en bokning för fastighetsfotografering</p>
        <BookingForm user={user} organization={organization} />
      </div>
    </animated.div>
  );
};

export default React.memo(CreateBooking);
