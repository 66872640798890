import { collection, getFirestore, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { getFullname } from '../../../functions';
import { v4 as uuidv4 } from 'uuid';

// components:
import AutocompleteInput from '../../global/AutocompleteInput';
import Backbutton from '../../global/Backbutton';
import RadioGroup from '../../global/RadioGroup';
import SelectionPillGroup from '../../global/SelectionPillGroup';
import idx from 'idx';
import CheckboxGroup from '../../global/CheckboxGroup';

// assets:
import CheckMark from '../../../assets/checkmark.svg';
import Clear from '../../../assets/xmark.svg';

const SortingOptionsAdmin = ({
  config,
  setConfig,
  configDesc,
  setConfigDesc,
  setShowingSelf,
}) => {
  const [taxonomyType, setTaxonomyType] = useState('');
  const taxonomyTypeOptions = [
    {
      label: 'Organisation',
      value: 'ORGANIZATION',
    },
    {
      label: 'Kund',
      value: 'CLIENT',
    },
  ];

  const sortByOptions = [
    {
      label: 'Nyaste',
      value: 'NEWEST',
    },
    {
      label: 'Äldsta',
      value: 'OLDEST',
    },
    {
      label: 'Deadline',
      value: 'DEADLINE',
    },
    {
      label: 'Bokningsdatum',
      value: 'BOOKING',
    },
  ];
  const [sortBy, setSortBy] = useState(sortByOptions[0].value);

  const showStatusesRows = [
    {
      label: 'Obokade',
      value: 'NOT_BOOKED',
    },
    {
      label: 'Bokade',
      value: 'BOOKED',
    },
    {
      label: 'Avbokade',
      value: 'CANCELED',
    },
    {
      label: 'Levererade',
      value: 'DELIVERED',
    },
  ];
  const [showStatuses, setShowStatuses] = useState(() => {
    var tempRes = [
      showStatusesRows[0].value,
      showStatusesRows[1].value,
      showStatusesRows[2].value,
    ];
    return tempRes;
  });

  const [autocompleteKey, setAutocompleteKey] = useState(uuidv4());

  const [organizations, setOrganizations] = useState([]);
  const [fetchOrganizations, setFetchOrganizations] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [organizationInputValue, setOrganizationInputValue] = useState(null);

  const [clients, setClients] = useState([]);
  const [fetchClients, setFetchClients] = useState(false);
  const [client, setClient] = useState(null);
  const [clientInputValue, setClientInputValue] = useState(null);

  useEffect(() => {
    setAutocompleteKey(uuidv4());
  }, [organizations, clients]);

  useEffect(() => {
    switch (taxonomyType) {
      case 'ORGANIZATION': {
        setClient(null);
        if (!fetchOrganizations) {
          setFetchOrganizations(true);
        }
        break;
      }
      case 'CLIENT': {
        setOrganization(null);
        if (!fetchClients) {
          setFetchClients(true);
        }
        break;
      }
      default:
        break;
    }
  }, [taxonomyType]);

  useEffect(() => {
    if (!fetchOrganizations) return;
    const db = getFirestore();
    const listener = onSnapshot(collection(db, 'organizations'), (snapshot) => {
      var tempOrganizations = [];
      snapshot.forEach((org) => {
        tempOrganizations.push(org.data());
      });
      setOrganizations(tempOrganizations);
    });

    return () => {
      listener();
    };
  }, [fetchOrganizations]);

  useEffect(() => {
    if (!fetchClients) return;
    const db = getFirestore();
    const listener = onSnapshot(collection(db, 'users'), (snapshot) => {
      var tempClients = [];
      snapshot.forEach((cli) => {
        let data = cli.data();
        data.fullName = getFullname(data);
        tempClients.push(data);
      });
      setClients(tempClients);
    });

    return () => {
      listener();
    };
  }, [fetchClients]);

  const returnSorting = () => {
    var wheres = [];
    var wheresDesc = {};
    wheres.push(['status', 'in', showStatuses]);
    if (organization != null) {
      wheres.push(['organizationID', '==', organization]);

      let orgName = '';
      organizations.forEach((it) => {
        if (it.id === organization) {
          orgName = it.name;
        }
      });
      wheresDesc = {
        key: 'ORGANIZATION',
        prefix: 'organisation: ',
        name: orgName,
        id: organization,
      };
    }
    if (client != null) {
      wheres.push(['uid', '==', client]);

      let clientName = '';
      clients.forEach((it) => {
        if (it.uid === client) {
          clientName = getFullname(it);
        }
      });
      wheresDesc = {
        key: 'CLIENT',
        prefix: 'kund: ',
        name: clientName,
        id: client,
      };
    }
    wheresDesc.showStatuses = showStatuses;
    var showStatusesLabels = [];
    showStatuses.forEach((it) => {
      showStatusesRows.forEach((row) => {
        if (row.value === it) {
          showStatusesLabels.push(row.label);
        }
      });
    });
    wheresDesc.showStatusesLabels = showStatusesLabels;
    var order = [];
    var ordersDesc = {};
    switch (sortBy) {
      case 'OLDEST':
        order.push(['timecreated', 'asc']);
        ordersDesc = {
          key: sortBy,
          name: 'Äldsta',
        };
        break;
      case 'DEADLINE':
        order.push(['deadline', 'desc']);
        ordersDesc = {
          key: sortBy,
          name: 'Deadline',
        };
        break;
      case 'BOOKING':
        order.push(['bookedtime', 'desc']);
        ordersDesc = {
          key: sortBy,
          name: 'Bokningsdatum',
        };
        break;
      default:
        // order by newest:
        order.push(['timecreated', 'desc']);
        ordersDesc = {
          key: sortBy,
          name: 'Nyaste',
        };
        break;
    }
    setConfig({
      wheres: wheres,
      order: order,
    });
    setConfigDesc({
      wheres: wheresDesc,
      order: ordersDesc,
    });

    setShowingSelf(false);
  };

  useEffect(() => {
    let where = idx(configDesc, (_) => _.wheres);
    if (where) {
      if (where.key === 'ORGANIZATION') {
        setTaxonomyType(where.key);
        setOrganization(where.id);
        setOrganizationInputValue(where.name);
      }
      if (where.key === 'CLIENT') {
        setTaxonomyType(where.key);
        setClient(where.id);
        setClientInputValue(where.name);
      }
      setShowStatuses(where.showStatuses);
    }

    let order = idx(configDesc, (_) => _.order);
    if (order) {
      setSortBy(order.key);
    }
  }, [configDesc]);

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: '100%', maxHeight: '100%' },
    from: { opacity: 0, x: 16, maxWidth: '100%', maxHeight: '100%' },
    config: { friction: 20, tension: 400 },
  });
  return (
    <div className="modal blur">
      <animated.div style={springprops}>
        <div className="card medium">
          <Backbutton
            normal={'Stäng'}
            target={'Bokningar'}
            onClick={() => setShowingSelf(false)}
          />
          <h2>Filtreringsinställningar</h2>
          <form onSubmit={(e) => e.preventDefault()}>
            <label htmlFor="taxonomytype">Vad vill du filtrera efter?</label>
            <RadioGroup
              rows={taxonomyTypeOptions}
              id={'taxonomytype'}
              state={taxonomyType}
              setState={setTaxonomyType}
            />
            {taxonomyType === 'ORGANIZATION' ? (
              <>
                <label htmlFor="organization" className="required">
                  Välj organisation
                </label>
                <AutocompleteInput
                  id={'organization'}
                  placeholder={'Välj organisation'}
                  options={organizations}
                  displaydict={'name'}
                  resdict={'id'}
                  state={organization}
                  setState={setOrganization}
                  key={autocompleteKey}
                  defaultValue={organizationInputValue}
                />
              </>
            ) : null}
            {taxonomyType === 'CLIENT' ? (
              <>
                <label htmlFor="client" className="required">
                  Välj kund
                </label>
                <AutocompleteInput
                  id={'client'}
                  placeholder={'Välj kund'}
                  options={clients}
                  displaydict={'fullName'}
                  resdict={'uid'}
                  state={client}
                  setState={setClient}
                  key={autocompleteKey}
                  defaultValue={clientInputValue}
                />
              </>
            ) : null}
            <label>Sortera efter:</label>
            <SelectionPillGroup
              options={sortByOptions}
              state={sortBy}
              setState={setSortBy}
            />
            <label>Visa:</label>
            <CheckboxGroup
              rows={showStatusesRows}
              state={showStatuses}
              setState={setShowStatuses}
              minchecked={1}
            />
            <div className="buttonrow">
              <button onClick={() => returnSorting()} type="button">
                <img src={CheckMark} className="doinvert" alt="" />
                Sätt filtrering
              </button>
              <button
                onClick={() => {
                  setShowingSelf(false);
                  setConfig(null);
                  setConfigDesc({});
                }}
                className="destructive"
                type="button"
              >
                <img src={Clear} className="doinvert" alt="" />
                Rensa filtrering
              </button>
            </div>
          </form>
        </div>
      </animated.div>
    </div>
  );
};

export default React.memo(SortingOptionsAdmin);
