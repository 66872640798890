import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";
import idx from "idx";
import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { getURLComponents, getURLParams } from "../../../functions";

// components:
import EditUserForm from "./EditUserForm";
import UserInfo from "./UserInfo";

const HandleUser = ({ userPermissions }) => {
  const [editing, setEditing] = useState(false);

  const [uid, setUid] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    let components = getURLComponents();
    components.forEach((comp) => {
      if (comp.includes("?") && comp.includes("uid")) {
        comp = comp.split("?")[1];
        const params = getURLParams(comp);
        setUid(idx(params, (_) => _.uid));
      }
    });
  }, []);

  useEffect(() => {
    if (uid == null || uid === "") return;

    const db = getFirestore();

    onSnapshot(doc(db, "users", uid), (snapshot) => {
      const data = snapshot.data();
      if (data) {
        setUser(data);
      }
    });
  }, [uid]);

  const [organizationID, setOrganizationID] = useState("");
  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    let components = getURLComponents();
    components.forEach((comp) => {
      if (comp.includes("?") && comp.includes("orgid")) {
        comp = comp.split("?")[1];
        const params = getURLParams(comp);
        setOrganizationID(idx(params, (_) => _.orgid));
      }
    });
  }, []);

  useEffect(() => {
    if (organizationID === "" || organizationID == null) return;

    const db = getFirestore();

    getDoc(doc(db, "organizations", organizationID)).then((doc) => {
      setOrganization(doc.data());
    });
  }, [organizationID]);

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <div className="modal blur">
      <animated.div style={springprops}>
        <div className="card medium">
          {editing ? (
            <EditUserForm
              organization={organization}
              user={user}
              setEditing={setEditing}
              userPermissions={userPermissions}
            />
          ) : (
            <UserInfo
              organization={organization}
              user={user}
              setEditing={setEditing}
            />
          )}
        </div>
      </animated.div>
    </div>
  );
};

export default React.memo(HandleUser);
