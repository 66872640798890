import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { toast } from 'react-toastify';
import { removeFromURL } from '../../functions';
import Backbutton from '../global/Backbutton';

const ResetPass = () => {
  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);

  const resetPass = (e) => {
    e.preventDefault();

    var invalid = false;

    if (email === '') {
      setEmailInvalid(true);
      invalid = true;
    }

    if (invalid) return;

    const auth = getAuth();
    const promise = sendPasswordResetEmail(auth, email)
      .then(() => {
        removeFromURL('resetpass');
      })
      .catch((err) => {
        console.error({ err });
      });

    toast.promise(promise, {
      pending:
        'Skickar mejl med återställningslänk för lösenord. Vänligen lämna inte sidan.',
      success:
        'Mejlet skickades utan problem! Kolla skräpposten om ni inte ser det direkt.',
      error: 'Något gick snett! Vänligen försök igen senare.',
    });
  };

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: '100%', maxHeight: '100%' },
    from: { opacity: 0, x: 16, maxWidth: '100%', maxHeight: '100%' },
    config: { friction: 20, tension: 400 },
  });
  return (
    <div className="modal">
      <animated.div style={springprops}>
        <div className="card">
          <Backbutton
            target={'Logga in'}
            onClick={() => removeFromURL('resetpass')}
          />
          <h2
            style={{
              marginBottom: '1.25rem',
            }}
          >
            Återställ lösenord
          </h2>
          <form onSubmit={resetPass}>
            <input
              className={`${emailInvalid ? 'invalid' : ''}`}
              type="email"
              placeholder="E-post"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailInvalid(false);
              }}
            />
            <div></div>
            <button onClick={resetPass}>Skicka återställningslänk</button>
          </form>
        </div>
      </animated.div>
    </div>
  );
};

export default ResetPass;
