import idx from 'idx';
import React, { useState, useEffect } from 'react';
import {
  formatPhoneNumber,
  getDiceBearAvatar,
  getFullname,
  getHumanDate,
  getHumanDateTime,
  getOrganization,
  getStatus,
  getUser,
  getUserPermissions,
  statusOptions,
} from '../../../functions';
import { animated, useSpring } from 'react-spring';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { toast } from 'react-toastify';

// components:
import Backbutton from '../../global/Backbutton';
import FileWrapper from './FileWrapper';
import Modal from '../../global/Modal';
import StatusTimeline from './wrappers/StatusTimeline';

// assets:
import EstateType from '../../../assets/estatetype.svg';
import PropertySize from '../../../assets/propertysize.svg';
import Services from '../../../assets/services.svg';
import EditingStyle from '../../../assets/editingstyle.svg';
import Name from '../../../assets/name.svg';
import Email from '../../../assets/email.svg';
import Phone from '../../../assets/phone.svg';
import Dropbox from '../../../assets/dropbox.svg';
import GoogleMaps from '../../../assets/googlemaps.svg';
import Discussion from '../../../assets/discussion.svg';
import Edit from '../../../assets/edit.svg';
import Clear from '../../../assets/xmark.svg';

const BookingDetails = ({
  booking,
  backaction,
  setEditing,
  setShowingDiscussion,
}) => {
  const [booker, setBooker] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [files, setFiles] = useState(null);
  const [userPermissions, setUserPermissions] = useState('');
  useEffect(() => {
    getUserPermissions((callback) => {
      setUserPermissions(callback);
    });
  }, []);

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const confirmDeletePhrase =
    idx(booking, (_) => _.address.address) || 'Ta bort';
  const [confirmDeleteValue, setConfirmDeleteValue] = useState('');

  useEffect(() => {
    getUser(idx(booking, (_) => _.uid)).then((user) => setBooker(user));
    getOrganization(
      idx(booking, (_) => _.organizationID),
      (org) => {
        setOrganization(org);
      }
    );
    const functions = getFunctions();
    const listFiles = httpsCallable(functions, 'listFilesFromDropbox');
    const bookingDropboxPath = idx(booking, (_) => _.dropbox.path);
    if (bookingDropboxPath == null) return;
    const folderPath = `${bookingDropboxPath}/bifogade filer`;
    listFiles({ folderPath: folderPath })
      .then((result) => {
        setFiles(idx(result, (_) => _.data.entries) || []);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [booking]);

  const formattedPhoneNumber = formatPhoneNumber(
    idx(booking, (_) => _.sellerphone)
  );

  const deleteBooking = (e) => {
    e.preventDefault();
    if (
      idx(confirmDeleteValue, (_) => _.toLowerCase()) ===
      idx(confirmDeletePhrase, (_) => _.toLowerCase())
    ) {
      const functions = getFunctions();
      const deleteBooking = httpsCallable(functions, 'deleteBooking');
      const promise = deleteBooking({ booking: booking })
        .then(() => {
          backaction();
        })
        .catch((err) => {
          console.error(err);
        });
      toast.promise(promise, {
        pending: 'Tar bort bokning...',
        error: 'Någonting gick fel! Vänligen försök igen senare.',
        success: 'Bokningen togs bort utan problem.',
      });
      return;
    }
    toast.error('Bekräftelsefrasen stämde inte överens');
  };

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: '100%', maxHeight: '100%' },
    from: { opacity: 0, x: 16, maxWidth: '100%', maxHeight: '100%' },
    config: { friction: 20, tension: 400 },
  });

  return (
    <animated.div style={springprops}>
      <div className="bookingdetails">
        <Backbutton
          normal={'Stäng'}
          target={'Se bokningar'}
          onClick={backaction}
        />
        <h2
          style={{
            marginBottom: '1.25rem',
          }}
          id={idx(booking, (_) => _.id)}
        >
          {idx(booking, (_) => _.address.address)},{' '}
          {idx(booking, (_) => _.address.postalcode)},{' '}
          {idx(booking, (_) => _.address.city)}
        </h2>
        <div className="infoGrid">
          <div className="infoPill" tooltip="Typ av fastighet">
            <img src={EstateType} className="doinvert" alt="" />
            <small>{getEstateType(booking)}</small>
          </div>
          <div className="infoPill" tooltip="Storlek på fastigheten">
            <img src={PropertySize} className="doinvert" alt="" />
            <small>{getPropertySize(booking)}m²</small>
          </div>
          <div className="infoPill" tooltip="Tjänster">
            <img src={Services} className="doinvert" alt="" />
            <small>{getServices(booking)}</small>
          </div>
          <div className="infoPill" tooltip="Redigeringsstil">
            <img src={EditingStyle} className="doinvert" alt="" />
            <small>{getEditingStyle(booking)}</small>
          </div>
        </div>
        <div className="bookingMeta">
          <label>Bokat fotograferingstillfälle</label>
          <p>
            {idx(booking, (_) => _.bookedtime)
              ? getHumanDateTime(booking.bookedtime)
              : 'Ingen bokad tid'}
          </p>
          <label>Deadline</label>
          <p>
            {idx(booking, (_) => _.deadline)
              ? getHumanDate(booking.deadline)
              : 'Ingen deadline satt'}
          </p>
          <label>Bokat av</label>
          <p>
            {getFullname(booker)}
            <img
              src={
                idx(booker, (_) => _.photoURL.w48) ||
                getDiceBearAvatar(idx(booker, (_) => _.uid))
              }
              className="db_avatar"
              alt=""
              id={idx(booker, (_) => _.uid)}
            />{' '}
            på{' '}
            <span id={idx(organization, (_) => _.id)}>
              {idx(organization, (_) => _.name)}
            </span>
          </p>
          <label>Information om säljaren</label>
          <div className="infoGrid">
            <div className="infoPill" tooltip="Namn">
              <img src={Name} className="doinvert" alt="" />
              <p>{idx(booking, (_) => _.sellername) || 'Okänt namn'}</p>
            </div>
            <div className="infoPill" tooltip="E-postadress">
              <img src={Email} className="doinvert" alt="" />
              {idx(booking, (_) => _.selleremail) ? (
                <a
                  className="forcepointerevents"
                  href={`mailto:${idx(booking, (_) => _.selleremail)}`}
                >
                  <p>{idx(booking, (_) => _.selleremail)}</p>
                </a>
              ) : (
                <p>Okänd e-postadress</p>
              )}
            </div>
            <div className="infoPill" tooltip="Telefonnummer">
              <img src={Phone} className="doinvert" alt="" />
              {formattedPhoneNumber ? (
                <a
                  className="forcepointerevents"
                  href={`${idx(formattedPhoneNumber, (_) => _.URI)}`}
                >
                  <p>{idx(formattedPhoneNumber, (_) => _.formatted)}</p>
                </a>
              ) : (
                <p>Okänt telefonnummer</p>
              )}
            </div>
          </div>
          <label>Instruktioner</label>
          <p>{idx(booking, (_) => _.instructions) || 'Inga instruktioner'}</p>
          <label>Övrigt</label>
          <p>{idx(booking, (_) => _.other) || 'Inget övrigt'}</p>
          <label>Bokning skapad</label>
          <p>{getHumanDateTime(idx(booking, (_) => _.timecreated))}</p>
          <label>Bifogade filer</label>
          {files == null ? (
            <p>Laddar filer...</p>
          ) : files.length === 0 ? (
            <p>Inga bifogade filer</p>
          ) : (
            <div className="filelist">
              {files.map((file) => {
                return <FileWrapper file={file} />;
              })}
            </div>
          )}
          <label>Status</label>
          <StatusTimeline booking={booking} userPermissions={userPermissions} />
          {booking ? (
            <div className="iconButtonRow">
              <div
                onClick={() => setShowingDiscussion((p) => !p)}
                tooltip="Visa diskussion"
              >
                <img src={Discussion} className="doinvert" alt="" />
              </div>
              {idx(booking, (_) => _.dropbox.result) ? (
                <a
                  href={booking.dropbox.result}
                  target="_blank"
                  rel="noreferrer"
                  tooltip="Visa färdiga bilder"
                >
                  <img src={Dropbox} alt="" />
                </a>
              ) : null}
              <a
                href={constructGoogleMapsLink(booking)}
                target="_blank"
                rel="noreferrer"
                tooltip="Visa på Google Maps"
              >
                <img src={GoogleMaps} alt="" />
              </a>
              <div onClick={() => setEditing(true)} tooltip="Redigera">
                <img src={Edit} className="doinvert" alt="" />
              </div>
              {userPermissions === 'superadmin' ? (
                <div
                  className="destructive"
                  onClick={() => setIsDeleteConfirmationOpen(true)}
                  tooltip="Radera bokning"
                >
                  <img src={Clear} className="doinvert" alt="" />
                  {isDeleteConfirmationOpen ? (
                    <Modal
                      isOpen={isDeleteConfirmationOpen}
                      setIsOpen={setIsDeleteConfirmationOpen}
                      backNormal={'Avbryt'}
                      backTarget={'Bokningsdetaljer'}
                    >
                      <h2
                        style={{
                          marginBottom: '1.25rem',
                        }}
                      >
                        Ta bort bokning?
                      </h2>
                      <form className="fullwidth">
                        <label>
                          Skriv "{confirmDeletePhrase}" för att bekräfta
                        </label>
                        <input
                          type="text"
                          placeholder="Skriv här"
                          value={confirmDeleteValue}
                          onChange={(e) =>
                            setConfirmDeleteValue(e.target.value)
                          }
                        />
                        <button className="destructive" onClick={deleteBooking}>
                          Ta bort
                        </button>
                      </form>
                    </Modal>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </animated.div>
  );
};

export default React.memo(BookingDetails);

const constructGoogleMapsLink = (booking) => {
  let base = 'https://www.google.com/maps/search/?api=1&query=';
  const address = idx(booking, (_) => _.address);

  if (!address) return base;
  let query = encodeURI(
    `${booking.address.address} ${booking.address.postalcode} ${booking.address.city}`
  );
  return base + query;
};

const getEstateType = (booking) => {
  let estateType = idx(booking, (_) => _.estatetype);
  if (!estateType) {
    return '';
  }
  return estateType.toLowerCase();
};

const getPropertySize = (booking) => {
  let propertysize = idx(booking, (_) => _.propertysize);
  return propertysize || 0;
};

const getServices = (booking) => {
  let services = idx(booking, (_) => _.services);
  if (!services) {
    return '';
  }
  return services.join(', ').toLowerCase();
};

const getEditingStyle = (booking) => {
  let editingstyle = idx(booking, (_) => _.editingstyle);
  if (!editingstyle) {
    return '';
  }
  return editingstyle.toLowerCase();
};
