import React from 'react';

// assets:
import Exclusive from '../../../assets/editingstyles/exclusive.jpg';
import Cold from '../../../assets/editingstyles/cold.jpg';
import Warm from '../../../assets/editingstyles/warm.jpg';

const EditingStyleSelector = ({ state, setState, id }) => {
  return (
    <div className="editingstylegrid" id={id}>
      <div
        className={state === 'EXCLUSIVE' ? 'selected' : ''}
        onClick={() => setState('EXCLUSIVE')}
      >
        <img src={Exclusive} alt="" />
        <p>
          <strong>Exklusiv</strong>
        </p>
      </div>
      <div
        className={state === 'COLD' ? 'selected' : ''}
        onClick={() => setState('COLD')}
      >
        <img src={Cold} alt="" />
        <p>
          <strong>Kall</strong>
        </p>
      </div>
      <div
        className={state === 'WARM' ? 'selected' : ''}
        onClick={() => setState('WARM')}
      >
        <img src={Warm} alt="" />
        <p>
          <strong>Varm</strong>
        </p>
      </div>
    </div>
  );
};

export default EditingStyleSelector;
