import React from 'react';
import { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { removeFromURL } from '../../../functions';
import Backbutton from '../../global/Backbutton';
import {
  collection,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
} from '@firebase/firestore';

import Plus from '../../../assets/plus.svg';
import AddNewsflashPopup from './AddNewsflashPopup';
import EditNewsflashPopup from './EditNewsflashPopup';
import LatestNewsflashWrapper from './wrappers/LatestNewsflashWrapper';
import OrganizationNewsflashWrapper from './wrappers/OrganizationNewsflashWrapper';
import EmptyScreen from '../../global/EmptyScreen';

const HandleNewsflash = () => {
  const [isShowingAdd, setIsShowingAdd] = useState(false);

  const [allNewsflashes, setAllNewsflashes] = useState([]);
  useEffect(() => {
    const db = getFirestore();
    const q = query(
      collection(db, 'newsflashes'),
      orderBy('timeCreated', 'desc')
    );

    const listener = onSnapshot(
      q,
      (snapshot) => {
        setAllNewsflashes((_) => {
          return snapshot.docs.map((e) => {
            var data = e.data();
            data.id = e.id;
            data.ref = e.ref;
            return data;
          });
        });
      },
      (err) => {
        console.error(err);
      }
    );
    return () => {
      listener();
    };
  }, []);

  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const db = getFirestore();
    getDocs(collection(db, 'organizations'))
      .then((snapshot) => {
        setOrganizations((_) => {
          return snapshot.docs.map((e) => {
            return e.data();
          });
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const [selectedNewsflash, setSelectedNewsflash] = useState(null);

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: '100%', maxHeight: '100%' },
    from: { opacity: 0, x: 16, maxWidth: '100%', maxHeight: '100%' },
    config: { friction: 20, tension: 400 },
  });
  return (
    <>
      <animated.div style={springprops}>
        <div className="createbooking board handleNewsflash">
          <Backbutton
            target={'Dashboard'}
            onClick={() => removeFromURL('newsflash')}
          />
          <h1 className="title">Hantera Newsflash</h1>
          <p className="subtitle">Ändra i, ta bort, eller skapa en Newsflash</p>
          <div
            className="buttonrow"
            onClick={() => {
              setIsShowingAdd(true);
            }}
          >
            <button>
              <img src={Plus} className="doinvert" alt="" />
              Skapa ny Newsflash
            </button>
          </div>
          <h3>Senaste</h3>
          <div className="latestNewsflashes">
            {allNewsflashes.length === 0 ? (
              <EmptyScreen title="Ingen newsflash." />
            ) : (
              allNewsflashes.map((e) => {
                return (
                  <LatestNewsflashWrapper
                    key={e.id}
                    data={e}
                    setEditObject={setSelectedNewsflash}
                  />
                );
              })
            )}
          </div>
          <h3
            tooltip="Vad varje organisation ser på startsidan"
            style={{ width: 'fit-content' }}
          >
            Efter organisation
          </h3>
          <div className="organizationLatestNewsflashes">
            {organizations.map((e) => {
              return (
                <OrganizationNewsflashWrapper
                  key={e.id}
                  organization={e}
                  setEditObject={setSelectedNewsflash}
                />
              );
            })}
          </div>
        </div>
      </animated.div>
      {isShowingAdd ? (
        <AddNewsflashPopup setShowingSelf={setIsShowingAdd} />
      ) : null}
      {selectedNewsflash ? (
        <EditNewsflashPopup
          providedNewsflash={selectedNewsflash}
          setEditObject={setSelectedNewsflash}
        />
      ) : null}
    </>
  );
};

export default HandleNewsflash;
