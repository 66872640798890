import React, { useState } from "react";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { toast } from "react-toastify";
import { animated, useSpring } from "react-spring";
import HyperLink from "../global/HyperLink";
import { addToURL, containsURL } from "../../functions";
import ResetPass from "./ResetPass";

const SignIn = ({ auth }) => {
  const [email, setEmail] = useState("");
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);

  const signIn = (e) => {
    e.preventDefault();

    var invalid = false;

    if (email === "") {
      setEmailInvalid(true);
      invalid = true;
    }

    if (password === "") {
      setPasswordInvalid(true);
      invalid = true;
    }

    if (invalid) return;

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {})
      .catch((error) => {
        switch (error.code) {
          case "auth/invalid-email":
            toast.error("E-postadressen är dåligt formatterad.");
            setEmailInvalid(true);
            break;
          case "auth/user-not-found":
            toast.error("Vi hittar inte denna användare.");
            break;
          case "auth/wrong-password":
            toast.error("Felaktigt lösenord.");
            setPasswordInvalid(true);
            break;
          default:
            toast.error("Någonting gick fel. Vänligen försök igen senare.");
            break;
        }
      });
  };

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  if (containsURL("resetpass")) {
    return <ResetPass />;
  }

  return (
    <div className="modal">
      <animated.div style={springprops}>
        <div className="card">
          <h1
            style={{
              marginBottom: "1.25rem",
            }}
          >
            Logga in
          </h1>
          <form onSubmit={signIn}>
            <input
              className={`${emailInvalid ? "invalid" : ""}`}
              type="email"
              placeholder="E-post"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailInvalid(false);
              }}
            />
            <input
              className={`${passwordInvalid ? "invalid" : ""}`}
              type="password"
              placeholder="Lösenord"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordInvalid(false);
              }}
            />
            <div></div>
            <button type="button" onClick={signIn}>Logga in</button>
            <HyperLink
              target={"Återställ lösenord"}
              onClick={() => {
                addToURL("resetpass");
              }}
            />
          </form>
        </div>
      </animated.div>
    </div>
  );
};

export default SignIn;
