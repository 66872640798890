import { React, useState } from "react";
import { animated, useSpring } from "react-spring";
import Backbutton from "../../../global/Backbutton";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Timestamp } from "@firebase/firestore";

const TimeRangePopup = ({ start, setStart, end, setEnd, setShowingSelf }) => {
  const [tempStart, setTempStart] = useState(start.toDate());
  const [tempEnd, setTempEnd] = useState(end.toDate());

  const submit = () => {
    setStart(Timestamp.fromDate(tempStart));
    setEnd(Timestamp.fromDate(tempEnd));
    setShowingSelf(false);
  };

  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 16, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });
  return (
    <form>
      <div className="timeRangeWrapper">
        <div className="dateInputWrapper">
          <label>Från</label>
          <DatePicker
            selected={tempStart}
            onChange={(date) => setTempStart(date)}
            showTimeSelect
          />
        </div>
        <div className="dateInputWrapper">
          <label>Till</label>
          <DatePicker
            selected={tempEnd}
            onChange={(date) => setTempEnd(date)}
            showTimeSelect
          />
        </div>
      </div>
      <button
        type="button"
        style={{
          width: "100%",
        }}
        onClick={submit}
      >
        Bekräfta
      </button>
    </form>
  );
};

export default TimeRangePopup;
