import React, { useState, useEffect } from 'react';
import idx from 'idx';
import HyperLink from '../../../global/HyperLink';
import { getHumanDateTime, getRelativeDateTime } from '../../../../functions';
import { deleteDoc, doc, getDoc, getFirestore } from '@firebase/firestore';

import Edit from '../../../../assets/edit.svg';
import XMark from '../../../../assets/xmark.svg';

const LatestNewsflashWrapper = ({ data, setEditObject }) => {
  const [organizations, setOrganizations] = useState([]);
  useEffect(() => {
    const getOrganizations = async () => {
      const db = getFirestore();
      const tempOrganizations = [];
      const promises = data.organizations.map((e) => {
        return new Promise((resolve, reject) => {
          getDoc(doc(db, 'organizations', e))
            .then((res) => {
              if (!res.exists) resolve();
              const orgName = idx(res, (_) => _.data().name);
              if (!orgName) resolve();
              tempOrganizations.push(orgName);
              resolve();
            })
            .catch((err) => {
              resolve();
            });
        });
      });
      await Promise.all(promises);
      setOrganizations(tempOrganizations);
    };
    getOrganizations();
  }, [data]);

  const deleteNewsFlash = () => {
    if (
      window
        .prompt(
          `Vänligen skriv "${
            idx(data, (_) => _.title) || 'TA BORT'
          }" för att bekräfta borttagning av Newsflash.`
        )
        .toLowerCase() ===
      (idx(data, (_) => _.title) || 'TA BORT').toLowerCase()
    ) {
      deleteDoc(data.ref);
    }
  };

  return (
    <div className="newsflashWrapperShowcase">
      <div className="newsflashWrapper">
        <label>{getRelativeDateTime(idx(data, (_) => _.timeCreated))}</label>
        <div className="titleWrapper">
          <h5>
            {idx(data, (_) => _.preTitle) ? (
              <span className="preTitle">
                <strong>{idx(data, (_) => _.preTitle)}</strong>
              </span>
            ) : null}
            <span>{idx(data, (_) => _.title)}</span>
          </h5>
        </div>
        {idx(data, (_) => _.subTitle) ? (
          <p className="faded subTitle">{idx(data, (_) => _.subTitle)}</p>
        ) : null}
        {idx(data, (_) => _.content) ? (
          <p className="content">{idx(data, (_) => _.content)}</p>
        ) : null}
        {idx(data, (_) => _.link) ? (
          <a href={idx(data, (_) => _.link)} target="_blank" rel="noreferrer">
            <HyperLink target={'Läs mer'} />
          </a>
        ) : null}
      </div>
      <div className="newsflashMeta faded">
        {idx(data, (_) => _.published) ? (
          <p>
            <span className="faded">Publicerad: </span>
            {getHumanDateTime(idx(data, (_) => _.timeCreated))}
          </p>
        ) : (
          <p>Inte publicerad.</p>
        )}
        <p>
          <span className="faded">Organisationer: </span> <br />
          {organizations.map((e) => {
            return (
              <>
                {e}
                <br />
              </>
            );
          })}
        </p>
      </div>
      <div className="buttonrow">
        <button
          onClick={() => {
            setEditObject(data);
          }}
        >
          <img src={Edit} className="doinvert" alt="" />
          Redigera
        </button>
        <button onClick={deleteNewsFlash}>
          <img src={XMark} className="doinvert" alt="" />
          Radera
        </button>
      </div>
    </div>
  );
};

export default LatestNewsflashWrapper;
