import idx from "idx";
import React from "react";
import { animated, useSpring } from "react-spring";
import { addToURL, getDiceBearInitials } from "../../../../functions";

const OrganizationWrapper = ({ organization }) => {
  const springprops = useSpring({
    to: { opacity: 1, x: 0, maxWidth: "100%", maxHeight: "100%" },
    from: { opacity: 0, x: 8, maxWidth: "100%", maxHeight: "100%" },
    config: { friction: 20, tension: 400 },
  });

  return (
    <animated.div style={springprops}>
      <div
        className="organizationwrapper"
        onClick={() => {
          addToURL("handleorganization?orgid=" + organization.id);
        }}
      >
        <div className="organizationmetawrapper">
          <img
            src={
              idx(organization, (_) => _.avatars.size1x1) ||
              getDiceBearInitials(organization.name)
            }
            className="db_initials"
            alt=""
          />
          <div className="organizationmeta">
            <h4>{organization.name}</h4>
            <p>
              <small className="mono faded">
                {idx(organization, (_) => _.members.length) || 0} medlemmar
              </small>
            </p>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

export default React.memo(OrganizationWrapper);
